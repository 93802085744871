import "./DonHangShopChiTiet.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
    getOneDonHang,
    updateDonHang,
    registerTaiKhoan,
    updatettShop,
    getIdSanPhamShop,
    getArrSanPham,
    updateSanPham,
} from "../redux/apiRequest";
import InHoaDon from "./InHoaDon";
import BanPhimSo from "../GiaoDienChung/BanPhimSo2";
import DinhDangSo from "../component/DinhDangSo";
const DonHangShopChiTiet = () => {
    const chieuRongMH = window.innerWidth;
    const { idShop, idDonHang } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const donHang = useSelector(
        (state) => state.donHang.donHang?.donHang?.donHang
    );
    console.log("donHang", donHang);
    const sanPham = useSelector(
        (state) => state?.sanPham?.sanPham?.sanPham?.sanPham
    );
    useEffect(() => {
        if (idDonHang) {
            const soBan = "";
            getOneDonHang(idShop, idDonHang, soBan, dispatch);
        }
    }, []);

    const handlePrint = () => {
        window.print();
    };

    // cu
    const ttShop = useSelector((state) => state.ttShop.ttShop.ttShop?.shop);
    const user = useSelector((state) => state.auth.login.currentUser);
    console.log("user", user);
    const [Tongtien, setTongtien] = useState();
    const [giaVon, setgiaVon] = useState();

    const [viewSanPhamXong, setviewSanPhamXong] = useState(0);
    const [sanPhamXong, setsanPhamXong] = useState();
    const [DacDiemSPXong, setDacDiemSPXong] = useState();

    const [hienBanPhimSo, sethienBanPhimSo] = useState(false);
    // const [giamTru, setgiamTru] = useState(
    //     donHang?.ttThem?.baoCao?.giamTru || "0"
    // );
    const [giamTru, setgiamTru] = useState({
        tenSo: "Giảm trừ",
        giaTri: donHang?.ttThem?.baoCao?.giamTru || "0",
        loaiSo: "vnd",
    });
    console.log("giamTru", giamTru);
    const [qr, setqr] = useState();

    // const [timShip, settimShip] = useState("Shop Tự Giao");
    // const [phiShip, setphiShip] = useState("0");

    const [phiShip, setphiShip] = useState({
        tenSo: "Phí ship",
        giaTri: donHang?.ttThem?.ttGiaoHang?.phiShip || "15000",
        loaiSo: "vnd",
    });
    // useEffect(() => {
    //     if (!donHang?.sdtXuLyDon && user) {
    //         const newDonHang = {
    //             sdtXuLyDon: user?.username,
    //         };
    //         console.log("newDonHang", newDonHang);
    //         updateDonHang(newDonHang, donHang?._id, dispatch);
    //     }
    // }, [donHang?._id]);
    useEffect(() => {
        setgiamTru({
            tenSo: "Giảm trừ",
            giaTri: donHang?.ttThem?.baoCao?.giamTru || "0",
            loaiSo: "vnd",
        });
        setphiShip({
            tenSo: "Giảm trừ",
            giaTri: donHang?.ttThem?.ttGiaoHang?.phiShip || "15000",
            loaiSo: "vnd",
        });
    }, [donHang]);
    const d = new Date();
    const gioPhut = `${d.getHours()}h${d.getMinutes()}`;
    const ngayThang = `${d.getDate()}/${d.getMonth()}/${d.getFullYear()}`;
    const handleGiamGia = () => {
        if (donHang?.trangThaiDH !== "ĐH Đã Thanh Toán") {
            const newDonHang = {
                ttThem: {
                    ...donHang?.ttThem,
                    ...{
                        baoCao: {
                            doanhThu: Tongtien - +giamTru?.giaTri,
                            giaVon: giaVon,
                            giamTru: +giamTru?.giaTri,
                            phiNenTang: 1000,
                        },
                    },
                },
            };
            console.log("newDonHang", newDonHang);
            updateDonHang(newDonHang, donHang?._id, dispatch);
        }
    };
    const handlePhiShip = () => {
        if (donHang?.trangThaiDH === "ĐH Tìm Ship Mới") {
            const newDonHang = {
                trangThaiDH: "ĐH Tìm Ship Mới",
                ttThem: {
                    ...donHang?.ttThem,
                    ...{
                        ttGiaoHang: {
                            tenNv: "",
                            idNv: "",
                            sdtNv: "",
                            phiShip: +phiShip?.giaTri || "0",
                            coc: "",
                        },
                    },
                },
            };
            console.log("newDonHang", newDonHang);
            updateDonHang(newDonHang, donHang?._id, dispatch);
        }
    };
    const handleQuayLai = () => {
        navigate(`/don-hang/${idShop}`);
    };
    const handleDaThanhToan = () => {
        if (ttShop?.cash > 0) {
            try {
                if (ttShop?.phiNenTang === "1K/Đơn Hàng") {
                    const newDonHang = {
                        trangThaiDH: "ĐH Đã Thanh Toán",
                        sdtThuTien: user?.username,
                        ttThem: {
                            ...donHang?.ttThem,
                            ...{
                                baoCao: {
                                    doanhThu:
                                        Tongtien -
                                        donHang?.ttThem?.baoCao?.giamTru,
                                    giaVon: giaVon,
                                    giamTru: donHang?.ttThem?.baoCao?.giamTru,
                                    phiNenTang: 1000,
                                },
                            },
                        },
                    };
                    console.log("newDonHang", newDonHang);
                    updateDonHang(newDonHang, donHang?._id, dispatch);
                    const newShop = {
                        cash: +ttShop?.cash - 1000,
                    };
                    console.log("newShop", newShop);
                    updatettShop(newShop, ttShop._id, dispatch);
                    const newTaiKhoan = {
                        GDVao: "",
                        GDRa: 1000,
                        noiDungCK: "Phí Nền Tảng",
                        xacNhanChuyenTien: "Thành Công",
                        thongTinThem: {
                            tenChuTk: ttShop?.TenShop,
                            sdtChuTk: ttShop?.sdtShop,
                        },
                        idChuTaiKhoan: ttShop?._id,
                    };
                    console.log("newTaiKhoan", newTaiKhoan);
                    registerTaiKhoan(newTaiKhoan, dispatch);
                }
                if (ttShop?.phiNenTang === "1% Doanh Thu") {
                    const newDonHang = {
                        trangThaiDH: "ĐH Đã Thanh Toán",
                        sdtThuTien: user?.username,
                        ttThem: {
                            ...donHang?.ttThem,
                            ...{
                                baoCao: {
                                    doanhThu:
                                        Tongtien -
                                        donHang?.ttThem?.baoCao?.giamTru,
                                    giaVon: giaVon,
                                    giamTru: donHang?.ttThem?.baoCao?.giamTru,
                                    phiNenTang:
                                        (Tongtien -
                                            donHang?.ttThem?.baoCao?.giamTru) /
                                        100,
                                },
                            },
                        },
                    };
                    console.log("newDonHang", newDonHang);
                    updateDonHang(newDonHang, donHang?._id, dispatch);
                    const newShop = {
                        cash:
                            ttShop?.cash -
                            (Tongtien - donHang?.ttThem?.baoCao?.giamTru) / 100,
                    };
                    console.log("newShop", newShop);
                    updatettShop(newShop, ttShop._id, dispatch);
                    const newTaiKhoan = {
                        GDVao: "",
                        GDRa:
                            (Tongtien - donHang?.ttThem?.baoCao?.giamTru) / 100,
                        noiDungCK: "Phí Nền Tảng",
                        xacNhanChuyenTien: "Thành Công",
                        thongTinThem: {
                            tenChuTk: ttShop?.TenShop,
                            sdtChuTk: ttShop?.sdtShop,
                        },
                        idChuTaiKhoan: ttShop?._id,
                    };
                    console.log("newTaiKhoan", newTaiKhoan);
                    registerTaiKhoan(newTaiKhoan, dispatch);
                }
            } catch (err) {
                console.log(err);
            }
        } else {
            alert("Fabysa Gold không đủ để thanh toán phí nền tảng!");
        }
    };
    const handleChuaThanhToan = () => {
        try {
            const newDonHang = {
                trangThaiDH: "ĐH Mới",
                sdtThuTien: "",
                ttThem: {
                    ...donHang?.ttThem,
                    ...{
                        baoCao: {
                            ...donHang?.ttThem?.baoCao,
                            ...{ doanhThu: 0, giaVon: 0 },
                        },
                    },
                },
            };
            updateDonHang(newDonHang, donHang?._id, dispatch);
            console.log("newDonHang", newDonHang);

            const newShop = {
                cash: +ttShop?.cash + donHang?.ttThem?.baoCao?.phiNenTang,
            };
            console.log("newShop", newShop);
            updatettShop(newShop, ttShop._id, dispatch);
            const newTaiKhoan = {
                GDVao: donHang?.ttThem?.baoCao?.phiNenTang,
                GDRa: "",
                noiDungCK: "Hoàn Phí Nền Tảng",
                xacNhanChuyenTien: "Thành Công",
                thongTinThem: {
                    tenChuTk: ttShop?.TenShop,
                    sdtChuTk: ttShop?.sdtShop,
                },
                idChuTaiKhoan: ttShop?._id,
            };
            console.log("newTaiKhoan", newTaiKhoan);
            registerTaiKhoan(newTaiKhoan, dispatch);
        } catch (err) {
            console.log(err);
        }
    };
    const handleHuyDon = () => {
        const cauHoi = window.confirm("Huỷ đơn?");
        if (cauHoi) {
            try {
                const newDonHang = {
                    trangThaiDH: "ĐH Huỷ",
                    sdtXuLyDon: user?.username,
                };
                updateDonHang(newDonHang, donHang?._id, dispatch);
            } catch (err) {
                console.log(err);
            }
        }
    };
    const handleTraHang = (id) => {
        const cauHoi = window.confirm("Khách muốn trả hàng?");
        if (cauHoi) {
            try {
                const newDonHang = {
                    trangThaiDH: "ĐH Huỷ",
                    sdtXuLyDon: user?.username,
                };
                updateDonHang(newDonHang, id, dispatch);

                const newShop = {
                    cash: +ttShop?.cash + donHang?.ttThem?.baoCao?.phiNenTang,
                };
                console.log("newShop", newShop);
                updatettShop(newShop, ttShop._id, dispatch);
                const newTaiKhoan = {
                    GDVao: donHang?.ttThem?.baoCao?.phiNenTang,
                    GDRa: "",
                    noiDungCK: "Hoàn Phí Nền Tảng",
                    xacNhanChuyenTien: "Thành Công",
                    thongTinThem: {
                        tenChuTk: ttShop?.TenShop,
                        sdtChuTk: ttShop?.sdtShop,
                    },
                    idChuTaiKhoan: ttShop?._id,
                };
                console.log("newTaiKhoan", newTaiKhoan);
                registerTaiKhoan(newTaiKhoan, dispatch);
            } catch (err) {
                console.log(err);
            }
        }
    };
    const handleGetIdSanPham = (item3, item) => {
        if (
            donHang?.trangThaiDH === "ĐH Mới" ||
            donHang?.trangThaiDH === "ĐH Tìm Ship Mới" ||
            donHang?.trangThaiDH === "ĐH Ship Đã Nhận"
        ) {
            getIdSanPhamShop(item3?._id, dispatch);
            setviewSanPhamXong(1);
            setsanPhamXong(item3);
            setDacDiemSPXong(item);
        }
    };
    const handleDaXong = () => {
        const sanPhamXong2 = {
            _id: sanPhamXong?._id,
            TenSanPham: sanPhamXong?.TenSanPham,
            allDacDiemSP: sanPhamXong?.allDacDiemSP?.map((item4) =>
                item4 !== DacDiemSPXong ? item4 : { ...item4, ...{ daXong: 1 } }
            ),
        };
        const donHangSua = donHang?.donHang?.map((item5) =>
            item5 === sanPhamXong ? sanPhamXong2 : item5
        );
        const newDonHang = {
            donHang: donHangSua,
        };
        console.log("newDonHang", newDonHang);
        updateDonHang(newDonHang, donHang?._id, dispatch);

        const DacDiemSP = sanPham?.allDacDiemSP?.find(
            (item6) => item6?.tenDacDiem === DacDiemSPXong?.tenDacDiem
        );
        const DacDiemSPMoi = {
            ...DacDiemSP,
            ...{ soLuong: +DacDiemSP?.soLuong - +DacDiemSPXong?.slMua },
        };
        const allDacDiemSPMoi = sanPham?.allDacDiemSP?.map((item) =>
            item?.tenDacDiem !== DacDiemSPXong?.tenDacDiem ? item : DacDiemSPMoi
        );
        const newSanPham = {
            allDacDiemSP: allDacDiemSPMoi,
        };
        console.log("newSanPham", newSanPham);
        updateSanPham(newSanPham, sanPham?._id, dispatch);
        setviewSanPhamXong(0);
    };
    const handleChuaXong = () => {
        const sanPhamXong2 = {
            _id: sanPhamXong?._id,
            TenSanPham: sanPhamXong?.TenSanPham,
            allDacDiemSP: sanPhamXong?.allDacDiemSP?.map((item4) =>
                item4 !== DacDiemSPXong ? item4 : { ...item4, ...{ daXong: 0 } }
            ),
        };
        const donHangSua = donHang?.donHang?.map((item5) =>
            item5 === sanPhamXong ? sanPhamXong2 : item5
        );
        const newDonHang = {
            donHang: donHangSua,
        };
        console.log("newDonHang", newDonHang);
        updateDonHang(newDonHang, donHang?._id, dispatch);
        const DacDiemSP = sanPham?.allDacDiemSP?.find(
            (item6) => item6?.tenDacDiem === DacDiemSPXong?.tenDacDiem
        );
        const DacDiemSPMoi = {
            ...DacDiemSP,
            ...{ soLuong: +DacDiemSP?.soLuong + +DacDiemSPXong?.slMua },
        };
        const allDacDiemSPMoi = sanPham?.allDacDiemSP?.map((item) =>
            item?.tenDacDiem !== DacDiemSPXong?.tenDacDiem ? item : DacDiemSPMoi
        );
        const newSanPham = {
            allDacDiemSP: allDacDiemSPMoi,
        };
        console.log("newSanPham", newSanPham);
        updateSanPham(newSanPham, sanPham?._id, dispatch);
        setviewSanPhamXong(0);
    };
    const handleThueShip = (trangThaiShip) => {
        if (trangThaiShip === "ĐH Tìm Ship Mới") {
            const newDonHang = {
                trangThaiDH: "ĐH Tìm Ship Mới",
                ttThem: {
                    ...donHang?.ttThem,
                    ...{
                        ttGiaoHang: {
                            tenNv: "",
                            idNv: "",
                            sdtNv: "",
                            phiShip: +phiShip?.giaTri,
                            coc: "",
                        },
                    },
                    ...{
                        stkShop: {
                            idNganHang: ttShop?.ttShopThem?.nganHang?.maSo,
                            nganHang: ttShop?.ttShopThem?.nganHang?.tenNganHang,
                            soTaiKhoan:
                                ttShop?.ttShopThem?.nganHang?.taiKhoanNganHang,
                            tenChuTk:
                                ttShop?.ttShopThem?.nganHang
                                    ?.chuTaiKhoanNganhang,
                            daCK: "chưa Chuyển",
                        },
                    },
                    ...{
                        baoCao: {
                            doanhThu: Tongtien - +giamTru?.giaTri,
                            giaVon: giaVon,
                            phiNenTang: 0,
                            giamTru: +giamTru?.giaTri,
                        },
                    },
                },
            };
            console.log("newDonHang", newDonHang);
            updateDonHang(newDonHang, donHang?._id, dispatch);
        }
        if (trangThaiShip === "ĐH Mới") {
            const newDonHang = {
                trangThaiDH: "ĐH Mới",
                ttThem: {
                    ...donHang?.ttThem,
                    ...{
                        stkShop: {
                            idNganHang: ttShop?.ttShopThem?.nganHang?.maSo,
                            nganHang: ttShop?.ttShopThem?.nganHang?.tenNganHang,
                            soTaiKhoan:
                                ttShop?.ttShopThem?.nganHang?.taiKhoanNganHang,
                            tenChuTk:
                                ttShop?.ttShopThem?.nganHang
                                    ?.chuTaiKhoanNganhang,
                            daCK: "chưa Chuyển",
                        },
                    },
                    ...{
                        baoCao: {
                            doanhThu: Tongtien - +giamTru?.giaTri,
                            giaVon: giaVon,
                            phiNenTang: 0,
                            giamTru: +giamTru?.giaTri,
                        },
                    },
                    ...{
                        ttGiaoHang: "",
                    },
                },
            };
            console.log("newDonHang", newDonHang);
            updateDonHang(newDonHang, donHang?._id, dispatch);
        }
    };
    const handleTuShip = () => {
        const cauHoi = window.confirm("Shop muốn tự ship?");
        if (cauHoi) {
            const newDonHang = {
                trangThaiDH: "ĐH Mới",
                ttThem: {
                    ...donHang?.ttThem,
                    ...{
                        ttGiaoHang: "",
                    },
                },
            };

            updateDonHang(newDonHang, donHang?._id, dispatch);
        }
    };
    const handleDangShip = () => {
        const newDonHang = {
            trangThaiDH: "ĐH Đang Ship",
            ttThem: {
                ...donHang?.ttThem,
                ...{
                    baoCao: {
                        doanhThu: Tongtien - +giamTru?.giaTri,
                        giaVon: giaVon,
                        phiNenTang: 0,
                        giamTru: +giamTru?.giaTri,
                    },
                },
            },
        };

        updateDonHang(newDonHang, donHang?._id, dispatch);
    };
    const handleTimShipKhac = () => {
        const cauHoi = window.confirm("Shop muốn tìm ship khác?");
        if (cauHoi) {
            const newDonHang = {
                trangThaiDH: "ĐH Tìm Ship Mới",
            };

            updateDonHang(newDonHang, donHang?._id, dispatch);
        }
    };
    const handleShipDaThanhToan = () => {
        if (ttShop?.phiNenTang === "1K/Đơn Hàng") {
            const newDonHang = {
                trangThaiDH: "ĐH Ship Đã Thanh Toán Về Shop",
                ttThem: {
                    ...donHang?.ttThem,
                    ...{
                        stkShop: {
                            ...donHang?.ttThem?.stkShop,
                            daCK: "Shop Ok",
                        },
                    },
                },
            };
            console.log("newDonHang", newDonHang);
            updateDonHang(newDonHang, donHang?._id, dispatch);
            const newShop = {
                cash: ttShop?.cash - 1000,
            };
            console.log("newShop", newShop);
            updatettShop(newShop, ttShop._id, dispatch);
            const newTaiKhoan = {
                GDVao: "",
                GDRa: 1000,
                noiDungCK: "Phí Nền Tảng",
                xacNhanChuyenTien: "Thành Công",
                thongTinThem: {
                    tenChuTk: ttShop?.TenShop,
                    sdtChuTk: ttShop?.sdtShop,
                },
                idChuTaiKhoan: ttShop?._id,
            };
            console.log("newTaiKhoan", newTaiKhoan);
            registerTaiKhoan(newTaiKhoan, dispatch);
        }
        if (ttShop?.phiNenTang === "1% Doanh Thu") {
            const newDonHang = {
                trangThaiDH: "ĐH Ship Đã Thanh Toán Về Shop",
                ttThem: {
                    ...donHang?.ttThem,
                    ...{
                        stkShop: {
                            ...donHang?.ttThem?.stkShop,
                            daCK: "Shop Ok",
                        },
                    },
                },
            };
            console.log("newDonHang", newDonHang);
            updateDonHang(newDonHang, donHang?._id, dispatch);
            const newShop = {
                cash: ttShop?.cash - donHang?.ttThem?.baoCao?.doanhThu / 100,
            };
            console.log("newShop", newShop);
            updatettShop(newShop, ttShop._id, dispatch);
            const newTaiKhoan = {
                GDVao: "",
                GDRa: (Tongtien - donHang?.ttThem?.baoCao?.giamTru) / 100,
                noiDungCK: "Phí Nền Tảng",
                xacNhanChuyenTien: "Thành Công",
                thongTinThem: {
                    tenChuTk: ttShop?.TenShop,
                    sdtChuTk: ttShop?.sdtShop,
                },
                idChuTaiKhoan: ttShop?._id,
            };
            console.log("newTaiKhoan", newTaiKhoan);
            registerTaiKhoan(newTaiKhoan, dispatch);
        }
    };
    const handleShipDaHoanHang = () => {
        const newDonHang = {
            trangThaiDH: "ĐH Ship Huỷ Đã Hoàn Hàng",
        };

        updateDonHang(newDonHang, donHang?._id, dispatch);
    };
    const handleSoLuongDonHang = (sl, item, item3) => {
        if (+sl < 0 || (sl === "tru" && +item?.slMua === 0)) {
            alert("Số lượng phải lớn hơn 0");
        }
        if (+sl > 0) {
            const tenDacDiem_SlMua = {
                ...item,
                ...{
                    slMua: +sl,
                    gioPhut: gioPhut,
                },
            };
            const allDacDiemSP_SLmua = item3?.allDacDiemSP?.map((item4) =>
                item4?.tenDacDiem !== item?.tenDacDiem
                    ? item4
                    : tenDacDiem_SlMua
            );

            const donHangDaDatmoi = donHang?.donHang?.map((item5) =>
                item5?._id !== item3?._id
                    ? item5
                    : {
                          ...item3,
                          ...{ allDacDiemSP: allDacDiemSP_SLmua },
                      }
            );
            const newDonHang = {
                donHang: donHangDaDatmoi,
            };
            updateDonHang(newDonHang, donHang?._id, dispatch);
            //

            setDacDiemSPXong({
                ...DacDiemSPXong,
                ...{
                    slMua: +sl,
                    gioPhut: gioPhut,
                },
            });
        }
        if (sl === "tru" && +item?.slMua > 0) {
            const tenDacDiem_SlMua = {
                ...item,
                ...{
                    slMua: +item?.slMua - 1,
                    gioPhut: gioPhut,
                },
            };
            const allDacDiemSP_SLmua = item3?.allDacDiemSP?.map((item4) =>
                item4?.tenDacDiem !== item?.tenDacDiem
                    ? item4
                    : tenDacDiem_SlMua
            );

            const donHangDaDatmoi = donHang?.donHang?.map((item5) =>
                item5?._id !== item3?._id
                    ? item5
                    : {
                          ...item3,
                          ...{ allDacDiemSP: allDacDiemSP_SLmua },
                      }
            );
            const newDonHang = {
                donHang: donHangDaDatmoi,
            };
            updateDonHang(newDonHang, donHang?._id, dispatch);
            setDacDiemSPXong({
                ...DacDiemSPXong,
                ...{
                    slMua: +DacDiemSPXong?.slMua - 1,
                    gioPhut: gioPhut,
                },
            });
        }
        if (sl === "cong") {
            const tenDacDiem_SlMua = {
                ...item,
                ...{
                    slMua: +item?.slMua + 1,
                    gioPhut: gioPhut,
                },
            };
            const allDacDiemSP_SLmua = item3?.allDacDiemSP?.map((item4) =>
                item4?.tenDacDiem !== item?.tenDacDiem
                    ? item4
                    : tenDacDiem_SlMua
            );

            const donHangDaDatmoi = donHang?.donHang?.map((item5) =>
                item5?._id !== item3?._id
                    ? item5
                    : {
                          ...item3,
                          ...{ allDacDiemSP: allDacDiemSP_SLmua },
                      }
            );
            const newDonHang = {
                donHang: donHangDaDatmoi,
            };
            updateDonHang(newDonHang, donHang?._id, dispatch);
            setDacDiemSPXong({
                ...DacDiemSPXong,
                ...{
                    slMua: +DacDiemSPXong?.slMua + 1,
                    gioPhut: gioPhut,
                },
            });
        }
    };

    useEffect(() => {
        if (donHang?.ttThem?.khachHang?.nhomKhach === "Khách Lẻ") {
            const tinhtongtien = () => {
                let tt = 0;
                if (donHang?.donHang?.length !== 0) {
                    donHang?.donHang?.map((sp) => {
                        sp?.allDacDiemSP?.map((item) => {
                            tt += +item?.slMua * item?.giaKhuyenMai;
                        });
                    });
                }

                setTongtien(tt);
            };
            tinhtongtien();
        }
        if (donHang?.ttThem?.khachHang?.nhomKhach === "Khách Sỉ") {
            const tinhtongtien = () => {
                let tt = 0;
                if (donHang?.donHang?.length !== 0) {
                    donHang?.donHang?.map((sp) => {
                        sp?.allDacDiemSP?.map((item) => {
                            tt += +item?.slMua * item?.giaSi;
                        });
                    });
                }

                setTongtien(tt);
            };
            tinhtongtien();
        }
        if (donHang?.ttThem?.khachHang?.nhomKhach === "Khách Cộng Tác Viên") {
            const tinhtongtien = () => {
                let tt = 0;
                if (donHang?.donHang?.length !== 0) {
                    donHang?.donHang?.map((sp) => {
                        sp?.allDacDiemSP?.map((item) => {
                            tt += +item?.slMua * item?.giaCtv;
                        });
                    });
                }

                setTongtien(tt);
            };
            tinhtongtien();
        }
    });
    useEffect(() => {
        const tinhTongGiaVon = () => {
            let tt = 0;
            if (donHang?.donHang?.length !== 0) {
                donHang?.donHang?.map((sp) => {
                    sp?.allDacDiemSP?.map((item) => {
                        tt += +item?.slMua * item?.giaVon;
                    });
                });
            }

            setgiaVon(tt);
        };
        tinhTongGiaVon();
    });

    const handleThanhToan = () => {
        if (!qr) {
            const nganHang = ttShop?.ttShopThem?.nganHang;
            const BANK_ID = nganHang.maSo;
            const ACCOUNT_NO = nganHang.taiKhoanNganHang;
            const TEMPLATE = "print";
            const AMOUNT = +Tongtien - +giamTru?.giaTri;
            const DESCRIPTION = `Hoá Đơn ${donHang?.soBan}`;
            const ACCOUNT_NAME = nganHang.chuTaiKhoanNganhang;
            const qr2 = `https://img.vietqr.io/image/${BANK_ID}-${ACCOUNT_NO}-${TEMPLATE}.png?amount=${AMOUNT}&addInfo=${DESCRIPTION}&accountName=${ACCOUNT_NAME}`;
            setqr(qr2);
        } else {
            setqr();
        }
    };

    return (
        <div className="view">
            {chieuRongMH <= "1023" && (
                <div className="mobile">
                    <div className="DonHangShopChiTiet-ConTaiNer">
                        <div className="quayLai-tieuDe">
                            <div
                                onClick={() => handleQuayLai()}
                                className="quayLai"
                            >
                                <i className="fa fa-angle-double-left"></i>Quay
                                Lại
                            </div>
                            <div className="tieuDe">Chi Tiết Đơn Hàng</div>
                            <div
                                className="inHoaDon"
                                onClick={() => handlePrint()}
                            >
                                <i className="fa-solid fa-print"></i>
                            </div>
                        </div>
                        <div className="trangThaiDh-huyDon">
                            <div className="trangThaiDh">
                                <i
                                    className="fas fa-shopping-bag"
                                    style={{
                                        color: "red",
                                    }}
                                ></i>
                                &#160;
                                {donHang?.trangThaiDH}
                            </div>
                            {!donHang?.donHang
                                ?.find((item) =>
                                    item?.allDacDiemSP?.find(
                                        (item2) => item2?.daXong === 1
                                    )
                                )
                                ?.allDacDiemSP?.find(
                                    (item3) => item3?.daXong === 1
                                ) &&
                                donHang?.trangThaiDH === "ĐH Mới" && (
                                    <div
                                        onClick={() => handleHuyDon()}
                                        className="huyDon"
                                    >
                                        <i
                                            className="fa fa-remove"
                                            style={{
                                                color: "red",
                                            }}
                                        ></i>
                                        &#160;Huỷ
                                    </div>
                                )}
                        </div>

                        <div className="sanPham-container">
                            {donHang?.ttThem?.khachHang?.nhomKhach ===
                                "Khách Lẻ" && (
                                <div className="allSanPham">
                                    {donHang?.donHang?.map((item3, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="sanPham"
                                            >
                                                <div className="tenSanPham">
                                                    {item3?.TenSanPham}
                                                </div>

                                                {item3?.allDacDiemSP &&
                                                    item3?.allDacDiemSP
                                                        ?.length > 0 &&
                                                    item3?.allDacDiemSP?.map(
                                                        (item, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className="dacDiem-themGioHang"
                                                                >
                                                                    <div className="tenDD-gioPhut">
                                                                        <div className="tenDD">
                                                                            {
                                                                                item?.tenDacDiem
                                                                            }
                                                                        </div>

                                                                        <div className="gioPhut">
                                                                            {
                                                                                item?.gioPhut
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="giaSanPham">
                                                                        <div className="giaNY">
                                                                            <DinhDangSo
                                                                                soDinhDang={
                                                                                    item?.giaNiemYet
                                                                                }
                                                                                donViSo="vnd"
                                                                                // tt,sdt,vnd,f
                                                                            />
                                                                        </div>
                                                                        <div className="giaKM">
                                                                            <DinhDangSo
                                                                                soDinhDang={
                                                                                    item?.giaKhuyenMai
                                                                                }
                                                                                donViSo="vnd"
                                                                                // tt,sdt,vnd,f
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="soLuong-SL">
                                                                        <div className="soLuong">
                                                                            SL
                                                                        </div>
                                                                        <div className="SLMua">
                                                                            {
                                                                                item?.slMua
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="thanhTien-TT">
                                                                        <div className="thanhTien">
                                                                            TT
                                                                        </div>
                                                                        <div className="TT">
                                                                            <DinhDangSo
                                                                                soDinhDang={
                                                                                    item?.slMua *
                                                                                    item?.giaKhuyenMai
                                                                                }
                                                                                donViSo="vnd"
                                                                                // tt,sdt,vnd,f
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {item?.daXong ===
                                                                    1 ? (
                                                                        <div className="xacNhan-boxCheck">
                                                                            <div className="xacNhan">
                                                                                Xác
                                                                                Nhận
                                                                            </div>
                                                                            <div
                                                                                onClick={() =>
                                                                                    handleGetIdSanPham(
                                                                                        item3,
                                                                                        item
                                                                                    )
                                                                                }
                                                                                className="daXong"
                                                                            >
                                                                                <i className="fa-solid fa-check"></i>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="xacNhan-boxCheck">
                                                                            <div className="xacNhan">
                                                                                Xác
                                                                                Nhận
                                                                            </div>
                                                                            <div
                                                                                onClick={() =>
                                                                                    handleGetIdSanPham(
                                                                                        item3,
                                                                                        item
                                                                                    )
                                                                                }
                                                                                className="chuaXong"
                                                                            >
                                                                                <i className="fa-solid fa-check"></i>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            {donHang?.ttThem?.khachHang?.nhomKhach ===
                                "Khách Sỉ" && (
                                <div className="allSanPham">
                                    {donHang?.map((item3, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="sanPham"
                                            >
                                                <div className="tenSanPham-xoa">
                                                    <div className="tenSanPham">
                                                        {item3?.TenSanPham}
                                                    </div>
                                                </div>
                                                {item3?.allDacDiemSP &&
                                                    item3?.allDacDiemSP
                                                        ?.length > 0 &&
                                                    item3?.allDacDiemSP?.map(
                                                        (item, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className="dacDiem-themGioHang"
                                                                >
                                                                    <div className="anhSp-tenSp">
                                                                        <div className="tenSp">
                                                                            {
                                                                                item?.tenDacDiem
                                                                            }
                                                                        </div>
                                                                        <div className="gioPhut">
                                                                            {
                                                                                item?.gioPhut
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="giaSanPham">
                                                                        <div className="giaKM">
                                                                            <DinhDangSo
                                                                                soDinhDang={
                                                                                    item?.giaSi
                                                                                }
                                                                                donViSo="vnd"
                                                                                // tt,sdt,vnd,f
                                                                            />
                                                                        </div>
                                                                        <div className="giaNY-giamGia">
                                                                            <div className="giaNY">
                                                                                <DinhDangSo
                                                                                    soDinhDang={
                                                                                        item?.giaNiemYet
                                                                                    }
                                                                                    donViSo="vnd"
                                                                                    // tt,sdt,vnd,f
                                                                                />
                                                                            </div>
                                                                            <div className="giamGia">
                                                                                Giảm&nbsp;
                                                                                {Math.floor(
                                                                                    (100 *
                                                                                        (item?.giaNiemYet -
                                                                                            item?.giaSi)) /
                                                                                        item?.giaNiemYet
                                                                                )}

                                                                                %
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="soLuong-SL">
                                                                        <div className="soLuong">
                                                                            Số
                                                                            Lượng
                                                                        </div>
                                                                        <div className="thayDoiSl">
                                                                            <div
                                                                                onClick={() =>
                                                                                    handleSoLuongDonHang(
                                                                                        "tru",
                                                                                        item,
                                                                                        item3
                                                                                    )
                                                                                }
                                                                                className="giamSl"
                                                                            >
                                                                                <i className="fa fa-arrow-circle-down"></i>
                                                                            </div>
                                                                            <input
                                                                                type="number"
                                                                                className="SL"
                                                                                placeholder={
                                                                                    item?.slMua
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    handleSoLuongDonHang(
                                                                                        e
                                                                                            .target
                                                                                            .value,

                                                                                        item,
                                                                                        item3
                                                                                    )
                                                                                }
                                                                            />
                                                                            <div
                                                                                onClick={() =>
                                                                                    handleSoLuongDonHang(
                                                                                        "cong",
                                                                                        item,
                                                                                        item3
                                                                                    )
                                                                                }
                                                                                className="giamSl"
                                                                            >
                                                                                <i className="fa fa-arrow-circle-up"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="thanhTien-TT">
                                                                        <div className="thanhTien">
                                                                            Thành
                                                                            Tiền
                                                                        </div>
                                                                        <div className="TT">
                                                                            <DinhDangSo
                                                                                soDinhDang={
                                                                                    item?.slMua *
                                                                                    item?.giaSi
                                                                                }
                                                                                donViSo="vnd"
                                                                                // tt,sdt,vnd,f
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    {/* {donHang?.trangThaiDH ===
                                                                        "ĐH Mới" ||
                                                                    donHang?.trangThaiDH ===
                                                                        "ĐH Tìm Ship Mới" ||
                                                                    donHang?.trangThaiDH ===
                                                                        "ĐH Ship Đã Nhận" ? (
                                                                        <div>
                                                                            {item.daXong ===
                                                                            1 ? (
                                                                                <div
                                                                                    onClick={() =>
                                                                                        handleGetIdSanPham(
                                                                                            item,
                                                                                            item3
                                                                                        )
                                                                                    }
                                                                                    className="daXong"
                                                                                >
                                                                                    ✅
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    onClick={() =>
                                                                                        handleGetIdSanPham(
                                                                                            item,
                                                                                            item3
                                                                                        )
                                                                                    }
                                                                                    className="chuaXong"
                                                                                >
                                                                                    ☐
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <div>
                                                                            {item.daXong ===
                                                                            1 ? (
                                                                                <div className="daXong">
                                                                                    ✅
                                                                                </div>
                                                                            ) : (
                                                                                <div className="chuaXong">
                                                                                    ☐
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )} */}
                                                                    {item.daXong ===
                                                                    1 ? (
                                                                        <div
                                                                            onClick={() =>
                                                                                handleGetIdSanPham(
                                                                                    item,
                                                                                    item3
                                                                                )
                                                                            }
                                                                            className="daXong"
                                                                        >
                                                                            ✅
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            onClick={() =>
                                                                                handleGetIdSanPham(
                                                                                    item,
                                                                                    item3
                                                                                )
                                                                            }
                                                                            className="chuaXong"
                                                                        >
                                                                            ☐
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            {donHang?.ttThem?.khachHang?.nhomKhach ===
                                "Khách Cộng Tác Viên" && (
                                <div className="allSanPham">
                                    {donHang?.map((item3, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="sanPham"
                                            >
                                                <div className="tenSanPham-xoa">
                                                    <div className="tenSanPham">
                                                        {item3?.TenSanPham}
                                                    </div>
                                                </div>
                                                {item3?.allDacDiemSP &&
                                                    item3?.allDacDiemSP
                                                        ?.length > 0 &&
                                                    item3?.allDacDiemSP?.map(
                                                        (item, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className="dacDiem-themGioHang"
                                                                >
                                                                    <div className="anhSp-tenSp">
                                                                        <div className="tenSp">
                                                                            {
                                                                                item?.tenDacDiem
                                                                            }
                                                                        </div>
                                                                        <div className="gioPhut">
                                                                            {
                                                                                item?.gioPhut
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="giaSanPham">
                                                                        <div className="giaKM">
                                                                            <DinhDangSo
                                                                                soDinhDang={
                                                                                    item?.giaCtv
                                                                                }
                                                                                donViSo="vnd"
                                                                                // tt,sdt,vnd,f
                                                                            />
                                                                        </div>
                                                                        <div className="giaNY-giamGia">
                                                                            <div className="giaNY">
                                                                                <DinhDangSo
                                                                                    soDinhDang={
                                                                                        item?.giaNiemYet
                                                                                    }
                                                                                    donViSo="vnd"
                                                                                    // tt,sdt,vnd,f
                                                                                />
                                                                            </div>
                                                                            <div className="giamGia">
                                                                                Giảm&nbsp;
                                                                                {Math.floor(
                                                                                    (100 *
                                                                                        (item?.giaNiemYet -
                                                                                            item?.giaCtv)) /
                                                                                        item?.giaNiemYet
                                                                                )}

                                                                                %
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="soLuong-SL">
                                                                        <div className="soLuong">
                                                                            Số
                                                                            Lượng
                                                                        </div>
                                                                        <div className="thayDoiSl">
                                                                            <div
                                                                                onClick={() =>
                                                                                    handleSoLuongDonHang(
                                                                                        "tru",
                                                                                        item,
                                                                                        item3
                                                                                    )
                                                                                }
                                                                                className="giamSl"
                                                                            >
                                                                                <i className="fa fa-arrow-circle-down"></i>
                                                                            </div>
                                                                            <input
                                                                                type="number"
                                                                                className="SL"
                                                                                placeholder={
                                                                                    item?.slMua
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    handleSoLuongDonHang(
                                                                                        e
                                                                                            .target
                                                                                            .value,

                                                                                        item,
                                                                                        item3
                                                                                    )
                                                                                }
                                                                            />
                                                                            <div
                                                                                onClick={() =>
                                                                                    handleSoLuongDonHang(
                                                                                        "cong",
                                                                                        item,
                                                                                        item3
                                                                                    )
                                                                                }
                                                                                className="giamSl"
                                                                            >
                                                                                <i className="fa fa-arrow-circle-up"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="thanhTien-TT">
                                                                        <div className="thanhTien">
                                                                            Thành
                                                                            Tiền
                                                                        </div>
                                                                        <div className="TT">
                                                                            <DinhDangSo
                                                                                soDinhDang={
                                                                                    item?.slMua *
                                                                                    item?.giaCtv
                                                                                }
                                                                                donViSo="vnd"
                                                                                // tt,sdt,vnd,f
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {donHang?.trangThaiDH ===
                                                                        "ĐH Mới" ||
                                                                    donHang?.trangThaiDH ===
                                                                        "ĐH Tìm Ship Mới" ||
                                                                    donHang?.trangThaiDH ===
                                                                        "ĐH Ship Đã Nhận" ? (
                                                                        <div>
                                                                            {item.daXong ===
                                                                            1 ? (
                                                                                <div
                                                                                    onClick={() =>
                                                                                        handleChuaXong(
                                                                                            item,
                                                                                            item3
                                                                                        )
                                                                                    }
                                                                                    className="daXong"
                                                                                >
                                                                                    ✅
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    onClick={() =>
                                                                                        handleDaXong(
                                                                                            item,
                                                                                            item3
                                                                                        )
                                                                                    }
                                                                                    className="chuaXong"
                                                                                >
                                                                                    ☐
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <div>
                                                                            {item.daXong ===
                                                                            1 ? (
                                                                                <div className="daXong">
                                                                                    ✅
                                                                                </div>
                                                                            ) : (
                                                                                <div className="chuaXong">
                                                                                    ☐
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                        <div className="tinhTien-container">
                            <div className="tieuDe">
                                <i
                                    className="fa fa-usd"
                                    style={{
                                        color: "red",
                                    }}
                                ></i>
                                &#160;Tính Tiền-Chuyen khoan-Tien Mat
                            </div>
                            <div className="tongTien">
                                <div className="tieude">Tổng Tiền Hàng :</div>
                                <div className="sotien">
                                    <DinhDangSo
                                        soDinhDang={Tongtien}
                                        donViSo="vnd"
                                        // tt,sdt,vnd,f
                                    />
                                </div>
                            </div>
                            <div className="tongTien">
                                <div className="tieude">Giảm Trừ :</div>
                                <div className="giamTru">
                                    <BanPhimSo
                                        soMoi={giamTru}
                                        setsoMoi={setgiamTru}
                                    />
                                </div>
                                {+giamTru?.giaTri !==
                                    +donHang?.ttThem?.baoCao?.giamTru && (
                                    <div
                                        onClick={() => handleGiamGia()}
                                        className="ok"
                                    >
                                        OK
                                    </div>
                                )}
                            </div>
                            <div className="tongTien">
                                <div className="tieude">Cần Thanh Toán :</div>
                                <div className="sotien">
                                    <DinhDangSo
                                        soDinhDang={Tongtien - +giamTru?.giaTri}
                                        donViSo="vnd"
                                        // tt,sdt,vnd,f
                                    />
                                </div>
                            </div>
                            <div
                                className="ptThanhToan"
                                onClick={() => handleThanhToan()}
                            >
                                {!qr ? (
                                    <div className="tienMat">
                                        <i
                                            className="fa fa-eye"
                                            style={{
                                                color: "red",
                                            }}
                                        ></i>
                                        &#160;Mã QR Chuyển Khoản
                                    </div>
                                ) : (
                                    <div className="chuyenKhoan">
                                        <div className="tieuDe">
                                            <i
                                                className="fa fa-eye-slash"
                                                style={{
                                                    color: "red",
                                                }}
                                            ></i>
                                            &#160;Mã QR Chuyển Khoản
                                        </div>

                                        <img className="qr" src={qr} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="nguoiNhan-container">
                            <div className="tieuDe">
                                {" "}
                                <i
                                    className="fa fa-address-book"
                                    style={{
                                        color: "red",
                                    }}
                                ></i>
                                &#160;Thông Tin Người Nhận
                            </div>
                            {(donHang?.noiNhan === "Ship Tận Nơi" ||
                                donHang?.noiNhan === "Tự Đến Lấy") && (
                                <div className="thongTinNguoiNhan">
                                    <div className="thongTinChiTiet">
                                        <div className="noiDung">
                                            Nhóm KH :&nbsp;
                                            {
                                                donHang?.ttThem?.khachHang
                                                    ?.nhomKhach
                                            }
                                        </div>
                                    </div>
                                    <div className="thongTinChiTiet">
                                        <div className="noiDung">
                                            Nơi Nhận :&nbsp;
                                            {donHang?.noiNhan}
                                        </div>
                                    </div>
                                    <div className="thongTinChiTiet">
                                        <div className="noiDung">
                                            Họ Và Tên :&nbsp;
                                            {
                                                donHang?.ttThem?.khachHang
                                                    ?.hoTenNguoiMua
                                            }
                                        </div>
                                    </div>
                                    <div className="thongTinChiTiet">
                                        <a
                                            href={`tel:${donHang?.ttThem?.khachHang?.sdtNguoiMua}`}
                                            className="noiDung"
                                        >
                                            Số Điện Thoại :&nbsp; &nbsp;
                                            <i
                                                className="fa fa-phone-square"
                                                style={{ color: "#04aa6d" }}
                                            ></i>
                                            &nbsp;
                                            <DinhDangSo
                                                soDinhDang={
                                                    donHang?.ttThem?.khachHang
                                                        ?.sdtNguoiMua
                                                }
                                                donViSo="sdt"
                                                // tt,sdt,vnd,f
                                            />
                                        </a>
                                    </div>
                                    <div className="thongTinChiTiet">
                                        <div className="noiDung">
                                            Địa Chỉ :&nbsp;
                                            {donHang?.thonXomMua},&nbsp;
                                            {donHang?.xaMua},&nbsp;
                                            {donHang?.huyenMua},&nbsp;
                                            {donHang?.tinhMua}
                                        </div>
                                    </div>
                                    <div className="thongTinChiTiet">
                                        <div className="noiDung">
                                            Ghi Chú Thêm :&nbsp;
                                            {
                                                donHang?.ttThem?.khachHang
                                                    ?.ghiChuNguoiMua
                                            }
                                        </div>
                                    </div>
                                </div>
                            )}
                            {donHang?.noiNhan === "Nhận Tại Bàn" && (
                                <div className="thongTinNguoiNhan">
                                    <div className="thongTinChiTiet">
                                        <div className="noiDung">
                                            Nhóm KH :&nbsp;
                                            {
                                                donHang?.ttThem?.khachHang
                                                    ?.nhomKhach
                                            }
                                        </div>
                                    </div>
                                    <div className="thongTinChiTiet">
                                        <div className="noiDung">
                                            Nơi Nhận :&nbsp;
                                            {donHang?.noiNhan}
                                            &emsp;-&emsp;
                                            {donHang?.soBan}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {donHang?.trangThaiDH === "ĐH Mới" && (
                            <div
                                onClick={() =>
                                    handleThueShip("ĐH Tìm Ship Mới")
                                }
                                className="thueShip"
                            >
                                <i
                                    className="fas fa-ambulance"
                                    style={{
                                        color: "red",
                                    }}
                                ></i>
                                &#160;Thuê Ship - phuong thuc giao hang
                            </div>
                        )}
                        {donHang?.trangThaiDH === "ĐH Tìm Ship Mới" && (
                            <div
                                onClick={() => handleThueShip("ĐH Mới")}
                                className="thueShip"
                            >
                                <i
                                    className="fas fa-dolly-flatbed"
                                    style={{
                                        color: "red",
                                    }}
                                ></i>
                                &#160;Tự Ship - phuong thuc giao hang
                            </div>
                        )}
                        {donHang?.trangThaiDH === "ĐH Tìm Ship Mới" && (
                            <div className="thongTinShip">
                                <div className="noiGui">
                                    Nơi Gửi :&nbsp;{ttShop?.thonXom}
                                    ,&nbsp;
                                    {ttShop?.xa},&nbsp;
                                    {ttShop?.huyen},&nbsp; {ttShop?.tinh}
                                </div>

                                <a
                                    href={`tel:${donHang?.ttThem?.khachHang?.sdtNguoiMua}`}
                                    className="noiGui"
                                >
                                    SĐT Gửi :&nbsp; &nbsp;
                                    <i
                                        className="fa fa-phone-square"
                                        style={{ color: "#04aa6d" }}
                                    ></i>
                                    &nbsp;
                                    <DinhDangSo
                                        soDinhDang={ttShop?.sdtShop}
                                        donViSo="sdt"
                                        // tt,sdt,vnd,f
                                    />
                                </a>

                                <div className="noiGui">
                                    Nơi Nhận :&nbsp;
                                    {donHang?.thonXomMua}
                                    ,&nbsp;
                                    {donHang?.xaMua},&nbsp;
                                    {donHang?.huyenMua},&nbsp;
                                    {donHang?.tinhMua}
                                </div>
                                <a
                                    href={`tel:${donHang?.ttThem?.khachHang?.sdtNguoiMua}`}
                                    className="noiGui"
                                >
                                    SĐT Nhận :&nbsp; &nbsp;
                                    <i
                                        className="fa fa-phone-square"
                                        style={{ color: "#04aa6d" }}
                                    ></i>
                                    &nbsp;
                                    <DinhDangSo
                                        soDinhDang={
                                            donHang?.ttThem?.khachHang
                                                ?.sdtNguoiMua
                                        }
                                        donViSo="sdt"
                                        // tt,sdt,vnd,f
                                    />
                                </a>
                                <div className="phiShip-input">
                                    <div className="phiShip">Phí Ship :</div>
                                    <div className="input">
                                        <BanPhimSo
                                            soMoi={phiShip}
                                            setsoMoi={setphiShip}
                                        />
                                    </div>

                                    {+phiShip?.giaTri !==
                                        +donHang?.ttThem?.ttGiaoHang
                                            ?.phiShip && (
                                        <div
                                            onClick={() => handlePhiShip()}
                                            className="ok"
                                        >
                                            OK
                                        </div>
                                    )}
                                </div>
                                <div className="luuY">
                                    Lưu ý :
                                    <br />- Shop tự đặt phí ship phù hợp kích
                                    thước, khối lượng và khoảng cách giao hàng.
                                    Ví dụ: 10.000đ, 20.000đ, . . .
                                    <br />- Người Ship tự do chọn đơn nên đặt
                                    mức phí ship tốt sẽ nhanh được nhận hơn.
                                    <br />- Fabysa chịu trách nhiệm đền bù đúng
                                    bằng giá thu hộ hàng nếu Người Ship làm thất
                                    lạc.
                                </div>
                            </div>
                        )}
                        {donHang?.ttThem?.ttGiaoHang?.tenNv ? (
                            <div className="thongTinShip-container">
                                <div className="tieuDe">
                                    <i
                                        className="fas fa-archive"
                                        style={{
                                            color: "red",
                                        }}
                                    ></i>
                                    &#160;Thông Tin Giao Hàng
                                </div>
                                <div className="noiDung">
                                    Người Giao Hàng :&nbsp;
                                    {donHang?.ttThem?.ttGiaoHang?.tenNv}
                                </div>
                                <a
                                    href={`tel:${donHang?.ttThem?.ttGiaoHang?.sdtNv}`}
                                    className="noiDung"
                                >
                                    Số Điện Thoại :&nbsp; &nbsp;
                                    <i
                                        className="fa fa-phone-square"
                                        style={{ color: "#04aa6d" }}
                                    ></i>
                                    &nbsp;
                                    <DinhDangSo
                                        soDinhDang={
                                            donHang?.ttThem?.ttGiaoHang?.sdtNv
                                        }
                                        donViSo="sdt"
                                        // tt,sdt,vnd,f
                                    />
                                </a>
                            </div>
                        ) : (
                            <div>
                                {donHang?.trangThaiDH === "ĐH Tìm Ship Mới" && (
                                    <div className="thongTinShip-container">
                                        <div className="tieuDe">
                                            <i
                                                className="fas fa-archive"
                                                style={{
                                                    color: "red",
                                                }}
                                            ></i>
                                            &#160;Thông Tin Giao Hàng
                                        </div>
                                        <div className="noiDung">
                                            . . . vui lòng chờ shiper nhận đơn!
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        <div className="phimChucNang-container">
                            {!donHang?.donHang
                                ?.find((item) =>
                                    item?.allDacDiemSP?.find(
                                        (item2) =>
                                            item2?.daXong === 0 ||
                                            !item2?.daXong
                                    )
                                )
                                ?.allDacDiemSP?.find(
                                    (item3) =>
                                        item3?.daXong === 0 || !item3?.daXong
                                ) &&
                                donHang?.trangThaiDH === "ĐH Mới" && (
                                    <div className="phimChucNang">
                                        <div
                                            className="phim"
                                            onClick={() => handleDaThanhToan()}
                                        >
                                            Đã Thanh Toán
                                        </div>
                                    </div>
                                )}
                            {donHang?.trangThaiDH === "ĐH Đã Thanh Toán" && (
                                <div className="phimChucNang">
                                    <div
                                        className="phim"
                                        onClick={() => handleChuaThanhToan()}
                                    >
                                        Chưa Thanh Toán
                                    </div>
                                </div>
                            )}
                            {donHang?.trangThaiDH === "ĐH Huỷ" && (
                                <div className="phimChucNang"></div>
                            )}
                            {donHang?.trangThaiDH === "ĐH Ship Đã Nhận" && (
                                <div className="phimChucNang">
                                    <div
                                        className="phim"
                                        onClick={() => handleDangShip()}
                                    >
                                        ĐH Đang Ship
                                    </div>
                                </div>
                            )}
                            {donHang?.trangThaiDH === "ĐH Đang Ship" && (
                                <div className="phimChucNang"></div>
                            )}
                            {donHang?.trangThaiDH ===
                                "ĐH Ship Chưa Thanh Toán Về Shop" && (
                                <div className="phimChucNang">
                                    <div
                                        onClick={() => handleShipDaThanhToan()}
                                        className="phim"
                                    >
                                        Đã Thanh Toán
                                    </div>
                                </div>
                            )}
                            {donHang?.trangThaiDH ===
                                "ĐH Ship Đã Thanh Toán Về Shop" && (
                                <div className="phimChucNang">
                                    {/* <div
                                        className="phim"
                                        onClick={() =>
                                            handleTraHang(donHang?._id)
                                        }
                                    >
                                        Trả Hàng
                                    </div> */}
                                </div>
                            )}
                            {donHang?.trangThaiDH ===
                                "ĐH Ship Huỷ Chưa Hoàn Hàng" && (
                                <div className="phimChucNang">
                                    <div
                                        className="phim"
                                        onClick={() => handleShipDaHoanHang()}
                                    >
                                        Đã Hoàn Hàng
                                    </div>
                                </div>
                            )}
                            {donHang?.trangThaiDH ===
                                "ĐH Ship Huỷ Đã Hoàn Hàng" && (
                                <div className="phimChucNang"></div>
                            )}
                        </div>
                        <InHoaDon
                            donHang={donHang}
                            setTongtien={setTongtien}
                            Tongtien={Tongtien}
                            setgiamTru={setgiamTru}
                            giamTru={giamTru}
                            qr={qr}
                        />
                        {viewSanPhamXong === 1 && (
                            <div className="viewSanPhamXong">
                                <div className="sanPham-phimChucNang">
                                    <div className="sanPham">
                                        <div className="tenSanPham">
                                            {sanPhamXong?.TenSanPham}
                                        </div>
                                        <div
                                            onClick={() =>
                                                setviewSanPhamXong(0)
                                            }
                                            className="dongLai"
                                        >
                                            <i className="fa fa-remove"></i>
                                        </div>
                                    </div>
                                    <div className="tenDacDiem-tonKho-soLuong">
                                        <div className="tenDacDiem">
                                            {DacDiemSPXong?.tenDacDiem}
                                        </div>
                                        <div className="tonKho">
                                            Kho Còn <br />
                                            {
                                                sanPham?.allDacDiemSP?.find(
                                                    (item) =>
                                                        item?.tenDacDiem ===
                                                        DacDiemSPXong?.tenDacDiem
                                                )?.soLuong
                                            }
                                        </div>
                                        <div className="soLuong-SL">
                                            <div className="soLuong">
                                                SL Mua
                                            </div>
                                            <div className="thayDoiSl">
                                                <div
                                                    onClick={() =>
                                                        handleSoLuongDonHang(
                                                            "tru",
                                                            DacDiemSPXong,
                                                            sanPhamXong
                                                        )
                                                    }
                                                    className="giamSl"
                                                >
                                                    <i className="fa fa-arrow-circle-down"></i>
                                                </div>
                                                <input
                                                    type="number"
                                                    className="SL"
                                                    placeholder={
                                                        DacDiemSPXong?.slMua
                                                    }
                                                    onChange={(e) =>
                                                        handleSoLuongDonHang(
                                                            e.target.value,

                                                            DacDiemSPXong,
                                                            sanPhamXong
                                                        )
                                                    }
                                                />
                                                <div
                                                    onClick={() =>
                                                        handleSoLuongDonHang(
                                                            "cong",
                                                            DacDiemSPXong,
                                                            sanPhamXong
                                                        )
                                                    }
                                                    className="giamSl"
                                                >
                                                    <i className="fa fa-arrow-circle-up"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="daXong-chuaXong">
                                        {DacDiemSPXong?.daXong === 1 ? (
                                            <div
                                                onClick={() => handleChuaXong()}
                                                className="chuaXong"
                                            >
                                                Chưa Xong
                                            </div>
                                        ) : (
                                            <div
                                                onClick={() => handleDaXong()}
                                                className="daXong"
                                            >
                                                Đã Xong
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {chieuRongMH > "1023" && (
                <div className="pc">
                    <div className="DonHangShopChiTiet-ConTaiNer">
                        DonHangShopChiTiet
                    </div>
                </div>
            )}
        </div>
    );
};
export default DonHangShopChiTiet;
