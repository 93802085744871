import "./TrangCaNhan.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import anhHaiHuoc from "../assets/images/anhHaiHuoc.jpg";
import QrScanner from "qr-scanner";
import {
    getAllttShopTimKiem,
    getPost,
    logOut,
    getAllttShop,
    getTaiKhoanXacNhan,
} from "../redux/apiRequest";
import BanPhimSo from "../GiaoDienChung/BanPhimSo2";
import DinhDangSo from "../component/DinhDangSo";
import XemAnh2 from "../GiaoDienChung/XemAnh2";

const TrangCaNhan = () => {
    const chieuRongMH = window.innerWidth;

    const user = useSelector((state) => state.auth.login?.currentUser);
    const myDetail = useSelector((state) => state.post.post?.myDetail);

    console.log("myDetail", myDetail);
    const allShop = useSelector(
        (state) => state.ttShop.ttShop.allttShop?.AllShop
    );
    const ttShop = useSelector((state) => state.ttShop?.ttShop?.ttShop?.shop);
    const allTaiKhoan = useSelector(
        (state) => state?.taiKhoan?.taiKhoan?.allTaiKhoan?.allTaiKhoan
    );
    const allShopTimKiem = useSelector(
        (state) => state.ttShop.ttShop.allShopTimKiem?.shopTimKiem
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            getPost(user?._id, dispatch);
        }
    }, [user]);

    useEffect(() => {
        if (user) {
            getAllttShop(user?._id, dispatch);
        }
    }, [user]);

    useEffect(() => {
        if (user?.admin === true) {
            getTaiKhoanXacNhan(1, 0, 6, dispatch);
        }
    }, [user]);

    // Menu
    const [viewMenu, setviewMenu] = useState([
        "Hẹn Hò",
        "Cá Nhân",
        "Cửa Hàng",
        "Việc Làm",
        // "Hệ Thống",
        "Giao Hàng",
        "Mua Sắm",
    ]);
    const handleMenu = (tenDanhMuc) => {
        const xetTenDanhMuc = viewMenu?.find((item) => item === tenDanhMuc);
        if (xetTenDanhMuc) {
            setviewMenu(viewMenu?.filter((item) => item !== tenDanhMuc));
        } else {
            setviewMenu([...viewMenu, tenDanhMuc]);
        }
    };
    const handleChuyenHuongMenu = (tenDM) => {
        if (tenDM === "Thông Tin Cá Nhân") {
            if (!user) {
                const cauHoi = window.confirm("Đăng nhập để sử dụng !");
                if (cauHoi) {
                    navigate(`/dang-nhap`);
                }
            } else {
                navigate(`/sua-trang-ca-nhan/${tenDM}`);
            }
        }
        if (tenDM === "Fabysa Xanh") {
            if (!user) {
                const cauHoi = window.confirm("Đăng nhập để sử dụng !");
                if (cauHoi) {
                    navigate(`/dang-nhap`);
                }
            } else {
                if (!myDetail) {
                    const cauHoi = window.confirm(
                        "Cập nhật thông tin cá nhân để sử dụng !"
                    );
                    if (cauHoi) {
                        navigate(`/sua-trang-ca-nhan/Thông%20Tin%20Cá%20Nhân`);
                    }
                } else {
                    navigate(`/fabysa-xanh/a/${user?._id}`);
                }
            }
        }
        if (tenDM === "Tài Khoản Ngân Hàng") {
            if (!user) {
                const cauHoi = window.confirm("Đăng nhập để sử dụng !");
                if (cauHoi) {
                    navigate(`/dang-nhap`);
                }
            } else {
                navigate(`/sua-trang-ca-nhan/${tenDM}`);
            }
        }
        if (tenDM === "Cửa Hàng Follow") {
            if (!user) {
                const cauHoi = window.confirm("Đăng nhập để sử dụng !");
                if (cauHoi) {
                    navigate(`/dang-nhap`);
                }
            } else {
                if (!myDetail) {
                    const cauHoi = window.confirm(
                        "Cập nhật thông tin cá nhân để sử dụng !"
                    );
                    if (cauHoi) {
                        navigate(`/sua-trang-ca-nhan/Thông%20Tin%20Cá%20Nhân`);
                    }
                } else {
                    navigate(`/cua-hang-follow`);
                }
            }
        }
        if (tenDM === "Lịch Sử Mua Hàng") {
            if (!user) {
                const cauHoi = window.confirm("Đăng nhập để sử dụng !");
                if (cauHoi) {
                    navigate(`/dang-nhap`);
                }
            } else {
                if (!myDetail) {
                    const cauHoi = window.confirm(
                        "Cập nhật thông tin cá nhân để sử dụng !"
                    );
                    if (cauHoi) {
                        navigate(`/sua-trang-ca-nhan/Thông%20Tin%20Cá%20Nhân`);
                    }
                } else {
                    navigate(`/don-mua/a/a/a/a/a/a`);
                }
            }
        }
        if (tenDM === "Đổi Mật Khẩu") {
            if (!user) {
                const cauHoi = window.confirm("Đăng nhập để sử dụng !");
                if (cauHoi) {
                    navigate(`/dang-nhap`);
                }
            } else {
                navigate(`/change-password`);
            }
        }

        if (tenDM === "Hệ Thống") {
            if (!user) {
                const cauHoi = window.confirm("Đăng nhập để sử dụng !");
                if (cauHoi) {
                    navigate(`/dang-nhap`);
                }
            } else {
                navigate(`/change-password`);
            }
        }

        if (tenDM === "Bạn Quanh Đây") {
            if (!user) {
                const cauHoi = window.confirm("Đăng nhập để sử dụng !");
                if (cauHoi) {
                    navigate(`/dang-nhap`);
                }
            } else {
                navigate(`/`);
            }
        }
        if (tenDM === "Phòng Trò Chuyện") {
            if (!user) {
                const cauHoi = window.confirm("Đăng nhập để sử dụng !");
                if (cauHoi) {
                    navigate(`/dang-nhap`);
                }
            } else {
                navigate(`/`);
            }
        }

        if (tenDM === "Thêm Cửa Hàng") {
            if (!user) {
                const cauHoi = window.confirm("Đăng nhập để sử dụng !");
                if (cauHoi) {
                    navigate(`/dang-nhap`);
                }
            } else {
                navigate(`/them-cua-hang`);
            }
        }

        if (tenDM === "Nhận Giao Hàng") {
            navigate(`/giao-hang/Nhận%20Giao%20Hàng/ĐH%20Tìm%20Người%20Giao`);
        }
        if (tenDM === "Tìm Giao Hàng") {
            navigate(`/giao-hang/Tìm%20Giao%20Hàng/ĐH%20Tìm%20Người%20Giao`);
        }
    };
    // Menu

    //  Viet QR
    const [soTien, setsoTien] = useState({
        tenSo: "Số Tiền",
        giaTri: "0",
        loaiSo: "vnd",
    });
    const nganHang = myDetail?.ttThem?.nganHang?.tenNganHang || "MBBANK";
    const BANK_ID = myDetail?.ttThem?.nganHang?.maSoNganHang || "970422";
    const ACCOUNT_NO =
        myDetail?.ttThem?.nganHang?.taiKhoanNganHang || "0931969456666";
    const TEMPLATE = "print";
    const AMOUNT = +soTien?.giaTri;
    const DESCRIPTION = "";
    const ACCOUNT_NAME =
        myDetail?.ttThem?.nganHang?.chuTaiKhoanNganhang ||
        "TÀI KHOẢN VÍ DỤ";
    const qr = `https://img.vietqr.io/image/${BANK_ID}-${ACCOUNT_NO}-${TEMPLATE}.png?amount=${AMOUNT}&addInfo=${DESCRIPTION}&accountName=${ACCOUNT_NAME}`;
    // Viet QR

    // read qr code
    const [result, setResult] = useState("");
    const readCode = (e) => {
        console.log("e", e);
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        QrScanner.scanImage(file, { returnDetailedScanResult: true })
            .then((result) => setResult(result.data))
            .catch((e) => console.log(e));
    };
    useEffect(() => {
        if (result) {
            window.location.href = result;
        }
    }, [result]);
    // read qr code

    // timKiemShop
    useEffect(() => {
        if (myDetail) {
            const skip = 0;
            const limit = 100;
            const tenSdt = "";
            const kinhDo = "";
            const viDo = "";
            getAllttShopTimKiem(
                tenSdt,
                myDetail?.tinh,
                myDetail?.huyen,
                myDetail?.xa,
                kinhDo,
                viDo,
                skip,
                limit,
                dispatch
            );
        }
    }, [myDetail]);
    const [tenSdt, settenSdt] = useState("");
    const handleTimKiemShopTenSdt = () => {
        const skip = 0;
        const limit = 100;
        const tinh = "";
        const huyen = "";
        const xa = "";
        const kinhDo = "";
        const viDo = "";
        getAllttShopTimKiem(
            tenSdt,
            tinh,
            huyen,
            xa,
            kinhDo,
            viDo,
            skip,
            limit,
            dispatch
        );
    };
    const handleTimKiemShopToaDo = () => {
        const skip = 0;
        const limit = 100;
        const tenSdt = "";
        const tinh = "";
        const huyen = "";
        const xa = "";
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const kinhDo = position.coords.latitude;
                const viDo = position.coords.longitude;
                getAllttShopTimKiem(
                    tenSdt,
                    tinh,
                    huyen,
                    xa,
                    kinhDo,
                    viDo,
                    skip,
                    limit,
                    dispatch
                );
            });
        }
    };
    // timKiemShop

    const handleLogout = () => {
        logOut(dispatch);
    };

    return (
        <div className="view">
            {chieuRongMH <= "1023" && (
                <div className="mobile">
                    <div className="TrangCaNhan-ConTaiNer">
                        <div className="TrangCaNhan">
                            <div className="quayLai-tieuDe">
                                {ttShop ? (
                                    <a
                                        href={`/${ttShop?._id}/${ttShop?.tenVietTat}`}
                                        className="quayLai"
                                    >
                                        <i className="fa fa-angle-double-left"></i>
                                        Quay Lại
                                    </a>
                                ) : (
                                    <div className="fabysa">Fabysa</div>
                                )}

                                <div className="tieuDe">Trang Cá Nhân</div>
                                {user ? (
                                    <div
                                        onClick={() => handleLogout()}
                                        className="dangXuat"
                                    >
                                        Đăng Xuất
                                    </div>
                                ) : (
                                    <a href={`/dang-nhap`} className="dangNhap">
                                        Đăng Nhập
                                    </a>
                                )}
                            </div>
                            <div className="ttCaNhan-container">
                                <div
                                    onClick={() =>
                                        handleChuyenHuongMenu(
                                            "Thông Tin Cá Nhân"
                                        )
                                    }
                                    className="tieuDe"
                                >
                                    <i
                                        style={{
                                            color: "red",
                                        }}
                                        className="fa fa-edit"
                                    ></i>
                                    &nbsp;Thông Tin Cá Nhân
                                </div>
                                <div className="thongTin">
                                    <div className="hoTen-sdt">
                                        <div className="hoTen">
                                            Họ & Tên :&nbsp;
                                            {myDetail?.hoTen ||
                                                ". . . . . . . . . . . ."}
                                        </div>
                                        <div className="hoTen">
                                            Số Điện Thoại :&nbsp;
                                            {myDetail?.sdt ||
                                                ". . . . . . . . . . . ."}
                                        </div>
                                        <div className="hoTen">
                                            Giới Tính :&nbsp;
                                            {myDetail?.gioiTinh ||
                                                ". . . . . . . . . . . ."}
                                        </div>
                                        <div className="hoTen">
                                            Ngày Sinh :&nbsp;{" "}
                                            {myDetail?.ngaySinh || ". . . . "}/
                                            {myDetail?.thangSinh || " . . . . "}
                                            /{myDetail?.namSinh || " . . . . "}
                                        </div>
                                    </div>
                                    <div className="avatar-taiKhoan">
                                        <XemAnh2
                                            anhBanDau={
                                                myDetail?.avatar || anhHaiHuoc
                                            }
                                            cssAnhBanDau={{
                                                width: "23vw",
                                                height: "23vw",
                                                borderRadius: "50%",
                                            }}
                                        />
                                        <div
                                            onClick={() =>
                                                handleChuyenHuongMenu(
                                                    "Fabysa Xanh"
                                                )
                                            }
                                            className="tien-container"
                                        >
                                            <i
                                                className="fas fa-chart-line"
                                                style={{
                                                    color: "#04aa6d",
                                                }}
                                            ></i>
                                            &#160;
                                            <DinhDangSo
                                                soDinhDang={
                                                    myDetail?.fabysaXanh || "9000"
                                                }
                                                donViSo="f"
                                                // tt,sdt,vnd,f
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="diaChi">
                                    Địa Chỉ :&nbsp;
                                    {myDetail?.thonXom || ". . . . . . . . . "}
                                    ,&nbsp;
                                    {myDetail?.xa || ". . . . . . . . . "}
                                    ,&nbsp;
                                    {myDetail?.huyen || ". . . . . . . . . "}
                                    ,&nbsp;
                                    {myDetail?.tinh || ". . . . . . . . . "}
                                </div>
                            </div>
                            <div className="tkNganHang-container">
                                <div
                                    onClick={() =>
                                        handleChuyenHuongMenu(
                                            "Tài Khoản Ngân Hàng"
                                        )
                                    }
                                    className="tieuDe"
                                >
                                    <i
                                        style={{
                                            color: "red",
                                        }}
                                        className="fa fa-edit"
                                    ></i>
                                    &nbsp;Tài Khoản Ngân Hàng
                                </div>

                                <div className="taikhoan">
                                    <div className="ttTaiKhoan">
                                        <div className="nganHang">
                                            Ngân hàng
                                        </div>
                                        <div className="tenNganHang">
                                            {myDetail?.ttThem?.nganHang
                                                ?.tenNganHang || "MBBANK"}
                                        </div>

                                        <div className="nganHang">
                                            Số tài Khoản
                                        </div>
                                        <div className="tenNganHang">
                                            {myDetail?.ttThem?.nganHang
                                                ?.taiKhoanNganHang ||
                                                "0931969456666"}
                                        </div>
                                        <div className="nganHang">
                                            Chủ Tài Khoản
                                        </div>
                                        <div className="tenNganHang">
                                            {myDetail?.ttThem?.nganHang
                                                ?.chuTaiKhoanNganhang ||
                                                "TÀI KHOẢN VÍ DỤ"}
                                        </div>
                                        <div className="nganHang">
                                            Số Tiền Nhận
                                        </div>
                                        <div className="soTien">
                                            <BanPhimSo
                                                soMoi={soTien}
                                                setsoMoi={setsoTien}
                                            />
                                        </div>
                                    </div>
                                    <div className="qr-container">
                                        <XemAnh2
                                            anhBanDau={qr}
                                            cssAnhBanDau={{
                                                width: "48vw",
                                                objectFit: "cover",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {viewMenu?.find((item) => item === "Cá Nhân") ? (
                                <div className="qlCaNhan-container">
                                    <div
                                        onClick={() => handleMenu("Cá Nhân")}
                                        className="tieuDe-iCon"
                                    >
                                        <div className="tieuDe">Cá Nhân</div>
                                        <div className="iCon">
                                            <i className="fas fa-caret-down"></i>
                                        </div>
                                    </div>

                                    <div
                                        onClick={() =>
                                            handleChuyenHuongMenu(
                                                "Cửa Hàng Follow"
                                            )
                                        }
                                        className="noiDung"
                                    >
                                        <i
                                            className="fa fa-check-square"
                                            style={{
                                                color: "red",
                                                fontSize: "3.9vw",
                                            }}
                                        ></i>
                                        &nbsp;Cửa Hàng Follow
                                    </div>

                                    <div
                                        onClick={() =>
                                            handleChuyenHuongMenu(
                                                "Lịch Sử Mua Hàng"
                                            )
                                        }
                                        className="noiDung"
                                    >
                                        <i
                                            className="far fa-file-alt"
                                            style={{
                                                color: "red",
                                                fontSize: "3.9vw",
                                            }}
                                        ></i>
                                        &nbsp;Lịch Sử Mua Hàng
                                    </div>

                                    <div
                                        onClick={() =>
                                            handleChuyenHuongMenu(
                                                "Đổi Mật Khẩu"
                                            )
                                        }
                                        className="noiDung"
                                    >
                                        <i
                                            className="fas fa-lock"
                                            style={{
                                                color: "red",
                                                fontSize: "3.9vw",
                                            }}
                                        ></i>
                                        &#160;Đổi Mật Khẩu
                                    </div>
                                </div>
                            ) : (
                                <div className="qlCaNhan-container">
                                    <div className="tieuDe-iCon">
                                        <div
                                            onClick={() =>
                                                handleMenu("Cá Nhân")
                                            }
                                            className="tieuDe"
                                        >
                                            Cá Nhân
                                        </div>
                                        <div className="iCon">
                                            <i className="fas fa-caret-left"></i>
                                        </div>
                                    </div>
                                    {user && user?.admin === true && (
                                        <div
                                            onClick={() =>
                                                handleMenu("Hệ Thống")
                                            }
                                            className="noiDung"
                                        >
                                            <i
                                                className="fas fa-lock"
                                                style={{
                                                    color: "red",
                                                    fontSize: "3.9vw",
                                                }}
                                            ></i>
                                            &#160;Hệ Thống
                                        </div>
                                    )}
                                </div>
                            )}
                            {viewMenu?.find((item) => item === "Hệ Thống") &&
                                user?.admin === true && (
                                    <div className="qlCaNhan-container">
                                        <div
                                            onClick={() =>
                                                handleMenu("Hệ Thống")
                                            }
                                            className="tieuDe-iCon"
                                        >
                                            <div className="tieuDe">
                                                Hệ Thống
                                            </div>
                                            <div className="iCon">
                                                <i className="fas fa-caret-down"></i>
                                            </div>
                                        </div>
                                        <div className="noiDung">Tài Khoản</div>
                                        <div className="noiDung">
                                            Danh Sách Shop
                                        </div>
                                        <div className="noiDung">
                                            Danh Sách Người Dùng
                                        </div>
                                        <div className="noiDung">Thêm Shop</div>
                                        <div className="noiDung">
                                            Reset Mật Khẩu
                                        </div>
                                        <div className="noiDung">
                                            Tìm Việc Làm
                                        </div>
                                        <div className="noiDung">
                                            Tìm Người Làm
                                        </div>

                                        <a
                                            href={` /thu-nghiem`}
                                            className="noiDung"
                                        >
                                            Thử Nghiệm
                                        </a>

                                        <a
                                            href={`/xac-nhan-tk`}
                                            className="noiDung"
                                        >
                                            Xác Nhận Tài Khoản{" "}
                                            <span
                                                style={{
                                                    color: "red",
                                                    fontSize: "3.9vw",
                                                }}
                                            >
                                                {allTaiKhoan?.length}
                                            </span>
                                        </a>
                                        <a
                                            href={`/mini-game/a/a/a/a/a/a`}
                                            className="noiDung"
                                        >
                                            Mini Game
                                        </a>
                                        <a
                                            href={`/don-vi-hanh-chinh-vn`}
                                            className="noiDung"
                                        >
                                            Đơn Vị Hành Chính VN
                                        </a>
                                    </div>
                                )}
                            {viewMenu?.find((item) => item === "Hẹn Hò") ? (
                                <div className="qlCaNhan-container">
                                    <div
                                        onClick={() => handleMenu("Hẹn Hò")}
                                        className="tieuDe-iCon"
                                    >
                                        <div className="tieuDe">Hẹn Hò</div>
                                        <div className="iCon">
                                            <i className="fas fa-caret-down"></i>
                                        </div>
                                    </div>

                                    <div
                                        onClick={() =>
                                            handleChuyenHuongMenu(
                                                "Bạn Quanh Đây"
                                            )
                                        }
                                        className="noiDung"
                                    >
                                        <i
                                            className="fa fa-users"
                                            style={{
                                                color: "red",
                                                fontSize: "3.9vw",
                                            }}
                                        ></i>
                                        &nbsp;Bạn Quanh Đây
                                    </div>
                                    <div
                                        onClick={() =>
                                            handleChuyenHuongMenu(
                                                "Phòng Trò Chuyện"
                                            )
                                        }
                                        className="noiDung"
                                    >
                                        <i
                                            className="far fa-comments"
                                            style={{
                                                color: "red",
                                                fontSize: "3.9vw",
                                            }}
                                        ></i>
                                        &nbsp;Phòng Trò Chuyện
                                    </div>
                                </div>
                            ) : (
                                <div className="qlCaNhan-container">
                                    <div className="tieuDe-iCon">
                                        <div
                                            onClick={() => handleMenu("Hẹn Hò")}
                                            className="tieuDe"
                                        >
                                            Hẹn Hò
                                        </div>
                                        <div className="iCon">
                                            <i className="fas fa-caret-left"></i>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {viewMenu?.find((item) => item === "Giao Hàng") ? (
                                <div className="qlCaNhan-container">
                                    <div
                                        onClick={() => handleMenu("Giao Hàng")}
                                        className="tieuDe-iCon"
                                    >
                                        <div className="tieuDe">Giao Hàng</div>
                                        <div className="iCon">
                                            <i className="fas fa-caret-down"></i>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() =>
                                            handleChuyenHuongMenu(
                                                "Nhận Giao Hàng"
                                            )
                                        }
                                        className="noiDung"
                                    >
                                        <i
                                            className="fas fa-shipping-fast"
                                            style={{
                                                color: "red",
                                                fontSize: "3.9vw",
                                            }}
                                        ></i>
                                        &nbsp;Nhận Giao Hàng
                                    </div>
                                    <div
                                        onClick={() =>
                                            handleChuyenHuongMenu(
                                                "Tìm Giao Hàng"
                                            )
                                        }
                                        className="noiDung"
                                    >
                                        <i
                                            className="fa fa-shopping-bag"
                                            style={{
                                                color: "red",
                                                fontSize: "3.9vw",
                                            }}
                                        ></i>
                                        &nbsp;Tìm Giao Hàng
                                    </div>
                                </div>
                            ) : (
                                <div className="qlCaNhan-container">
                                    <div className="tieuDe-iCon">
                                        <div
                                            onClick={() =>
                                                handleMenu("Giao Hàng")
                                            }
                                            className="tieuDe"
                                        >
                                            Giao Hàng
                                        </div>
                                        <div className="iCon">
                                            <i className="fas fa-caret-left"></i>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {viewMenu?.find((item) => item === "Cửa Hàng") ? (
                                <div className="qlCaNhan-container">
                                    <div
                                        onClick={() => handleMenu("Cửa Hàng")}
                                        className="tieuDe-iCon"
                                    >
                                        <div className="tieuDe">Cửa Hàng</div>
                                        <div className="iCon">
                                            <i className="fas fa-caret-down"></i>
                                        </div>
                                    </div>
                                    {user?.admin === true ||
                                    !allShop ||
                                    allShop?.length <= 3 ? (
                                        <div
                                            onClick={() =>
                                                handleChuyenHuongMenu(
                                                    "Thêm Cửa Hàng"
                                                )
                                            }
                                            className="noiDung"
                                        >
                                            <i
                                                className="fa fa-plus-square"
                                                style={{
                                                    color: "red",
                                                    fontSize: "3.9vw",
                                                }}
                                            ></i>
                                            &nbsp;Thêm Cửa Hàng
                                        </div>
                                    ) : (
                                        <div
                                            onClick={() =>
                                                alert("Đã mở 3 cửa hàng !")
                                            }
                                            className="noiDung"
                                        >
                                            <i
                                                className="fa fa-plus-square"
                                                style={{
                                                    color: "red",
                                                    fontSize: "3.9vw",
                                                }}
                                            ></i>
                                            &nbsp;Thêm Cửa Hàng
                                        </div>
                                    )}
                                    {allShop &&
                                        allShop?.length !== 0 &&
                                        allShop?.map((item, index) => {
                                            return (
                                                <a
                                                    key={item._id}
                                                    className="noiDung"
                                                    href={`/${item?._id}/${item?.tenVietTat}`}
                                                >
                                                    <i
                                                        style={{
                                                            color: "red",
                                                            fontSize: "3.9vw",
                                                        }}
                                                    >
                                                        {index + 1}
                                                    </i>
                                                    &#160; {item?.TenShop}
                                                </a>
                                            );
                                        })}
                                </div>
                            ) : (
                                <div className="qlCaNhan-container">
                                    <div className="tieuDe-iCon">
                                        <div
                                            onClick={() =>
                                                handleMenu("Cửa Hàng")
                                            }
                                            className="tieuDe"
                                        >
                                            Cửa Hàng
                                        </div>
                                        <div className="iCon">
                                            <i className="fas fa-caret-left"></i>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {viewMenu?.find((item) => item === "Mua Sắm") ? (
                                <div className="qlCaNhan-container">
                                    <div
                                        onClick={() => handleMenu("Mua Sắm")}
                                        className="tieuDe-iCon"
                                    >
                                        <div className="tieuDe">Mua Sắm</div>
                                        <div className="iCon">
                                            <i className="fas fa-caret-down"></i>
                                        </div>
                                    </div>
                                    <div
                                        className="noiDung"
                                        onClick={() => handleTimKiemShopToaDo()}
                                    >
                                        <i
                                            className="fa fa-search"
                                            style={{ color: "red" }}
                                        ></i>
                                        &nbsp;Tìm Kiếm
                                    </div>
                                    <div>
                                        <input
                                            id="anhQr"
                                            hidden
                                            type="file"
                                            onChange={(e) => readCode(e)}
                                        />
                                        <label
                                            className="noiDung"
                                            htmlFor="anhQr"
                                        >
                                            <i
                                                className="fa fa-qrcode"
                                                style={{ color: "red" }}
                                            ></i>
                                            &nbsp;Quét Mã QR
                                        </label>
                                    </div>
                                    <div
                                        className="noiDung"
                                        onClick={() => handleTimKiemShopToaDo()}
                                    >
                                        <i
                                            className="fas fa-map-marker-alt"
                                            style={{ color: "red" }}
                                        ></i>
                                        &nbsp;Cửa Hàng Quanh Đây
                                    </div>
                                    <div className="danhSachCuaHang">
                                        <div>
                                            ...
                                        </div>
                                        {allShopTimKiem &&
                                            allShopTimKiem?.map(
                                                (item, index) => {
                                                    return (
                                                        <a
                                                            href={`/a/${item._id}/a/a/a/a`}
                                                            key={index}
                                                            className="allShoplike"
                                                        >
                                                            <div className="tenShop">
                                                                {index + 1}
                                                                .&nbsp;
                                                                {item.TenShop}
                                                            </div>

                                                            <div className="diaChi">
                                                                {item.xa},&nbsp;{" "}
                                                                {item.huyen}
                                                                ,&nbsp;{" "}
                                                                {item.tinh}
                                                            </div>
                                                        </a>
                                                    );
                                                }
                                            )}
                                    </div>
                                </div>
                            ) : (
                                <div className="qlCaNhan-container">
                                    <div className="tieuDe-iCon">
                                        <div
                                            onClick={() =>
                                                handleMenu("Mua Sắm")
                                            }
                                            className="tieuDe"
                                        >
                                            Mua Sắm
                                        </div>
                                        <div className="iCon">
                                            <i className="fas fa-caret-left"></i>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {chieuRongMH > "1023" && (
                <div className="pc">
                    <div className="TrangCaNhan-ConTaiNer"></div>
                </div>
            )}
        </div>
    );
};
export default TrangCaNhan;
