import Resizer from "react-image-file-resizer";
const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            720,
            720,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });
export const resizeAnh = async (event) => {
    try {
        const file = event.target.files[0];
        const image = await resizeFile(file);
        return image;
    } catch (err) {
        console.log(err);
    }
};
