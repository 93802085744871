import "./NganHangShop.scss";
import { useState, useRef } from "react";
import BanPhimSo from "../GiaoDienChung/BanPhimSo";
import XemAnh2 from "../GiaoDienChung/XemAnh2";
import { useEffect } from "react";
const NganHangShop = (props) => {
    const { ttShop, loading, setloading } = props;
    const chieuRongMH = window.innerWidth;
    const [soTien, setsoTien] = useState(0);
    const VND = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND",
    });
    // BanPhimSo
    const [soThayThe, setsoThayThe] = useState();
    const [danhSachSo, setdanhSachSo] = useState([]);
    const handleDinhDangSo = (data) => {
        const n = +data;
        return n.toFixed(0).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "," && (a.length - i) % 3 === 0 ? "." + c : c;
        });
    };
    const handleBanPhimSo = (soCanThay, giaTriSoThayThe) => {
        if (!giaTriSoThayThe) {
            setsoThayThe(soCanThay);
            if (!danhSachSo?.find((item) => item?.tenSo === soCanThay?.tenSo)) {
                setdanhSachSo([soCanThay, ...danhSachSo]);
            }
        } else {
            if (soThayThe && danhSachSo && danhSachSo?.length !== 0) {
                if (giaTriSoThayThe !== "xoa") {
                    const timSoThayThe = danhSachSo.find(
                        (item) => item?.tenSo === soThayThe?.tenSo
                    );
                    const soThayThe2 = {
                        tenSo: soThayThe?.tenSo,
                        giaTri: timSoThayThe?.giaTri + giaTriSoThayThe,
                    };
                    setdanhSachSo(
                        danhSachSo?.map((item) =>
                            item?.tenSo === soThayThe?.tenSo ? soThayThe2 : item
                        )
                    );
                } else {
                    const timSoThayThe = danhSachSo.find(
                        (item) => item?.tenSo === soThayThe?.tenSo
                    );
                    const soThayThe2 = {
                        tenSo: soThayThe?.tenSo,
                        giaTri: timSoThayThe?.giaTri.slice(0, -1),
                    };
                    setdanhSachSo(
                        danhSachSo?.map((item) =>
                            item?.tenSo === soThayThe?.tenSo ? soThayThe2 : item
                        )
                    );
                }
            }
        }
    };
    // BanPhimSo
    useEffect(() => {
        setsoTien(
            +danhSachSo?.find((item) => item?.tenSo === "Số Tiền Nhận")?.giaTri
        );
    }, [soThayThe]);
    //  Viet QR
    const BANK_ID = ttShop?.ttShopThem?.nganHang?.maSo;
    const ACCOUNT_NO = ttShop?.ttShopThem?.nganHang?.taiKhoanNganHang;
    const TEMPLATE = "print";
    const AMOUNT = +soTien;
    const DESCRIPTION = "";
    const ACCOUNT_NAME = ttShop?.ttShopThem?.nganHang?.chuTaiKhoanNganhang;
    const qr = `https://img.vietqr.io/image/${BANK_ID}-${ACCOUNT_NO}-${TEMPLATE}.png?amount=${AMOUNT}&addInfo=${DESCRIPTION}&accountName=${ACCOUNT_NAME}`;
    // Viet QR
    // xemAnhFull
    // const [xemAnhFull, setxemAnhFull] = useState();
    // const handleXemAnhFull = (anh) => {
    //     setxemAnhFull(anh);
    // };
    // xemAnhFull
    return (
        <div className="view">
            {chieuRongMH <= "1023" && (
                <div className="mobile">
                    <div className="NganHangShop-ConTaiNer">
                        <div className="nganHang-container">
                            <div className="quayLai-tieuDe">
                                <div
                                    className="quayLai"
                                    onClick={() => setloading(0)}
                                >
                                    <i className="fa fa-angle-double-left"></i>
                                    Quay Lại
                                </div>
                                <div className="tieuDe">Ngân Hàng</div>
                            </div>
                            <div className="ttTaiKhoan">
                                Thông Tin Tài Khoản
                            </div>
                            <div className="chiTietTaiKhoan">
                                Ngân Hàng:&nbsp;
                                {ttShop?.ttShopThem?.nganHang?.tenNganHang}
                                <br />
                                Số Tài Khoản:&nbsp;
                                {ttShop?.ttShopThem?.nganHang?.taiKhoanNganHang}
                                <br />
                                Chủ Tài Khoản:&nbsp;
                                {
                                    ttShop?.ttShopThem?.nganHang
                                        ?.chuTaiKhoanNganhang
                                }
                            </div>
                            <div
                                className="nhapSoTien"
                                onClick={() =>
                                    handleBanPhimSo(
                                        {
                                            tenSo: "Số Tiền Nhận",
                                            giaTri: "0",
                                        },
                                        ""
                                    )
                                }
                            >
                                {VND.format(
                                    danhSachSo?.find(
                                        (item) => item?.tenSo === "Số Tiền Nhận"
                                    )?.giaTri || 0
                                )}
                            </div>
                            {soThayThe && (
                                <BanPhimSo
                                    handleBanPhimSo={handleBanPhimSo}
                                    soThayThe={soThayThe}
                                    setsoThayThe={setsoThayThe}
                                    danhSachSo={danhSachSo}
                                    setdanhSachSo={setdanhSachSo}
                                    handleDinhDangSo={handleDinhDangSo}
                                />
                            )}
                            <div className="maQr">Chuyển Khoản Nhanh</div>
                            <XemAnh2
                                anhBanDau={qr}
                                cssAnhBanDau={{
                                    width: "96vw",
                                    objectFit: "cover",
                                    marginBottom: "30vw",
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
            {chieuRongMH > "1023" && (
                <div className="pc">
                    <div className="NganHangShop-ConTaiNer">NganHangShop</div>
                </div>
            )}
        </div>
    );
};
export default NganHangShop;
