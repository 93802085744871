import "./BanPhimSo2.scss";
import React, { useState } from "react";
import DinhDangSo from "../component/DinhDangSo";
const BanPhimSo = (props) => {
    const { soMoi, setsoMoi } = props;
    const [viewBanPhimSo, setviewBanPhimSo] = useState("");
    const handleBanPhimSo = (giaTriMoi) => {
        if (giaTriMoi !== "xoa") {
            setsoMoi({
                tenSo: soMoi?.tenSo,
                giaTri: soMoi?.giaTri + giaTriMoi,
                loaiSo: soMoi?.loaiSo,
            });
        } else {
            setsoMoi({
                tenSo: soMoi?.tenSo,
                giaTri: soMoi?.giaTri?.toString()?.slice(0, -1),
                loaiSo: soMoi?.loaiSo,
            });
        }
    };
    return (
        <span className="banPhim">
            <span className="sua-so" onClick={() => setviewBanPhimSo("true")}>
                <span className="sua-so">
                    <span className="sua">
                        &#160;
                        <i
                            className="far fa-edit"
                            style={{
                                color: "#04aa6d",
                            }}
                        ></i>
                        &#160;
                    </span>
                    <span className="so">
                        <DinhDangSo
                            soDinhDang={soMoi?.giaTri}
                            donViSo={soMoi?.loaiSo}
                        />
                    </span>
                </span>
            </span>
            {viewBanPhimSo === "true" && (
                <span className="BanPhimSo-ConTaiNer">
                    <span className="soSua-banPhimSo">
                        <span className="tat-tenSo-xong">
                            <span className="tenSo">{soMoi?.tenSo}</span>
                            <span
                                onClick={() => setviewBanPhimSo()}
                                className="xong"
                            >
                                <i
                                    className="fa fa-close"
                                    style={{
                                        color: "red",
                                    }}
                                ></i>
                            </span>
                        </span>
                        <span className="soMoi">
                            <DinhDangSo
                                soDinhDang={soMoi?.giaTri}
                                donViSo={soMoi?.loaiSo}
                            />
                        </span>
                        <span className="banPhimSo">
                            <span onClick={() => handleBanPhimSo("1")}>1</span>
                            <span onClick={() => handleBanPhimSo("2")}>2</span>
                            <span onClick={() => handleBanPhimSo("3")}>3</span>
                            <span onClick={() => handleBanPhimSo("4")}>4</span>
                            <span onClick={() => handleBanPhimSo("5")}>5</span>
                            <span onClick={() => handleBanPhimSo("6")}>6</span>
                            <span onClick={() => handleBanPhimSo("7")}>7</span>
                            <span onClick={() => handleBanPhimSo("8")}>8</span>
                            <span onClick={() => handleBanPhimSo("9")}>9</span>
                            <span onClick={() => handleBanPhimSo("000")}>
                                .000
                            </span>
                            <span onClick={() => handleBanPhimSo("0")}>0</span>
                            <span onClick={() => handleBanPhimSo("xoa")}>
                                <i className="fas fa-chevron-circle-left"></i>
                            </span>
                        </span>
                    </span>
                </span>
            )}
        </span>
    );
};
export default BanPhimSo;
