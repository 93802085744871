import "./XemAnh2.scss";
import React, { useRef, useState, useEffect } from "react";
const XemAnh2 = (props) => {
    const { anhBanDau, cssAnhBanDau } = props;
    const [anhFullMan, setanhFullMan] = useState("");
      const chieuRongMH = window.innerWidth;

    //     <XemAnh2
    //     anhBanDau={vocher}
    //     cssAnhBanDau={{
    //         width: "20vw",
    //         height: "20vw",
    //         borderRadius: "50%",
    //     }}
    // />
    return (
        


        
<span className="view">
{chieuRongMH <= "1023" && (
    <span className="mobile">
        <span className="XemAnh2-ConTaiNer">
            {anhBanDau && (
                <img
                    onClick={() => setanhFullMan(anhBanDau)}
                    src={anhBanDau}
                    style={cssAnhBanDau}
                />
            )}
            {anhFullMan && (
                <span className="anhFullMan">
                    <span className="close" onClick={() => setanhFullMan()}>
                        <i className="fa fa-remove"></i>
                    </span>
                    <span className="xemAnh">
                        <img src={anhFullMan} className="anh" />
                    </span>
                </span>
            )}
        </span>
    </span>
)}
{chieuRongMH > "1023" && (
    <span className="pc">
        <span className="XemAnh2-ConTaiNer">
            {anhBanDau && (
                <img
                    onClick={() => setanhFullMan(anhBanDau)}
                    src={anhBanDau}
                    style={cssAnhBanDau}
                />
            )}
            {anhFullMan && (
                <span className="anhFullMan">
                    <span className="close" onClick={() => setanhFullMan()}>
                        <i className="fa fa-remove"></i>
                    </span>
                    <span className="xemAnh">
                        <img src={anhFullMan} className="anh" />
                    </span>
                </span>
            )}
        </span>
    </span>
)}
</span>
    );
};
export default XemAnh2;
