import "./DinhDangSo.scss";

const DinhDangSo = (props) => {
    const { soDinhDang, donViSo } = props;
    const chieuRongMH = window.innerWidth;
    const handleDinhDangSo = (soDinhDang) => {
        const n = +soDinhDang;
        return n.toFixed(0).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "," && (a.length - i) % 3 === 0 ? "." + c : c;
        });
    };
    // phan loai don vi: thong thuong - tt, vnd,fabysa
    return (
        <span className="view">
            {chieuRongMH <= "1023" && (
                <span className="mobile">
                    <span className="DinhDangSo-ConTaiNer">
                        {(!donViSo || donViSo === "tt") && (
                            <span className="cssSo">
                                {handleDinhDangSo(soDinhDang)}
                            </span>
                        )}
                        {donViSo === "stk" && (
                            <span className="cssSo">
                                0{handleDinhDangSo(soDinhDang)}
                            </span>
                        )}
                        {donViSo === "sdt" && (
                            <a href={`tel:${soDinhDang}`} className="cssSo">
                                <i
                                    className="fa fa-phone-square"
                                    style={{ color: "#04aa6d" }}
                                ></i>
                                &#160;0{handleDinhDangSo(soDinhDang)}
                            </a>
                        )}
                        {donViSo === "vnd" && (
                            <span className="cssSo">
                                {handleDinhDangSo(soDinhDang)}&#160;
                                <span className="vnd">đ</span>
                            </span>
                        )}
                        {(donViSo === "fabysa" || donViSo === "f") && (
                            <span className="cssSo">
                                {handleDinhDangSo(soDinhDang)}&#160;
                                <span className="fabysa">F</span>
                            </span>
                        )}
                    </span>
                </span>
            )}
            {chieuRongMH > "1023" && (
                <span className="pc">
                    <span className="DinhDangSo-ConTaiNer">DinhDangSo</span>
                </span>
            )}
        </span>
    );
};
export default DinhDangSo;
