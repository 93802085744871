import "./QuanLyShop.scss";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getttShop } from "../redux/apiRequest";
import { useEffect } from "react";
import logoFabysa from "../assets/images/logoFabysa.png";
const QuanLyShop = () => {
    const ttShop = useSelector((state) => state.ttShop.ttShop.ttShop?.shop);
    const chieuRongMH = window.innerWidth;
    const { idShop } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!ttShop) {
            getttShop(idShop, dispatch);
        }
    }, [idShop]);
    return (
        <div className="view">
            {chieuRongMH <= "1023" && (
                <div className="mobile">
                    <div className="QuanLyShop-ConTaiNer">
                        <div className="quayLai-tieuDe">
                            <a
                                href={`/${ttShop?._id}/${ttShop?.tenVietTat}`}
                                className="quayLai"
                            >
                                <i className="fa fa-angle-double-left"></i>
                                Quay Lại
                            </a>
                            <div className="tieuDe">Quản Lý Shop</div>
                        </div>
                        <div className="quanLyShop">
                            <a
                                href={`/update-shop/${ttShop?._id}`}
                                className="quanLy"
                            >
                                <span>
                                    <i className="fas fa-store-alt"></i>&#160;
                                </span>
                                Thông Tin Shop
                            </a>
                            <a
                                href={`/sua-menu/${ttShop?._id}`}
                                className="quanLy"
                            >
                                <span>
                                    <i className="fa fa-bars"></i>&#160;
                                </span>
                                Menu & Sản Phẩm
                            </a>

                            <a
                                href={`/nhan-vien/${ttShop?._id}`}
                                className="quanLy"
                            >
                                <span>
                                    <i className="fas fa-user-clock"></i>&#160;
                                </span>
                                Nhân Viên
                            </a>
                            <a
                                href={`/doi-tac/${ttShop?._id}`}
                                className="quanLy"
                            >
                                <span>
                                    <i className="fas fa-user-tie"></i>&#160;
                                </span>
                                Khách Hàng
                            </a>
                            <a
                                href={`/don-hang/${ttShop?._id}`}
                                className="quanLy"
                            >
                                <span>
                                    <i className="fas fa-shopping-bag"></i>
                                    &#160;
                                </span>
                                Đơn Hàng
                            </a>
                            <a
                                href={`/bao-cao-shop/${ttShop?.ttShopThem?.tenVietTat}/${idShop}/a/a/a/`}
                                className="quanLy"
                            >
                                <span>
                                    <i className="fa fa-bar-chart"></i>&#160;
                                </span>
                                Báo Cáo Kinh Doanh
                            </a>
                            <a
                                href={`/tai-khoan/a/a/a/a/a/a/${ttShop?._id}`}
                                className="quanLy"
                            >
                                <span className="fabysaXanh">F&#160;</span>
                                Fabysa Xanh
                            </a>
                        </div>
                        <div className="gioiThieuFabysa">
                            <div className="logoDangNhap">
                                <img src={logoFabysa} alt="he" />
                            </div>
                            <div className="tieuDe">Fabysa.com</div>
                            <div className="noiDung">
                                Bán hàng nhanh, mua hàng dễ !
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {chieuRongMH > "1023" && ttShop && (
                <div className="pc">
                    <div className="QuanLyShop-ConTaiNer">QuanLyShop</div>
                </div>
            )}
        </div>
    );
};
export default QuanLyShop;
