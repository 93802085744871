import "./ThuNghiem.scss";
import React, { useRef, useState, useEffect } from "react";
import { guiMail } from "../redux/apiRequest";
const ThuNghiem = () => {
    const chieuRongMH = window.innerWidth;
    const chieuCaoMH = window.innerHeight;
    console.log("chieuCaoMH", chieuCaoMH);
    const linkDonHang =
        "https://fabysa.com/giao-hang/Nhận%20Giao%20Hàng/ĐH%20Tìm%20Người%20Giao";
    const email1 = "hungtranvan.0976993229@gmail.com";
    const email2 = "hungtranvan.01656303797@gmail.com";
    console.log("`${email1} ${email2}`", `${email1} ${email2}`);
    const handleGuiMail = () => {
        const newEmail = {
            // email: `${email1}, ${email2}`,
            email: "hungtranvan.01656303797@gmail.com",
            subject: "Cong Nghe So Minh Hung",
            html: `<a style='text-decoration: none' href=${linkDonHang}>Đơn hàng mới . . . !</a>`,
        };
        guiMail(newEmail);
    };
    return (
        <div className="view">
            {chieuRongMH <= "1023" && (
                <div className="mobile">
                    <div className="ThuNghiem-ConTaiNer">
                        <button onClick={() => window.history.back()}>
                            Thử Nghiệm
                        </button>
                        <div>........</div>
                        <button onClick={() => handleGuiMail()}>guiMai</button>
                    </div>
                </div>
            )}
            {chieuRongMH > "1023" && (
                <div className="pc">
                    <div className="ThuNghiem-ConTaiNer">
                        <button onClick={() => window.history.back()}>
                            Thử Nghiệm
                        </button>
                        <div>........</div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default ThuNghiem;
