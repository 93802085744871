import "./ThemDonHangGiao.scss";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { registerDonHang, getAllPosts, guiMail } from "../redux/apiRequest";
import ChonDiaChi from "../component/ChonDiaChi";
import BanPhimSo from "../GiaoDienChung/BanPhimSo2";
import DinhDangSo from "../component/DinhDangSo";
const ThemDonHangGiao = () => {
    const chieuRongMH = window.innerWidth;
    const myDetail = useSelector((state) => state.post.post?.myDetail);
    const allPosts = useSelector((state) => state.post.post?.allPosts);
    console.log("allPosts", allPosts);
    console.log("myDetail", myDetail);
    const user = useSelector((state) => state.auth.login.currentUser);
    const dispatch = useDispatch();

    const [tenGui, settenGui] = useState();
    const [sdtGui, setsdtGui] = useState();
    const [thonGui, setthonGui] = useState();
    const [xaGui, setxaGui] = useState();
    const [huyenGui, sethuyenGui] = useState();
    const [tinhGui, settinhGui] = useState();

    const [tenNhan, settenNhan] = useState("Nhập họ và tên");
    const [sdtNhan, setsdtNhan] = useState("Nhập số điện thoại");
    const [thonXom, setthonXom] = useState("Thôn/Xóm/Số nhà . . .");
    const [xa, setxa] = useState("Xã/Phường");
    const [huyen, sethuyen] = useState("Quận/Huyện");
    const [tinh, settinh] = useState("Tỉnh/TP");

    const [kinhDo, setkinhDo] = useState(0);
    const [viDo, setviDo] = useState(0);

    useEffect(() => {
        if (myDetail && myDetail?.length !== 0) {
            settenGui(myDetail?.hoTen);
            setsdtGui(myDetail?.soDienThoai);
            setthonGui(myDetail?.thonXom || "Thôn/Xóm/Số nhà...");
            setxaGui(myDetail?.xa || "Xã/Phường");
            sethuyenGui(myDetail?.huyen || "Quận/Huyện");
            settinhGui(myDetail?.tinh || "Tỉnh/TP");
            setkinhDo(myDetail?.kinhDo);
            setviDo(myDetail?.viDo);
        }
    }, [myDetail]);
    useEffect(() => {
        const skip = 0;
        const limit = 20;
        getAllPosts(dispatch, kinhDo, viDo, skip, limit);
    }, [kinhDo, viDo]);
    const [giaTriHang, setgiaTriHang] = useState({
        tenSo: "Giá Trị Hàng",
        giaTri: "0",
        loaiSo: "vnd",
    });
    const [phiGiaoHang, setphiGiaoHang] = useState({
        tenSo: "Phí Giao Hàng",
        giaTri: "0",
        loaiSo: "vnd",
    });
    const [thuNguoiNhan, setthuNguoiNhan] = useState({
        tenSo: "Thu Người Nhận",
        giaTri: "0",
        loaiSo: "vnd",
    });

    const handleDHTimShipMoi = () => {
        if (
            tenNhan !== "Nhập họ và tên" &&
            sdtNhan !== "Nhập số điện thoại" &&
            tinh !== "" &&
            huyen !== "" &&
            +phiGiaoHang?.giaTri > 0
        ) {
            const newDonHang = {
                tenShop: tenGui,
                sdtShop: sdtGui,
                donHang: "",
                idShop: user?._id,
                sdtCtv: "",
                sdtKhachHang: "",

                sdtOrder: "",
                sdtXuLyDon: "",
                sdtGiaoHang: "",
                sdtThuTien: "",

                noiNhan: "Ship Tận Nơi",
                soBan: "",
              

                thonXomMua: thonXom,
                xaMua: xa,
                huyenMua: huyen,
                tinhMua: tinh,

                kinhDo: kinhDo,
                viDo: viDo,

                thonXomBan: thonGui,
                xaBan: xaGui,
                huyenBan: huyenGui,
                tinhBan: tinhGui,

                trangThaiDH: "ĐH Tìm Người Giao",

                ttThem: {
                    baoCao: {
                        doanhThu: +giaTriHang?.giaTri,
                        giaVon: 0,
                        phiNenTang: 0,
                        giamTru: 0,
                    },
                    ttCuaHang: {
                        idNganHang:
                            myDetail?.ttThemUser?.nganHang?.maSoNganHang,
                        nganHang: myDetail?.ttThemUser?.nganHang?.tenNganHang,
                        soTaiKhoan:
                            myDetail?.ttThemUser?.nganHang?.taiKhoanNganHang,
                        tenChuTk:
                            myDetail?.ttThemUser?.nganHang?.chuTaiKhoanNganhang,
                        gmail: myDetail?.ttThemUser?.gmail,
                    },
                    ttKhachHang: {
                        hoTenNguoiMua: tenNhan,
                        sdtNguoiMua: sdtNhan,
                        ghiChuNguoiMua: "",
                        maBaoMat: "",
                        nhomKhach: "",
                        gmail: "",
                    },
                    ttGiaoHang: {
                        phiGiaoHang: +phiGiaoHang?.giaTri,
                        thuNguoiNhan: +thuNguoiNhan?.giaTri,
                        tenNv: "",
                        idNv: "",
                        sdtNv: "",
                        tienCoc: "",
                        hoanHang: "",
                        ckNguoiGui: "",
                        gmail: "",
                    },
                },
            };
            console.log("newDonHang", newDonHang);
            registerDonHang(newDonHang, dispatch);

            const linkDonHang =
                "https://fabysa.com/giao-hang/Nhận%20Giao%20Hàng/ĐH%20Tìm%20Người%20Giao";
            const newEmail = {
                email: [
                    ...allPosts?.map((item) => item && item?.ttThemUser?.gmail),
                    ...["hungtranvan.0976993229@gmail.com"],
                ],
                subject: myDetail?.hoTen,
                html: `<a style='text-decoration: none' href=${linkDonHang}>Đơn hàng cần giao mới. Xem thêm . . . !</a>`,
            };
            guiMail(newEmail);
            console.log("newEmail", newEmail);
            window.history.back();
        } else {
            alert("Thiếu thông tin đơn hàng!");
        }
    };
    return (
        <div className="view">
            {chieuRongMH <= "1023" && (
                <div className="mobile">
                    <div className="ThemDonHangGiao-ConTaiNer">
                        <div className="quayLai-tieuDe">
                            <div
                                onClick={() => window.history.back()}
                                className="quayLai"
                            >
                                <i className="fa fa-angle-double-left"></i>
                                Quay Lại
                            </div>
                            <div className="tieuDe">Tạo Đơn Hàng</div>
                        </div>
                        <div className="noiGui">
                            <div className="tieuDe">
                                <i
                                    style={{ color: "red" }}
                                    className="fas fa-arrow-alt-circle-up"
                                ></i>
                                &nbsp;Người Gửi
                            </div>
                            <div className="thongTin">
                                Toạ Độ : {Math.round(kinhDo * 10) / 10}
                                &nbsp;-&nbsp;
                                {Math.round(viDo * 10) / 10}
                            </div>
                            <div className="thongTin">
                                Họ & Tên :&nbsp;{tenGui}
                            </div>
                            <div className="thongTin">
                                Số Điện Thoại :&nbsp;
                                <i
                                    className="fa fa-phone-square"
                                    style={{ color: "#04aa6d" }}
                                ></i>
                                &nbsp;
                                <DinhDangSo
                                    soDinhDang={sdtGui}
                                    donViSo="sdt"
                                    // tt,sdt,vnd,f
                                />
                            </div>
                            <div className="thongTin">
                                Địa Chỉ :&nbsp;{thonGui},&nbsp;{xaGui},&nbsp;
                                {huyenGui}
                                ,&nbsp;{tinhGui}
                            </div>
                            <div className="thongTin">
                                Ngân Hàng :&nbsp;Tên ngân hàng&nbsp;-&nbsp;
                                {myDetail?.ttThemUser?.nganHang?.tenNganHang},
                                Chủ tài khoản&nbsp;-&nbsp;
                                {
                                    myDetail?.ttThemUser?.nganHang
                                        ?.chuTaiKhoanNganhang
                                }
                                , Số tài khoản&nbsp;-&nbsp;
                                <DinhDangSo
                                    soDinhDang={
                                        myDetail?.ttThemUser?.nganHang
                                            ?.taiKhoanNganHang
                                    }
                                    donViSo="sdt"
                                    // tt,sdt,vnd,f
                                />
                            </div>
                        </div>
                        <div className="noiNhan">
                            <div className="tieuDeNoiGui">
                                <i
                                    style={{ color: "red" }}
                                    className="fas fa-arrow-alt-circle-down"
                                ></i>
                                &nbsp;Người Nhận
                            </div>
                            <div className="hoTen-thongTin">
                                <div className="hoTen">Họ & Tên</div>
                                <input
                                    className="thongTin"
                                    placeholder={tenNhan}
                                    onChange={(e) => settenNhan(e.target.value)}
                                />
                            </div>
                            <div className="hoTen-thongTin">
                                <div className="hoTen">Số Điện Thoại</div>
                                <input
                                    className="thongTin"
                                    placeholder={sdtNhan}
                                    onChange={(e) => setsdtNhan(e.target.value)}
                                />
                            </div>
                            <div className="diaChi">
                                <ChonDiaChi
                                    thonXom={thonXom}
                                    setthonXom={setthonXom}
                                    xa={xa}
                                    setxa={setxa}
                                    huyen={huyen}
                                    sethuyen={sethuyen}
                                    tinh={tinh}
                                    settinh={settinh}
                                />
                            </div>
                        </div>
                        <div className="giaoHang">
                            <div className="tieuDe">
                                <i
                                    style={{ color: "red" }}
                                    className="fas fa-shipping-fast"
                                ></i>
                                &nbsp;Giao Hàng
                            </div>
                            <div className="giaTriHang">
                                Giá Trị Hàng :
                                <BanPhimSo
                                    soMoi={giaTriHang}
                                    setsoMoi={setgiaTriHang}
                                />
                            </div>
                            <div className="giaTriHang">
                                Phí Giao Hàng :
                                <BanPhimSo
                                    soMoi={phiGiaoHang}
                                    setsoMoi={setphiGiaoHang}
                                />
                            </div>
                            <div className="giaTriHang">
                                Thu Người Nhận :
                                <BanPhimSo
                                    soMoi={thuNguoiNhan}
                                    setsoMoi={setthuNguoiNhan}
                                />
                            </div>
                        </div>

                        <div
                            className="taoDonHang"
                            onClick={() => handleDHTimShipMoi()}
                        >
                            <i className="fa fa-plus"></i>&nbsp;ĐH Tìm Người
                            Giao
                        </div>
                        <div className="luuY-ConTainer">
                            <div className="tieuDe">
                                <i
                                    style={{ color: "red" }}
                                    className="fas fa-exclamation-triangle"
                                ></i>
                                &nbsp;Lưu ý
                            </div>
                            <div className="noiDung">
                                - Phí giao hàng người gửi tự thống nhất với
                                người nhận để đặt cho hợp lý. <br />- Người gửi
                                thanh toán phí giao hàng cho người giao hàng
                                ngay lúc người giao hàng đến lấy hàng đi giao.
                                <br />- Người giao hàng chuyển khoản tiền thu
                                người nhận cho người gửi sau khi giao hàng thành
                                công.
                                <br />- Fabysa đền bù bằng giá trị hàng nếu hư
                                hỏng hoặc mất mát.
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {chieuRongMH > "1023" && (
                <div className="pc">
                    <div className="ThemDonHangGiao-ConTaiNer">
                        ThemDonHangGiao
                    </div>
                </div>
            )}
        </div>
    );
};
export default ThemDonHangGiao;
