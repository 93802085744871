import "./TrangChuShop.scss";
import HeaderShop from "./HeaderShop";
import NganHangShop from "./NganHangShop";
import GioiThieuShop from "./GioiThieuShop";
import ThemeShop from "./ThemeShop";
import ThemeBlog from "./ThemeBlog";
import MenuShop from "./MenuShop";

import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getttShop, getSanPham, getPost } from "../redux/apiRequest";
import { useEffect } from "react";
import GioHang from "./GioHang";
import ChiTietSanPham2 from "./ChiTietSanPham2";

const TrangChuShop = () => {
    const chieuRongMH = window.innerWidth;
    const { idShop, tenVietTat } = useParams();
    console.log("idShop", idShop);
    const user = useSelector((state) => state.auth.login.currentUser);
    const ttShop = useSelector((state) => state.ttShop.ttShop.ttShop?.shop);
    console.log("ttShop", ttShop);
    const allSanPham2 = useSelector(
        (state) => state.sanPham.sanPham.allsanPham?.allSanpham
    );
    const loadingSP = useSelector((state) => state.sanPham.sanPham.isFetching);
    const VND = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND",
    });
    const [Tongsoluong, setTongsoluong] = useState(0);
    const [Tongtien, setTongtien] = useState(0);
    const [slMatHang, setslMatHang] = useState(0);
    const [loading, setloading] = useState(0);
    const [thongTinSp, setthongTinSp] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [nhomSP, setnhomSP] = useState("Xin kính chào quý khách");
    const [skip, setskip] = useState(0);
    const [sort, setsort] = useState(1);
    const [soBan, setsoBan] = useState("Chọn Số Bàn");
    const [maBaoMat, setmaBaoMat] = useState();
    const [allSanPham, setallSanPham] = useState([]);
    console.log("allSanPham", allSanPham);

    const [TTMenu, setTTMenu] = useState("an");
    const nhomSanPham = ttShop?.ttShopThem?.menuShop;

    const giaoDien = tenVietTat.slice(0, 3);
    console.log("giaoDien", giaoDien);

    useEffect(() => {
        if (user && user.length !== 0) {
            getPost(user?._id, dispatch);
        }
    }, []);
    useEffect(() => {
        getttShop(idShop, dispatch);
    }, [idShop]);
    // get san pham
    useEffect(() => {
        const handleScroll = (e) => {
            const scrollHeight = e.target.documentElement.scrollHeight;
            const currentHeight =
                e.target.documentElement.scrollTop + window.innerHeight + 100;
            if (currentHeight >= scrollHeight) {
                setskip(skip + 2);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [allSanPham]);

    useEffect(() => {
        const limit = 2;
        getSanPham(idShop, nhomSP, sort, skip, limit, dispatch);
    }, [nhomSP, skip, sort]);
    useEffect(() => {
        if (skip > 0) {
            setallSanPham([...allSanPham, ...allSanPham2]);
        } else {
            setallSanPham(allSanPham2);
        }
    }, [allSanPham2]);
    // get san pham
    useEffect(() => {
        if (ttShop) {
            setnhomSP(
                ttShop?.ttShopThem?.menuShop[0] || "Xin kính chào quý khách"
            );
        }
    }, [ttShop]);
    // Gio hang
    const [cart, setcart] = useState([]);
    const [cartDemo, setcartDemo] = useState([]);
    // Gio hang

    // Chi Tiet San Pham
    const handleChiTietSP = (item) => {
        setloading(4);
        setthongTinSp(item);
    };
    // Chi Tiet San Pham
    //Gio Hang
    const handleDaThemGioHang = (item) => {
        if (cartDemo && cartDemo.length > 0) {
            const CartDemo2 = cartDemo.filter(
                (item2) => item2._id !== item._id
            );
            setcartDemo(CartDemo2);
        }

        if (cart && cart.length > 0) {
            const xetCart = cart.filter((item2) => item2._id !== item._id);
            setcart(xetCart);
        }
    };
    const handleThemGioHang = (item) => {
        // const allDacDiemSP = item?.allDacDiemSP;
        // const allDacDiemSP2 = allDacDiemSP?.map(
        //     (item) =>
        //         item && {
        //             ...item,
        //             slMua: 0,
        //         }
        // );
        setcartDemo([
            ...cartDemo,
            item,
            // {
            //     _id: item?._id,
            //     tenSanPham: item?.TenSanPham,
            //     allDacDiemSP: allDacDiemSP2,
            //     allAnhSp: item?.allAnhSp,
            //     thongTinSanPham: item?.thongTinSanPham,
            // },
        ]);
    };
    // Gio Hang

    // Sap xep
    const handleSapXep = () => {
        setallSanPham([]);
        setsort(-sort);
        setskip(0);
    };

    // Sap xep
    const handleChonNhomSanPham = (item) => {
        setallSanPham([]);
        setnhomSP(item);
        setskip(0);
        setTTMenu("an");
    };
    useEffect(() => {
        setslMatHang(cartDemo?.length);
    });
    return (
        <div className="view">
            {chieuRongMH <= "1023" && (
                <div className="mobile">
                    <div className="TrangChuShop-ConTaiNer">
                        {loading === 0 && (
                            <div>
                                <HeaderShop
                                    giaoDien={giaoDien}
                                    setTongtien={setTongtien}
                                    setTongsoluong={setTongsoluong}
                                    Tongtien={Tongtien}
                                    Tongsoluong={Tongsoluong}
                                    loading={loading}
                                    setloading={setloading}
                                    setTTMenu={setTTMenu}
                                    sort={sort}
                                    handleSapXep={handleSapXep}
                                    loadingSP={loadingSP}
                                />
                                {giaoDien === 'Fa1' && (
                                    <ThemeShop
                                        allSanPham={allSanPham}
                                        nhomSanPham={nhomSanPham}
                                        nhomSP={nhomSP}
                                        handleChiTietSP={handleChiTietSP}
                                        handleDaThemGioHang={
                                            handleDaThemGioHang
                                        }
                                        handleThemGioHang={handleThemGioHang}
                                        VND={VND}
                                        cartDemo={cartDemo}
                                        setTTMenu={setTTMenu}
                                        loadingSP={loadingSP}
                                    />
                                )}
                                {giaoDien === 'Fa2' && (
                                    <ThemeBlog
                                        allSanPham={allSanPham}
                                        nhomSanPham={nhomSanPham}
                                        nhomSP={nhomSP}
                                        handleChiTietSP={handleChiTietSP}
                                        handleDaThemGioHang={
                                            handleDaThemGioHang
                                        }
                                        handleThemGioHang={handleThemGioHang}
                                        VND={VND}
                                        cartDemo={cartDemo}
                                        setTTMenu={setTTMenu}
                                    />
                                )}
                                 {giaoDien === 'Fa3' && (
                                    <ThemeBlog
                                        allSanPham={allSanPham}
                                        nhomSanPham={nhomSanPham}
                                        nhomSP={nhomSP}
                                        handleChiTietSP={handleChiTietSP}
                                        handleDaThemGioHang={
                                            handleDaThemGioHang
                                        }
                                        handleThemGioHang={handleThemGioHang}
                                        VND={VND}
                                        cartDemo={cartDemo}
                                        setTTMenu={setTTMenu}
                                    />
                                )}
                                <div className="trong-container">
                                    <div className="trong1">
                                        <span className=""></span>
                                        <div
                                            style={
                                                loadingSP === true
                                                    ? {
                                                          backgroundColor:
                                                              "#e5e5e5",
                                                      }
                                                    : {
                                                          backgroundColor:
                                                              "#fff",
                                                      }
                                            }
                                            className="line"
                                        >
                                            <div className="inner"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="GioHang-container">
                            {loading === 2 && (
                                <div
                                    onClick={() => setloading(0)}
                                    className="gioHang-soLuong-thanhTien"
                                >
                                    <div className="gioHang">
                                        <i className="fas fa-cart-arrow-down"></i>
                                    </div>

                                    <div className="soLuong-thanhTien">
                                        <div className="soLuong">
                                            {Tongsoluong}
                                            <span style={{ color: "red" }}>
                                                /{slMatHang}
                                            </span>
                                        </div>
                                        <div className="thanhTien">
                                            {VND.format(Tongtien)}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {(loading === 0 || loading === 4) && (
                                <div
                                    onClick={() => setloading(2)}
                                    className="gioHang-soLuong-thanhTien"
                                >
                                    <div className="gioHang">
                                        <i className="fas fa-cart-arrow-down"></i>
                                    </div>

                                    <div className="soLuong-thanhTien">
                                        <div className="soLuong">
                                            {Tongsoluong}{" "}
                                            <span style={{ color: "red" }}>
                                                /{slMatHang}
                                            </span>
                                        </div>
                                        <div className="thanhTien">
                                            {VND.format(Tongtien)}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {loading === 2 && (
                            <GioHang
                                handleDaThemGioHang={handleDaThemGioHang}
                                cart={cart}
                                setcart={setcart}
                                loading={loading}
                                setloading={setloading}
                                setTongtien={setTongtien}
                                setTongsoluong={setTongsoluong}
                                Tongtien={Tongtien}
                                Tongsoluong={Tongsoluong}
                                setcartDemo={setcartDemo}
                                cartDemo={cartDemo}
                                soBan={soBan}
                                setsoBan={setsoBan}
                                maBaoMat={maBaoMat}
                                setmaBaoMat={setmaBaoMat}
                            />
                        )}
                        {loading === 4 && (
                            <ChiTietSanPham2
                                handleDaThemGioHang={handleDaThemGioHang}
                                handleThemGioHang={handleThemGioHang}
                                cart={cart}
                                setcart={setcart}
                                setcartDemo={setcartDemo}
                                cartDemo={cartDemo}
                                thongTinSp={thongTinSp}
                                loading={loading}
                                setloading={setloading}
                                idShop={idShop}
                            />
                        )}
                        {loading === 7 && (
                            <NganHangShop
                                setloading={setloading}
                                loading={loading}
                                ttShop={ttShop}
                            />
                        )}
                        {loading === 8 && (
                            <GioiThieuShop
                                setloading={setloading}
                                loading={loading}
                                ttShop={ttShop}
                            />
                        )}
                        {TTMenu === "hien" && (
                            <MenuShop
                                nhomSanPham={nhomSanPham}
                                setnhomSP={setnhomSP}
                                nhomSP={nhomSP}
                                handleChonNhomSanPham={handleChonNhomSanPham}
                                setTTMenu={setTTMenu}
                            />
                        )}
                    </div>
                </div>
            )}
            {chieuRongMH > "1023" && (
                <div className="pc">
                    <div className="TrangChuShop-ConTaiNer">TrangChuShop</div>
                </div>
            )}
        </div>
    );
};
export default TrangChuShop;
