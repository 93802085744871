import "./NhanGiaoHang.scss";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import {
    getDonHangShip,
    getDonHang,
    updateDonHang,
    updatePost,
    registerTaiKhoan,
    getTaiKhoan,
    getOneDonHang,
    deleteDonHang,
    guiMail,
} from "../redux/apiRequest";
import DinhDangSo from "../component/DinhDangSo";
const NhanGiaoHang = () => {
    const { idDonHang, tenVietTat, idShop, idCtv, tenCtv, sdtCtv } =
        useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const myDetail = useSelector((state) => state.post.post?.myDetail);
    const [phiNenTang, setphiNenTang] = useState(1000);
    const donHang = useSelector(
        (state) => state.donHang.donHang?.donHang?.donHang
    );
    const message = useSelector(
        (state) => state.donHang.donHang?.donHang?.message
    );
    useEffect(() => {
        const idShop = "";
        const soBan = "";
        getOneDonHang(idShop, idDonHang, soBan, dispatch);
    }, [idDonHang]);
    const handleQuayLai = () => {
        navigate(`/giao-hang/Nhận%20Giao%20Hàng/${donHang?.trangThaiDH}`);
    };

    const handleNhanShip = () => {
        const newDonHang = {
            trangThaiDH: "ĐH Người Giao Đã Nhận",
            sdtGiaoHang: myDetail?.soDienThoai,
            ttThem: {
                ...donHang?.ttThem,
                ...{
                    ttGiaoHang: {
                        ...donHang?.ttThem?.ttGiaoHang,

                        tenNv: myDetail?.hoTen,
                        idNv: myDetail?.user,
                        sdtNv: myDetail?.soDienThoai,
                        gmail: myDetail?.ttThemUser?.gmail,
                    },
                },
            },
        };
        console.log("newDonHang", newDonHang);
        updateDonHang(newDonHang, donHang?._id, dispatch);
    };
    const handleCocDonHang = () => {
        const newDonHang = {
            ttThem: {
                ...donHang?.ttThem,
                ...{
                    ttGiaoHang: {
                        ...donHang?.ttThem?.ttGiaoHang,
                        tienCoc: "Đã Cọc",
                    },
                },
            },
        };
        console.log("newDonHang", newDonHang);
        updateDonHang(newDonHang, donHang?._id, dispatch);
        const newPost = {
            fabysaXanh:
                +myDetail?.fabysaXanh -
                +donHang?.ttThem?.baoCao?.doanhThu +
                +donHang?.ttThem?.baoCao?.giamTru,
        };
        console.log("newPost", newPost);
        updatePost(newPost, myDetail?._id, dispatch);
        const newTaiKhoan = {
            GDVao: "",
            GDRa:
                +donHang?.ttThem?.baoCao?.doanhThu -
                +donHang?.ttThem?.baoCao?.giamTru,
            noiDungCK: "Cọc Đơn Hàng",
            xacNhanChuyenTien: "Thành Công",
            thongTinThem: {
                tenChuTk: myDetail?.hoTen,
                sdtChuTk: myDetail?.soDienThoai,
            },
            idChuTaiKhoan: myDetail?.user,
        };
        console.log("newTaiKhoan", newTaiKhoan);
        registerTaiKhoan(newTaiKhoan, dispatch);
    };
    const handleTimShipKhac = () => {
        const cauHoi = window.confirm("Nhận lại cọc và chịu phạt 10.000 F ?");
        if (cauHoi) {
            const newDonHang = {
                trangThaiDH: "ĐH Tìm Người Giao",
                sdtGiaoHang: "",
                ttThem: {
                    ...donHang?.ttThem,
                    ...{
                        ttGiaoHang: {
                            ...donHang?.ttThem?.ttGiaoHang,

                            tenNv: "",
                            idNv: "",
                            sdtNv: "",
                            tienCoc: "",
                            gmail: "",
                        },
                    },
                },
            };
            console.log("newDonHang", newDonHang);
            updateDonHang(newDonHang, donHang?._id, dispatch);
            const newPost = {
                fabysaXanh:
                    +myDetail?.fabysaXanh +
                    +donHang?.ttThem?.baoCao?.doanhThu -
                    +donHang?.ttThem?.baoCao?.giamTru -
                    10000,
            };
            console.log("newPost", newPost);
            updatePost(newPost, myDetail?._id, dispatch);
            const newTaiKhoan = {
                GDVao:
                    +donHang?.ttThem?.baoCao?.doanhThu -
                    +donHang?.ttThem?.baoCao?.giamTru -
                    10000,
                GDRa: "",
                noiDungCK: "Hoàn Cọc & Phạt",
                xacNhanChuyenTien: "Thành Công",
                thongTinThem: {
                    tenChuTk: myDetail?.hoTen,
                    sdtChuTk: myDetail?.soDienThoai,
                },
                idChuTaiKhoan: myDetail?.user,
            };
            console.log("newTaiKhoan", newTaiKhoan);
            registerTaiKhoan(newTaiKhoan, dispatch);
        }
    };
    const handleKhachDaNhan = () => {
        const cauHoi = window.confirm("Khách đã nhận ?");
        if (cauHoi) {
            const newDonHang = {
                trangThaiDH: "ĐH Giao Khách Đã Nhận",
            };

            updateDonHang(newDonHang, donHang?._id, dispatch);
            const newPost = {
                fabysaXanh: +myDetail?.fabysaXanh - +phiNenTang,
            };
            console.log("newPost", newPost);
            updatePost(newPost, myDetail?._id, dispatch);
            const newTaiKhoan = {
                GDVao: "",
                GDRa: +phiNenTang,
                noiDungCK: "Phí Nền Tảng",
                xacNhanChuyenTien: "Thành Công",
                thongTinThem: {
                    tenChuTk: myDetail?.hoTen,
                    sdtChuTk: myDetail?.soDienThoai,
                },
                idChuTaiKhoan: myDetail?.user,
            };
            console.log("newTaiKhoan", newTaiKhoan);
            registerTaiKhoan(newTaiKhoan, dispatch);
        }
    };
    const handleDaChuyenKhoan = () => {
        const newDonHang = {
            ttThem: {
                ...donHang?.ttThem,
                ...{
                    ttGiaoHang: {
                        ...donHang?.ttThem?.ttGiaoHang,
                        ckNguoiGui: "Chờ Xác Nhận",
                    },
                },
            },
        };
        updateDonHang(newDonHang, donHang?._id, dispatch);

        const linkDonHang = `https://fabysa.com/tim-ship/${donHang?._id}/a/a/a/a/a/a`;
        const newEmail = {
            email: donHang?.ttThem?.ttCuaHang?.gmail,
            subject: donHang?.tenShop,
            html: `<a style='text-decoration: none' href=${linkDonHang}>Đơn hàng chuyển khoản mới. . . !</a>`,
        };
        guiMail(newEmail);
    };
   
    const handleKhachKhongNhan = () => {
        const cauHoi = window.confirm("Khách không nhận ?");
        if (cauHoi) {
            const newDonHang = {
                trangThaiDH: "ĐH Giao Khách Không Nhận",
            };
            updateDonHang(newDonHang, donHang?._id, dispatch);
        }
    };
    const handleDaHoanHang = () => {
        const newDonHang = {
            ttThem: {
                ...donHang?.ttThem,
                ...{
                    ttGiaoHang: {
                        ...donHang?.ttThem?.ttGiaoHang,
                        hoanHang: "Chờ Xác Nhận",
                    },
                },
            },
        };
        console.log("newDonHang", newDonHang);
        updateDonHang(newDonHang, donHang?._id, dispatch);
    };
    const handleNhanLaiCoc = () => {
        const newDonHang = {
            ttThem: {
                ...donHang?.ttThem,
                ...{
                    ttGiaoHang: {
                        ...donHang?.ttThem?.ttGiaoHang,
                        tienCoc: "Đã Nhận Lại Cọc",
                    },
                },
            },
        };
        console.log("newDonHang", newDonHang);
        updateDonHang(newDonHang, donHang?._id, dispatch);
        const newPost = {
            fabysaXanh:
                +myDetail?.fabysaXanh +
                +donHang?.ttThem?.baoCao?.doanhThu -
                +donHang?.ttThem?.baoCao?.giamTru,
        };
        console.log("newPost", newPost);
        updatePost(newPost, myDetail?._id, dispatch);

        const newTaiKhoan = {
            GDVao:
                +donHang?.ttThem?.baoCao?.doanhThu -
                +donHang?.ttThem?.baoCao?.giamTru,
            GDRa: "",
            noiDungCK: "Nhận Lại Cọc",
            xacNhanChuyenTien: "Thành Công",
            thongTinThem: {
                tenChuTk: myDetail?.hoTen,
                sdtChuTk: myDetail?.soDienThoai,
            },
            idChuTaiKhoan: myDetail?.user,
        };
        console.log("newTaiKhoan", newTaiKhoan);
        registerTaiKhoan(newTaiKhoan, dispatch);
    };
    const handlePrint = () => {
        window.print();
    };
    // quy trinh ship hang: đăng tìm ship => shiper nhận ship(fabysa giu tien coc) => shiper nhan hang => shiper giao hang =>
    // neu hoan thanh => ship chuyen tien ve cho shop => shop xác nhan da thanh toan
    // neu that bại => ship chuyen hang ve cho shop => shop xác nhan da nhan hàng
    // Shiper làm mất hàng Fabysa chịu trách nhiệm
    //  Viet QR
    const nganHang = donHang?.ttThem?.ttCuaHang?.nganHang;
    const BANK_ID = donHang?.ttThem?.ttCuaHang?.idNganHang;
    const ACCOUNT_NO = donHang?.ttThem?.ttCuaHang?.soTaiKhoan;
    const TEMPLATE = "print";
    const AMOUNT = +donHang?.ttThem?.ttGiaoHang?.thuNguoiNhan;
    const DESCRIPTION = `${donHang?._id}`;
    const ACCOUNT_NAME = donHang?.ttThem?.ttCuaHang?.tenChuTk;
    const qr = `https://img.vietqr.io/image/${BANK_ID}-${ACCOUNT_NO}-${TEMPLATE}.png?amount=${AMOUNT}&addInfo=${DESCRIPTION}&accountName=${ACCOUNT_NAME}`;
    // Viet QR
    return (
        <div className="view">
            <div className="mobile">
                <div className="chiTietDonHangNhan-ConTaiNer">
                    <div className="quayLai-tieuDe">
                        <div
                            onClick={() => handleQuayLai()}
                            className="quayLai"
                        >
                            <i className="fa fa-angle-double-left"></i>Quay Lại
                        </div>
                        <div className="tieuDe">Chi Tiết Đơn Hàng</div>
                        <div className="inHoaDon" onClick={() => handlePrint()}>
                            <i className="fa-solid fa-print"></i>
                        </div>
                    </div>
                    <div className="trangThaiDh-huyDon">
                        <div className="trangThaiDh">
                            <i
                                className="fas fa-shopping-bag"
                                style={{
                                    color: "red",
                                }}
                            ></i>
                            &#160;
                            {donHang?.trangThaiDH}
                        </div>
                        {donHang?.trangThaiDH === "ĐH Người Giao Đã Nhận" && (
                            <div
                                onClick={() => handleTimShipKhac()}
                                className="huyDon"
                            >
                                <i
                                    className="fas fa-sync-alt"
                                    style={{
                                        color: "red",
                                    }}
                                ></i>
                                &#160;Bỏ Giao
                            </div>
                        )}
                    </div>
                    <div className="chiTiet">
                        <div className="noiGui-thongTin">
                            <div className="tieuDe">
                                <i
                                    style={{ color: "red" }}
                                    className="fas fa-arrow-alt-circle-up"
                                ></i>
                                &nbsp;Người Gửi
                            </div>
                            <div className="noiDung">
                                Họ & Tên :&nbsp;{donHang?.tenShop}
                            </div>
                            <a
                                href={`tel:${donHang?.sdtShop}`}
                                className="noiDung"
                            >
                                Số Điện Thoại :&nbsp; &nbsp;
                                <i
                                    className="fa fa-phone-square"
                                    style={{ color: "#04aa6d" }}
                                ></i>
                                &nbsp;
                                <DinhDangSo
                                    soDinhDang={donHang?.sdtShop}
                                    donViSo="sdt"
                                    // tt,vnd,f
                                />
                            </a>
                            <div className="noiDung">
                                Địa Chỉ : &nbsp;{donHang?.thonXomBan}
                                ,&nbsp;
                                {donHang?.xaBan},&nbsp;
                                {donHang?.huyenBan},&nbsp;
                                {donHang?.tinhBan}
                            </div>
                        </div>
                        <div className="noiGui-thongTin">
                            <div className="tieuDe">
                                <i
                                    style={{ color: "red" }}
                                    className="fas fa-arrow-alt-circle-down"
                                ></i>
                                &nbsp;Người Nhận
                            </div>
                            <div className="noiDung">
                                Họ & Tên :&nbsp;
                                {donHang?.ttThem?.ttKhachHang?.hoTenNguoiMua}
                            </div>

                            <a
                                className="noiDung"
                                href={`tel:${donHang?.ttThem?.ttKhachHang?.sdtNguoiMua}`}
                            >
                                Số Điện Thoại :&nbsp; &nbsp;
                                <i
                                    className="fa fa-phone-square"
                                    style={{ color: "#04aa6d" }}
                                ></i>
                                &nbsp;
                                <DinhDangSo
                                    soDinhDang={
                                        donHang?.ttThem?.ttKhachHang
                                            ?.sdtNguoiMua
                                    }
                                    donViSo="sdt"
                                    // tt,sdt,vnd,f
                                />
                            </a>
                            <div className="noiDung">
                                Địa Chỉ : &nbsp;{donHang?.thonXomMua}
                                ,&nbsp;
                                {donHang?.xaMua},&nbsp;
                                {donHang?.huyenMua},&nbsp;
                                {donHang?.tinhMua}
                            </div>
                        </div>
                        <div className="noiGui-thongTin">
                            <div className="tieuDe">
                                <i
                                    style={{ color: "red" }}
                                    className="fas fa-shipping-fast"
                                ></i>
                                &nbsp;Người Giao Hàng
                            </div>
                            {donHang?.trangThaiDH !== "ĐH Tìm Người Giao" && (
                                <div className="noiDung">
                                    Họ & Tên :&nbsp;
                                    {donHang?.ttThem?.ttGiaoHang?.tenNv}
                                </div>
                            )}
                            {donHang?.trangThaiDH !== "ĐH Tìm Người Giao" && (
                                <a
                                    className="noiDung"
                                    href={`tel:${donHang?.ttThem?.ttGiaoHang?.phiShip?.sdtNv}`}
                                >
                                    Số Điện Thoại :&nbsp; &nbsp;
                                    <i
                                        className="fa fa-phone-square"
                                        style={{ color: "#04aa6d" }}
                                    ></i>
                                    &nbsp;
                                    <DinhDangSo
                                        soDinhDang={
                                            donHang?.ttThem?.ttGiaoHang?.sdtNv
                                        }
                                        donViSo="sdt"
                                        // tt,sdt,vnd,f
                                    />
                                </a>
                            )}

                            <div className="noiDung">
                                Giá Trị Hàng (Cọc) :&nbsp;
                                <DinhDangSo
                                    soDinhDang={
                                        donHang?.ttThem?.baoCao?.doanhThu -
                                        donHang?.ttThem?.baoCao?.giamTru
                                    }
                                    donViSo="vnd"
                                    // tt,vnd,f
                                />
                            </div>
                            <div className="noiDung">
                                Phí Giao Hàng :&nbsp;
                                <DinhDangSo
                                    soDinhDang={
                                        donHang?.ttThem?.ttGiaoHang?.phiGiaoHang
                                    }
                                    donViSo="vnd"
                                    // tt,sdt,vnd,f
                                />
                            </div>
                            <div className="noiDung">
                                Thu Người Nhận :&nbsp;
                                <DinhDangSo
                                    soDinhDang={
                                        +donHang?.ttThem?.ttGiaoHang
                                            ?.thuNguoiNhan
                                    }
                                    donViSo="vnd"
                                    // tt,vnd,f
                                />
                            </div>
                        </div>
                    </div>
                    {donHang?.trangThaiDH === "ĐH Giao Khách Đã Nhận" &&
                        donHang?.ttThem?.ttCuaHang && (
                            <div className="thanhToan-container">
                                <div className="tieuDe">
                                    <i
                                        style={{ color: "red" }}
                                        className="fa fa-qrcode"
                                    ></i>
                                    &nbsp;Chuyển Tiền Người Gửi
                                </div>
                                <div className="noiDung">
                                    Ngân Hàng : &nbsp;
                                    {donHang?.ttThem?.ttCuaHang?.nganHang}
                                </div>
                                <div className="noiDung">
                                    Số Tài Khoản : &nbsp;
                                    {donHang?.ttThem?.ttCuaHang?.soTaiKhoan}
                                </div>
                                <div className="noiDung">
                                    Chủ Tài Khoản : &nbsp;
                                    {donHang?.ttThem?.ttCuaHang?.tenChuTk}
                                </div>
                                <div className="noiDung">
                                    Số Tiền = Thu Người Nhận =&nbsp;
                                    <DinhDangSo
                                        soDinhDang={
                                            +donHang?.ttThem?.ttGiaoHang
                                                ?.thuNguoiNhan
                                        }
                                        donViSo="vnd"
                                        // tt,vnd,f
                                    />
                                </div>
                                <img className="qr" src={qr} />
                            </div>
                        )}
                    <div className="phimChucNang-container">
                        {donHang?.trangThaiDH === "ĐH Tìm Người Giao" && (
                            <div
                                className="phim1"
                                onClick={() => handleNhanShip()}
                            >
                                Nhận Giao Hàng
                            </div>
                        )}
                        {donHang?.trangThaiDH === "ĐH Trên Đường Giao" && (
                            <div className="phim2-phim3">
                                <div
                                    className="phim2"
                                    onClick={() => handleKhachKhongNhan()}
                                >
                                    Khách Không Nhận
                                </div>
                                <div
                                    className="phim3"
                                    onClick={() => handleKhachDaNhan()}
                                >
                                    Khách Đã Nhận
                                </div>
                            </div>
                        )}
                        {donHang?.trangThaiDH === "ĐH Giao Khách Đã Nhận" && (
                            <div className="phim4-phim5">
                                {donHang?.ttThem?.ttGiaoHang?.ckNguoiGui ===
                                    "" && (
                                    <div
                                        onClick={() => handleDaChuyenKhoan()}
                                        className="phimXanh"
                                    >
                                        <i
                                            // style={{ color: "red" }}
                                            className="fa fa-square"
                                        ></i>
                                        &nbsp;Đã Chuyển Tiền
                                    </div>
                                )}
                                {donHang?.ttThem?.ttGiaoHang?.ckNguoiGui ===
                                    "Chờ Xác Nhận" && (
                                    <div
                                        onClick={() =>
                                            alert(
                                                "Vui lòng chờ xác nhận chuyển khoản !"
                                            )
                                        }
                                        className="phimDo"
                                    >
                                        <i
                                            style={{ color: "gray" }}
                                            className="fa fa-check-square"
                                        ></i>
                                        &nbsp;Đã Chuyển Tiền
                                    </div>
                                )}
                                {donHang?.ttThem?.ttGiaoHang?.ckNguoiGui ===
                                    "Đã Nhận" && (
                                    <div
                                        onClick={() =>
                                            alert("Chuyển khoản thành công !")
                                        }
                                        className="phimDo"
                                    >
                                        <i
                                            // style={{ color: "red" }}
                                            className="fa fa-check-square"
                                        ></i>
                                        &nbsp;Đã Chuyển Tiền
                                    </div>
                                )}
                                {(donHang?.ttThem?.ttGiaoHang?.ckNguoiGui ===
                                    "" ||
                                    donHang?.ttThem?.ttGiaoHang?.ckNguoiGui ===
                                        "Chờ Xác Nhận") && (
                                    <div
                                        onClick={() =>
                                            alert(
                                                "Chuyển tiền cho người gửi để nhận lại cọc !"
                                            )
                                        }
                                        className="phimXam"
                                    >
                                        <i
                                            // style={{ color: "red" }}
                                            className="fa fa-square"
                                        ></i>
                                        &nbsp;Nhận Lại Cọc
                                    </div>
                                )}
                                {donHang?.ttThem?.ttGiaoHang?.ckNguoiGui ===
                                    "Đã Nhận" &&
                                    donHang?.ttThem?.ttGiaoHang?.tienCoc !==
                                        "Đã Nhận Lại Cọc" && (
                                        <div
                                            onClick={() => handleNhanLaiCoc()}
                                            className="phimXanh"
                                        >
                                            <i
                                                // style={{ color: "red" }}
                                                className="fa fa-square"
                                            ></i>
                                            &nbsp;Nhận Lại Cọc
                                        </div>
                                    )}
                                {donHang?.ttThem?.ttGiaoHang?.tienCoc ===
                                    "Đã Nhận Lại Cọc" && (
                                    <div
                                        onClick={() =>
                                            alert("Đơn hàng đã hoàn thành !")
                                        }
                                        className="phimDo"
                                    >
                                        <i
                                            // style={{ color: "red" }}
                                            className="fa fa-check-square"
                                        ></i>
                                        &nbsp;Nhận Lại Cọc
                                    </div>
                                )}
                            </div>
                        )}
                        {donHang?.trangThaiDH ===
                            "ĐH Giao Khách Không Nhận" && (
                            <div className="phim4-phim5">
                                {donHang?.ttThem?.ttGiaoHang?.hoanHang ===
                                    "" && (
                                    <div
                                        onClick={() => handleDaHoanHang()}
                                        className="phimXanh"
                                    >
                                        <i
                                            // style={{ color: "red" }}
                                            className="fa fa-square"
                                        ></i>
                                        &nbsp;Đã Hoàn Hàng
                                    </div>
                                )}
                                {donHang?.ttThem?.ttGiaoHang?.hoanHang ===
                                    "Chờ Xác Nhận" && (
                                    <div
                                        onClick={() =>
                                            alert(
                                                "Vui lòng chờ xác nhận hoàn hàng !"
                                            )
                                        }
                                        className="phimDo"
                                    >
                                        <i
                                            style={{ color: "gray" }}
                                            className="fa fa-check-square"
                                        ></i>
                                        &nbsp;Đã Hoàn Hàng
                                    </div>
                                )}
                                {donHang?.ttThem?.ttGiaoHang?.hoanHang ===
                                    "Đã Nhận" && (
                                    <div
                                        onClick={() =>
                                            alert("Hoàn hàng thành công !")
                                        }
                                        className="phimDo"
                                    >
                                        <i
                                            // style={{ color: "red" }}
                                            className="fa fa-check-square"
                                        ></i>
                                        &nbsp;Đã Hoàn Hàng
                                    </div>
                                )}
                                {(donHang?.ttThem?.ttGiaoHang?.hoanHang ===
                                    "" ||
                                    donHang?.ttThem?.ttGiaoHang?.hoanHang ===
                                        "Chờ Xác Nhận") && (
                                    <div
                                        onClick={() =>
                                            alert(
                                                "Hoàn hàng cho người gửi để nhận lại cọc !"
                                            )
                                        }
                                        className="phimXam"
                                    >
                                        <i
                                            // style={{ color: "red" }}
                                            className="fa fa-square"
                                        ></i>
                                        &nbsp;Nhận Lại Cọc
                                    </div>
                                )}
                                {donHang?.ttThem?.ttGiaoHang?.hoanHang ===
                                    "Đã Nhận" &&
                                    donHang?.ttThem?.ttGiaoHang?.tienCoc !==
                                        "Đã Nhận Lại Cọc" && (
                                        <div
                                            onClick={() => handleNhanLaiCoc()}
                                            className="phimXanh"
                                        >
                                            <i
                                                // style={{ color: "red" }}
                                                className="fa fa-square"
                                            ></i>
                                            &nbsp;Nhận Lại Cọc
                                        </div>
                                    )}
                                {donHang?.ttThem?.ttGiaoHang?.tienCoc ===
                                    "Đã Nhận Lại Cọc" && (
                                    <div
                                        onClick={() =>
                                            alert("Đơn hàng đã hoàn thành !")
                                        }
                                        className="phimDo"
                                    >
                                        <i
                                            // style={{ color: "red" }}
                                            className="fa fa-check-square"
                                        ></i>
                                        &nbsp;Nhận Lại Cọc
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    <div className="luuY-ConTainer">
                        {donHang?.trangThaiDH === "ĐH Tìm Người Giao" && (
                            <div className="luuY">
                                <div className="tieuDe">
                                    <i
                                        style={{ color: "red" }}
                                        className="fas fa-exclamation-triangle"
                                    ></i>
                                    &nbsp;Lưu ý
                                </div>
                                <div className="noiDung">
                                    - Fabysa là trung gian giữa người gửi, người
                                    nhận và người giao hàng. <br />- Fabysa giữ
                                    cọc của người giao hàng để đảm bảo hàng được
                                    giao đến nơi an toàn không hư hỏng và mất
                                    mát.
                                    <br />- Số tiền cọc bằng bằng giá trị hàng
                                    gửi đi.
                                    <br />- Người giao hàng nhận lại cọc sau khi
                                    người bán xác nhận đơn hàng hoàn thành.
                                    <br />- Phí nền tảng là&nbsp;
                                    <DinhDangSo
                                        soDinhDang={phiNenTang}
                                        donViSo="f"
                                        // tt,vnd,f
                                    />
                                    /Đơn hàng hoàn thành.
                                </div>
                            </div>
                        )}
                        {donHang?.trangThaiDH === "ĐH Người Giao Đã Nhận" && (
                            <div className="luuY">
                                <div className="tieuDe">
                                    <i
                                        style={{ color: "red" }}
                                        className="fas fa-exclamation-triangle"
                                    ></i>
                                    &nbsp;Lưu ý
                                </div>
                                <div className="noiDung">
                                    - Người giao hàng thu phí giao hàng từ người
                                    gửi ngay lúc lấy hàng đi giao. <br />- Người
                                    giao hàng chuyển khoản tiền thu người nhận
                                    cho người gửi sau khi giao hàng thành công.
                                </div>
                            </div>
                        )}
                        {donHang?.trangThaiDH === "ĐH Giao Khách Đã Nhận" && (
                            <div className="luuY">
                                <div className="tieuDe">
                                    <i
                                        style={{ color: "red" }}
                                        className="fas fa-exclamation-triangle"
                                    ></i>
                                    &nbsp;Lưu ý
                                </div>
                                <div className="noiDung">
                                    - Sau khi giao hàng hoàn thành, người giao
                                    hàng chuyển khoản tiền thu người nhận cho
                                    người gửi theo thông tin chuyển khoản bên
                                    trên. <br />- Người gửi xác nhận đã nhận đủ
                                    tiền, người giao hàng được nhận lại tiền cọc
                                    của đơn hàng.
                                </div>
                            </div>
                        )}
                    </div>
                    {message === "Đã có người nhận!" && (
                        <div className="daCoNguoiNhan">
                            <div className="thongBao">
                                <div className="noiDung">
                                    Đã có người nhận đơn trước !
                                </div>
                                <div
                                    onClick={() => handleQuayLai()}
                                    className="quayLai"
                                >
                                    Quay lại
                                </div>
                            </div>
                        </div>
                    )}
                    {donHang?.sdtGiaoHang === myDetail?.soDienThoai &&
                        donHang?.ttThem?.ttGiaoHang?.tienCoc === "" && (
                            <div className="daCoNguoiNhan">
                                <div className="thongBao">
                                    <div className="noiDung">
                                        Nhận giao đơn hàng thành công !
                                    </div>
                                    <div
                                        onClick={() => handleCocDonHang()}
                                        className="quayLai"
                                    >
                                        Đồng ý
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>
            <div className="pc">
                <div className="NhanGiaoHang-ConTaiNer">NhanGiaoHang</div>
            </div>
        </div>
    );
};
export default NhanGiaoHang;
