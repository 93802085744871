import "./UpdateMyDetail.scss";
import { useNavigate, useParams } from "react-router-dom";
import { resizeAnh } from "../component/UploadAnh";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import anhHaiHuoc from "../assets/images/anhHaiHuoc.jpg";
import Loading from "../GiaoDienChung/Loading";
import {
    updatePost,
    getPost,
    registerPost,
    getTaiKhoan,
    registerTaiKhoan,
} from "../redux/apiRequest";
import DinhDangSo from "../component/DinhDangSo";
import BanPhimSo from "../GiaoDienChung/BanPhimSo2";
import ChonDiaChi from "../component/ChonDiaChi";
const UpdateMyDetail = () => {
    const { thongTinSua } = useParams();
    const user = useSelector((state) => state.auth.login?.currentUser);
    const myDetail = useSelector((state) => state.post.post?.myDetail);
    const isFetching = useSelector((state) => state.post.post?.isFetching);
    // const taiKhoan = useSelector(
    //     (state) => state?.taiKhoan?.taiKhoan?.taiKhoan?.taiKhoan
    // );

    // const allTaiKhoan2 = useSelector(
    //     (state) => state?.taiKhoan?.taiKhoan?.allTaiKhoan?.allTaiKhoan
    // );
    // const [allTaiKhoan, setallTaiKhoan] = useState(allTaiKhoan2);
    // console.log("allTaiKhoan2", allTaiKhoan2);
    // useEffect(() => {
    //     setallTaiKhoan(allTaiKhoan2);
    // }, [allTaiKhoan2]);
    // const [dateMax, setdateMax] = useState(0);
    // const [dateMin, setdateMin] = useState(1);
    const dispatch = useDispatch();


    const handleToaDo = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setkinhDo(position.coords.latitude);
                setviDo(position.coords.longitude);
            });
        }
    };
    // useEffect(() => {
    //     if (user) {
    //         const GDVao = 0;
    //         const GDRa = "";
    //         getTaiKhoan(
    //             user?._id,
    //             dateMax,
    //             dateMin,
    //             1,
    //             GDVao,
    //             GDRa,
    //             0,
    //             2,
    //             dispatch
    //         );
    //     }
    // }, [user]);

    // Thong Tin Chi Tiet
    const [avatar, setavatar] = useState(anhHaiHuoc);
    const [fabysaXanh, setfabysaXanh] = useState({
        tenSo: "Fabysa Xanh",
        giaTri: "0",
        loaiSo: "vnd",
    });
    const [hoTen, sethoTen] = useState("Đào Chưa Chín");
    const [sdt, setsdt] = useState("0987666888");
    const [gioiTinh, setgioiTinh] = useState("Nam");

    const [ngaySinh, setngaySinh] = useState("9");
    const [thangSinh, setthangSinh] = useState("9");
    const [namSinh, setnamSinh] = useState("1999");

    const [thonXom, setthonXom] = useState("Thôn Lá Đào");
    const [xa, setxa] = useState("Xã Cành Đào");
    const [huyen, sethuyen] = useState("Huyện Thân Đào");
    const [tinh, settinh] = useState("Tỉnh Gốc Đào");
    const [kinhDo, setkinhDo] = useState(0);
    const [viDo, setviDo] = useState(0);

    const [gmail, setgmail] = useState("Gmail");
    const [ttGiaoHang, setttGiaoHang] = useState("");

    const [vaiTro, setvaiTro] = useState("Người Dùng");

    const [followShop, setfollowShop] = useState([]);
    const [followBB, setfollowBB] = useState([]);

    const [tenNganHang, settenNganHang] = useState("MBBank");
    const [maSoNganHang, setmaSoNganHang] = useState("970422");
    const [taiKhoanNganHang, settaiKhoanNganHang] = useState("0931969456666");
    const [chuTaiKhoanNganhang, setchuTaiKhoanNganhang] =
        useState("Tài Khoản Demo");

    useEffect(() => {
        if (myDetail) {
            setavatar(myDetail?.avatar || anhHaiHuoc);
            sethoTen(myDetail?.hoTen || "Đào Chưa Chín");
            setsdt(myDetail?.sdt || "0987666888");
            setgioiTinh(myDetail?.gioiTinh || "Nam");

            setngaySinh(myDetail?.ngaySinh || "9");
            setthangSinh(myDetail?.thangSinh || "9");
            setnamSinh(myDetail?.namSinh || "1999");

            setthonXom(myDetail?.thonXom || "Thôn Lá Đào");
            setxa(myDetail?.xa || "Xã Cành Đào");
            sethuyen(myDetail?.huyen || "Huyện Thân Đào");
            settinh(myDetail?.tinh || "Tỉnh Gốc Đào");
            setkinhDo(myDetail?.kinhDo || 0);
            setviDo(myDetail?.viDo || 0);

            setfabysaXanh(
                {
                    tenSo: "Fabysa Xanh",
                    giaTri: +myDetail?.fabysaXanh,
                    loaiSo: "vnd",
                } || {
                    tenSo: "Fabysa Xanh",
                    giaTri: "0",
                    loaiSo: "vnd",
                }
            );

            setvaiTro(myDetail?.vaiTro || "Người Dùng");
            setttGiaoHang(myDetail?.ttGiaoHang || "");

            setfollowShop(myDetail?.followShop || []);
            setfollowBB(myDetail?.followBB || []);

            setgmail(myDetail?.ttThem?.gmail || "Gmail");
            settenNganHang(myDetail?.ttThem?.nganHang?.tenNganHang || "MBBank");
            setmaSoNganHang(
                myDetail?.ttThem?.nganHang?.maSoNganHang || "970422"
            );
            settaiKhoanNganHang(
                myDetail?.ttThem?.nganHang?.taiKhoanNganHang || "0931969456666"
            );
            setchuTaiKhoanNganhang(
                myDetail?.ttThem?.nganHang?.chuTaiKhoanNganhang ||
                    "Tài Khoản Demo"
            );
        }
    }, []);
    const danhSachNganHang = [
        { maSo: "970405", tenNganHang: "Agribank" },
        { maSo: "970422", tenNganHang: "MBBank" },
        { maSo: "970407", tenNganHang: "Teckcombank" },
        { maSo: "970415", tenNganHang: "Vietinbank" },
        { maSo: "970436", tenNganHang: "Vietcombank" },
    ];
    // nam
    var presentDate = new Date();
    const year = presentDate.getFullYear();
    const arrYear = [];
    for (let i = 0; i <= 70; i++) {
        arrYear.push(year - i);
    }

    // thang
    const arrMonth = [];
    for (let i = 1; i <= 12; i++) {
        arrMonth.push(i);
    }

    // ngay
    const arrDate = [];
    for (let i = 1; i <= 31; i++) {
        arrDate.push(i);
    }
    const handleOnchangeImage = async (event) => {
        const anhResize = await resizeAnh(event);
        if (anhResize) {
            setavatar(anhResize);
        }
    };
    const handleLuuThongTinCaNhan = () => {
        try {
            const newPost = {
                avatar: avatar,
                hoTen: hoTen,
                sdt: sdt,
                gioiTinh: gioiTinh,

                ngaySinh: ngaySinh,
                thangSinh: thangSinh,
                namSinh: namSinh,

                tinh: tinh,
                huyen: huyen,
                xa: xa,
                thonXom: thonXom,
                kinhDo: kinhDo,
                viDo: viDo,

                fabysaXanh: +fabysaXanh.giaTri,

                vaiTro: vaiTro,
                ttGiaoHang: ttGiaoHang,

                followShop: followShop,
                followBB: followBB,

                ttThem: {
                    nganHang: {
                        tenNganHang: danhSachNganHang?.find(
                            (item) => item?.maSo === maSoNganHang
                        )?.tenNganHang,
                        maSoNganHang: maSoNganHang,
                        taiKhoanNganHang: taiKhoanNganHang,
                        chuTaiKhoanNganhang: chuTaiKhoanNganhang,
                    },
                    gmail: gmail,
                },

                idUser: user?._id,
                tkUser: user?.username,
            };
            console.log("newPost", newPost);
            if (myDetail) {
                updatePost(newPost, myDetail?._id, dispatch);
            } else {
                registerPost(newPost, dispatch);
            }
            // if (allTaiKhoan && allTaiKhoan?.length === 0) {
            //     const newTaiKhoan = {
            //         GDVao: 99000,
            //         GDRa: "",
            //         noiDungCK: "Fabysa Tặng",
            //         xacNhanChuyenTien: "Thành Công",
            //         thongTinThem: {
            //             tenChuTk: hoTen,
            //             sdtChuTk: user?.username,
            //             loaiTK: "User",
            //         },
            //         idChuTaiKhoan: user?._id,
            //     };
            //     console.log("newTaiKhoan", newTaiKhoan);
            //     setallTaiKhoan([newTaiKhoan]);
            //     registerTaiKhoan(newTaiKhoan, dispatch);
            // }
        } catch (err) {
            console.log(err);
        }
    };
    // Thong Tin Chi Tiet
    //  Viet QR
    // const nganHang = tenNganHang;
    const BANK_ID = maSoNganHang;
    const ACCOUNT_NO = taiKhoanNganHang;
    const TEMPLATE = "print";
    const AMOUNT = "";
    const DESCRIPTION = "";
    const ACCOUNT_NAME = chuTaiKhoanNganhang;
    const qr = `https://img.vietqr.io/image/${BANK_ID}-${ACCOUNT_NO}-${TEMPLATE}.png?amount=${AMOUNT}&addInfo=${DESCRIPTION}&accountName=${ACCOUNT_NAME}`;
    // Viet QR

    return (
        <div className="UpdateMyDetail-ConTaiNer">
            <div className="quayLai-tieuDe">
                <a href="/" className="quayLai">
                    <i className="fa fa-angle-double-left"></i>Quay Lại
                </a>
                <div className="tieuDe">{thongTinSua}</div>
            </div>
            {thongTinSua === "Thông Tin Cá Nhân" && (
                <div className="thongTinCaNhan-container">
                    <div className="thongTin">
                        <div className="hoTen-sdt">
                            <div className="hoTen-input">
                                <div className="hoTen">Họ & Tên</div>
                                <input
                                    className="input"
                                    type="text"
                                    placeholder={hoTen}
                                    onChange={(e) => sethoTen(e.target.value)}
                                />
                            </div>
                            <div className="hoTen-input">
                                <div className="hoTen">Số Điện Thoại</div>
                                <input
                                    className="input"
                                    type="text"
                                    placeholder={sdt}
                                    onChange={(e) => setsdt(e.target.value)}
                                />
                            </div>
                            <div className="gioiTinh-select">
                                <div className="gioiTinh">Giới Tính</div>
                                <select
                                    className="select"
                                    name="gioi-tinh"
                                    id="gioi-tinh"
                                    onChange={(e) =>
                                        setgioiTinh(e.target.value)
                                    }
                                >
                                    <option>{gioiTinh}</option>
                                    <option>Nam</option>
                                    <option>Nữ</option>
                                    <option>Khác</option>
                                </select>
                            </div>
                        </div>
                        <div className="avatar-taiKhoan">
                            <div className="container-avatar">
                                <input
                                    id="avatar"
                                    type="file"
                                    hidden
                                    onChange={handleOnchangeImage}
                                    className="avatarFormregis"
                                />
                                <label
                                    htmlFor="avatar"
                                    className="avatarFormregis"
                                >
                                    <img src={avatar} className="avatar" />
                                </label>
                            </div>
                            {user?.admin === true ? (
                                <div className="taiKhoan">
                                    <BanPhimSo
                                        soMoi={fabysaXanh}
                                        setsoMoi={setfabysaXanh}
                                    />
                                </div>
                            ) : (
                                <div className="taiKhoan">
                                    <DinhDangSo
                                        soDinhDang={+fabysaXanh.giaTri}
                                        donViSo="f"
                                        // tt,sdt,vnd,f
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="ngaySinh-select">
                        <div className="ngaySinh">Ngày Sinh</div>
                        <div className="select-container">
                            <select
                                className="select"
                                onChange={(e) => setngaySinh(e.target.value)}
                            >
                                <option value="">{ngaySinh}</option>
                                {arrDate &&
                                    arrDate.length > 0 &&
                                    arrDate.map((item, index) => {
                                        return (
                                            <option key={index}>{item}</option>
                                        );
                                    })}
                            </select>
                            <select
                                className="select"
                                onChange={(e) => setthangSinh(e.target.value)}
                            >
                                <option value="">{thangSinh}</option>
                                {arrMonth &&
                                    arrMonth.length > 0 &&
                                    arrMonth.map((item, index) => {
                                        return (
                                            <option key={index}>{item}</option>
                                        );
                                    })}
                            </select>
                            <select
                                className="select"
                                onChange={(e) => setnamSinh(e.target.value)}
                            >
                                <option value="">{namSinh}</option>
                                {arrYear &&
                                    arrYear.length > 0 &&
                                    arrYear.map((item, index) => {
                                        return (
                                            <option key={index}>{item}</option>
                                        );
                                    })}
                            </select>
                        </div>
                    </div>
                    <div className="diaChi">
                        <ChonDiaChi
                            thonXom={thonXom}
                            setthonXom={setthonXom}
                            xa={xa}
                            setxa={setxa}
                            huyen={huyen}
                            sethuyen={sethuyen}
                            tinh={tinh}
                            settinh={settinh}
                        />
                    </div>
                    <div className="toaDo">
                        <div className="tDo-vTri">
                            <div className="tDo">Toạ Độ</div>
                            <div className="vTri" onClick={() => handleToaDo()}>
                                <i
                                    className="fas fa-sync-alt"
                                    style={{
                                        color: "red",
                                    }}
                                ></i>
                                &nbsp;Vị Trí
                            </div>
                        </div>

                        <div className="kinhDo-viDo">
                            <div className="kinhDo">
                                Kinh Độ :&nbsp;
                                {Math.round(kinhDo * 1000) / 1000}
                            </div>
                            <div className="viDo">
                                Vĩ Độ :&nbsp;{Math.round(viDo * 1000) / 1000}
                            </div>
                        </div>
                    </div>
                    <div className="gmail-input">
                        <div className="gmail">Gmail</div>
                        <input
                            className="input"
                            type="text"
                            placeholder={gmail}
                            onChange={(e) => setgmail(e.target.value)}
                        />
                    </div>
                </div>
            )}
            {thongTinSua === "Tài Khoản Ngân Hàng" && (
                <div className="tkNganHang-container">
                    <div className="taikhoan">
                        <div className="ttTaiKhoan">
                            <div className="stk">Ngân hàng</div>
                            <select
                                id="provinces"
                                onChange={(e) =>
                                    setmaSoNganHang(e.target.value)
                                }
                            >
                                <option>{tenNganHang}</option>
                                {danhSachNganHang?.map((item) => {
                                    return (
                                        <option
                                            value={item.maSo}
                                            key={item.maSo}
                                        >
                                            {item.tenNganHang}
                                        </option>
                                    );
                                })}
                            </select>
                            <div className="stk">Số tài Khoản</div>
                            <input
                                onChange={(e) =>
                                    settaiKhoanNganHang(e.target.value)
                                }
                                type="number"
                                placeholder={taiKhoanNganHang}
                            />
                            <div className="stk">Chủ Tài Khoản</div>
                            <input
                                onChange={(e) =>
                                    setchuTaiKhoanNganhang(e.target.value)
                                }
                                className="chuTk"
                                placeholder={chuTaiKhoanNganhang}
                            />
                        </div>
                        <div className="qr-container">
                            <img className="maQr" src={qr} />
                        </div>
                    </div>
                </div>
            )}
            <div
                onClick={() => handleLuuThongTinCaNhan()}
                className="luuThongTin"
            >
                Lưu Thông Tin
            </div>
            {isFetching === true && <Loading />}
        </div>
    );
};
export default UpdateMyDetail;
