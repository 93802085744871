import "./GioHang.scss";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    getOneDonHang,
    registerDonHang,
    updateDonHang,
    registerTaiKhoan,
    updatettShop,
    guiMail,
} from "../redux/apiRequest";
import ChonDiaChi from "../component/ChonDiaChi";
import XemAnh2 from "../GiaoDienChung/XemAnh2";
const GioHang = (props) => {
    const {
        cart,
        setcart,
        setloading,
        setTongtien,
        setTongsoluong,
        Tongtien,
        handleDaThemGioHang,
        setcartDemo,
        cartDemo,
        soBan,
        setsoBan,
        maBaoMat,
        setmaBaoMat,
        // handleXemAnhFull,
    } = props;

    const user = useSelector((state) => state.auth.login.currentUser);

    const myDetail = useSelector((state) => state.post.post?.myDetail);
    // const donHang = useSelector((state) => state.donHang.donHang?.donHang);
    const donHangTruoc = useSelector(
        (state) => state.donHang.donHang?.donHang?.donHang
    );
    const { tenVietTat, idShop, idCtv, tenCtv, sdtCtv } = useParams();
    const [hangDaDat, sethangDaDat] = useState([]);
    const [giaVon, setgiaVon] = useState();
    useEffect(() => {
        if (donHangTruoc) {
            sethangDaDat(donHangTruoc?.donHang);
        }
    }, [donHangTruoc]);
    const ttShop = useSelector((state) => state?.ttShop?.ttShop?.ttShop?.shop);
    console.log("ttShop", ttShop);
    const allNhanVien = [
        ...ttShop?.ttShopThem?.nvBanHang?.map((item) => item?.sdt),
        ...ttShop?.ttShopThem?.nvQuanLy?.map((item) => item?.sdt),
        ttShop?.sdtShop,
    ];
    console.log("donHangTruoc", donHangTruoc);
    const dispatch = useDispatch();
    const VND = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND",
    });
    const gioPhut = `${new Date().getHours()}h${new Date().getMinutes()}`;

    useEffect(() => {
        if (idShop && soBan) {
            const idDonHang = "";
            getOneDonHang(idShop, idDonHang, soBan, dispatch);
        }
    }, [idShop, soBan]);
    const [tongTien2, settongTien2] = useState(0);
    const [giamTru, setgiamTru] = useState(0);
    const [noiNhan, setnoiNhan] = useState("Nhận Tại Bàn");
    const arrSoBan = ttShop?.ttShopThem?.soBan;

    // thong tin nguoi nhan không có user
    const [sdtNguoiMua, setsdtNguoiMua] = useState("Nhập số điện thoại");
    const [hoTenNguoiMua, sethoTenNguoiMua] = useState("Nhập họ và tên");
    const [thonXom, setthonXom] = useState("Thôn/Xóm/Số nhà...");
    const [xa, setxa] = useState("Xã/Phường");
    const [huyen, sethuyen] = useState("Quận/Huyện");
    const [tinh, settinh] = useState("Tỉnh/TP");
    const [ghiChuNguoiMua, setghiChuNguoiMua] = useState("Thêm ghi chú!");
    useEffect(() => {
        if (myDetail) {
            setsdtNguoiMua(myDetail?.soDienThoai || "Nhập số điện thoại");
            sethoTenNguoiMua(myDetail?.hoTen || "Nhập họ và tên");
            setthonXom(myDetail?.thonXom || "Số nhà/Thôn/Xóm/...");
            setxa(myDetail?.xa || "Xã/Phường");
            sethuyen(myDetail?.huyen || "Quận/Huyện");
            settinh(myDetail?.tinh || "Tỉnh/TP");
        }
    }, [myDetail]);
    // Que Quan
    const handleDonHangMoi = () => {
        if (
            !donHangTruoc ||
            noiNhan !== "Nhận Tại Bàn" ||
            (noiNhan === "Nhận Tại Bàn" && donHangTruoc?.soBan !== soBan) ||
            (donHangTruoc && donHangTruoc?.trangThaiDH === "ĐH Đã Thanh Toán")
        ) {
            try {
                const maBaoMat2 = Math.floor(Math.random() * 9999) + 1000;
                setmaBaoMat(maBaoMat2);
                const newDonHang = {
                    tenShop: ttShop?.TenShop,
                    sdtShop: ttShop?.sdtShop,
                    donHang: cart,
                    idShop: idShop,
                    sdtCtv: sdtCtv,
                    sdtKhachHang: allNhanVien?.find(
                        (item) => item === sdtNguoiMua
                    )
                        ? ""
                        : sdtNguoiMua,

                    sdtOrder: user?.username || "",
                    sdtXuLyDon: "",
                    sdtGiaoHang: "",
                    sdtThuTien: "",

                    noiNhan: noiNhan,
                    soBan: soBan || "",

                    thonXomMua: thonXom,
                    xaMua: xa,
                    huyenMua: huyen,
                    tinhMua: tinh,

                    kinhDo: ttShop?.kinhDo,
                    viDo: ttShop?.viDo,

                    thonXomBan: ttShop?.thonXom,
                    xaBan: ttShop?.xa,
                    huyenBan: ttShop?.huyen,
                    tinhBan: ttShop?.tinh,

                    trangThaiDH: "ĐH Mới",

                    ttThem: {
                        baoCao: {
                            doanhThu: 0,
                            giaVon: 0,
                            phiNenTang: 0,
                            giamTru: 0,
                        },
                        ttCuaHang: {
                            idNganHang: ttShop?.ttShopThem?.nganHang?.maSo,
                            nganHang: ttShop?.ttShopThem?.nganHang?.tenNganHang,
                            soTaiKhoan:
                                ttShop?.ttShopThem?.nganHang?.taiKhoanNganHang,
                            tenChuTk:
                                ttShop?.ttShopThem?.nganHang
                                    ?.chuTaiKhoanNganhang,
                            gmail: ttShop?.ttShopThem?.gmail,
                        },
                        ttKhachHang: {
                            hoTenNguoiMua: hoTenNguoiMua,
                            sdtNguoiMua: sdtNguoiMua,
                            ghiChuNguoiMua: ghiChuNguoiMua,
                            maBaoMat: maBaoMat2,
                            nhomKhach: "Khách Lẻ",
                            gmail: "",
                        },

                        ttGiaoHang: {
                            phiGiaoHang: "",
                            thuNguoiNhan: "",
                            tenNv: "",
                            idNv: "",
                            sdtNv: "",
                            tienCoc: "",
                            hoanHang: "",
                            ckNguoiGui: "",
                            gmail: "",
                        },
                    },
                };
                console.log("newDonHang", newDonHang);
                registerDonHang(newDonHang, dispatch);

                const linkDonHang =
                    "https://fabysa.com/giao-hang/Nhận%20Giao%20Hàng/ĐH%20Tìm%20Người%20Giao";
                const newEmail = {
                    email: [
                        // ...allPosts?.map(
                        //     (item) => item && item?.ttThemUser?.gmail
                        // ),
                        ...[
                            ttShop?.ttShopThem?.gmail,
                            "hungtranvan.0976993229@gmail.com",
                        ],
                    ],
                    subject: myDetail?.hoTen,
                    html: `<a style='text-decoration: none' href=${linkDonHang}>Đơn hàng cần giao mới. Xem thêm . . . !</a>`,
                };
                guiMail(newEmail);
                console.log("newEmail", newEmail);

                setcart([]);
                setcartDemo([]);
            } catch (err) {
                console.log(err);
            }
        } else {
            const newDonHang = {
                donHang: [...cart, ...hangDaDat],
                trangThaiDH: "ĐH Mới",
            };
            updateDonHang(newDonHang, donHangTruoc?._id, dispatch);
            console.log("newDonHang", newDonHang);
            setcart([]);
            setcartDemo([]);
        }
    };

    // Thay Doi So Luong
    const handleSoLuong = (sl, item, item3) => {
        if (+sl < 0 || (+sl === "tru" && +item?.slMua === 0)) {
            alert("Số lượng phải lớn hơn 0");
        }
        if (+sl > 0) {
            const tenDacDiem_SlMua = {
                ...item,
                ...{
                    slMua: +sl,
                    gioPhut: gioPhut,
                },
            };
            const allDacDiemSP_SLmua = item3?.allDacDiemSP?.map((item4) =>
                item4?.tenDacDiem !== item?.tenDacDiem
                    ? item4
                    : tenDacDiem_SlMua
            );
            console.log("allDacDiemSP_SLmua", allDacDiemSP_SLmua);
            setcartDemo(
                cartDemo?.map((item5) =>
                    item5?._id !== item3?._id
                        ? item5
                        : {
                              ...item3,
                              ...{ allDacDiemSP: allDacDiemSP_SLmua },
                          }
                )
            );
        }
        if (sl === "tru" && +item?.slMua > 0) {
            const tenDacDiem_SlMua = {
                ...item,
                ...{
                    slMua: +item?.slMua - 1,
                    gioPhut: gioPhut,
                },
            };
            const allDacDiemSP_SLmua = item3?.allDacDiemSP?.map((item4) =>
                item4?.tenDacDiem !== item?.tenDacDiem
                    ? item4
                    : tenDacDiem_SlMua
            );
            console.log("allDacDiemSP_SLmua", allDacDiemSP_SLmua);
            setcartDemo(
                cartDemo?.map((item5) =>
                    item5?._id !== item3?._id
                        ? item5
                        : {
                              ...item3,
                              ...{ allDacDiemSP: allDacDiemSP_SLmua },
                          }
                )
            );
        }
        if (sl === "cong") {
            const tenDacDiem_SlMua = {
                ...item,
                ...{
                    slMua: +item?.slMua + 1,
                    gioPhut: gioPhut,
                },
            };
            const allDacDiemSP_SLmua = item3?.allDacDiemSP?.map((item4) =>
                item4?.tenDacDiem !== item?.tenDacDiem
                    ? item4
                    : tenDacDiem_SlMua
            );
            console.log("allDacDiemSP_SLmua", allDacDiemSP_SLmua);
            setcartDemo(
                cartDemo?.map((item5) =>
                    item5?._id !== item3?._id
                        ? item5
                        : {
                              ...item3,
                              ...{ allDacDiemSP: allDacDiemSP_SLmua },
                          }
                )
            );
        }
    };

    // Thay Doi So Luong
    useEffect(() => {
        setcart(
            cartDemo
                ?.map((item2) => {
                    if (item2?.allDacDiemSP?.find((item) => item?.slMua > 0)) {
                        return {
                            _id: item2?._id,
                            TenSanPham: item2?.TenSanPham,
                            allDacDiemSP: item2?.allDacDiemSP?.filter(
                                (item) => item?.slMua > 0
                            ),
                        };
                    } else {
                        return;
                    }
                })
                .filter((item3) => item3)
        );
    }, [cartDemo]);
    const handleSoLuongDonHang = (sl, item, item3) => {
        console.log("sl", sl);
        if (+sl < 0 || (sl === "tru" && +item?.slMua === 0)) {
            alert("Số lượng phải lớn hơn 0");
        }
        if (+sl > 0) {
            const tenDacDiem_SlMua = {
                ...item,
                ...{
                    slMua: +sl,
                    gioPhut: gioPhut,
                },
            };
            const allDacDiemSP_SLmua = item3?.allDacDiemSP?.map((item4) =>
                item4?.tenDacDiem !== item?.tenDacDiem
                    ? item4
                    : tenDacDiem_SlMua
            );
            console.log("allDacDiemSP_SLmua", allDacDiemSP_SLmua);
            const hangDaDatmoi = hangDaDat?.map((item5) =>
                item5?._id !== item3?._id
                    ? item5
                    : {
                          ...item3,
                          ...{ allDacDiemSP: allDacDiemSP_SLmua },
                      }
            );
            sethangDaDat(hangDaDatmoi);
            const newDonHang = {
                donHang: hangDaDatmoi,
            };
            updateDonHang(newDonHang, donHangTruoc?._id, dispatch);
        }
        if (sl === "tru" && +item?.slMua > 0) {
            const tenDacDiem_SlMua = {
                ...item,
                ...{
                    slMua: +item?.slMua - 1,
                    gioPhut: gioPhut,
                },
            };
            const allDacDiemSP_SLmua = item3?.allDacDiemSP?.map((item4) =>
                item4?.tenDacDiem !== item?.tenDacDiem
                    ? item4
                    : tenDacDiem_SlMua
            );
            console.log("allDacDiemSP_SLmua", allDacDiemSP_SLmua);
            const hangDaDatmoi = hangDaDat?.map((item5) =>
                item5?._id !== item3?._id
                    ? item5
                    : {
                          ...item3,
                          ...{ allDacDiemSP: allDacDiemSP_SLmua },
                      }
            );
            sethangDaDat(hangDaDatmoi);
            const newDonHang = {
                donHang: hangDaDatmoi,
            };
            updateDonHang(newDonHang, donHangTruoc?._id, dispatch);
        }
        if (sl === "cong") {
            const tenDacDiem_SlMua = {
                ...item,
                ...{
                    slMua: +item?.slMua + 1,
                    gioPhut: gioPhut,
                    // soLuong: +item?.soLuong - 1,
                },
            };
            const allDacDiemSP_SLmua = item3?.allDacDiemSP?.map((item4) =>
                item4?.tenDacDiem !== item?.tenDacDiem
                    ? item4
                    : tenDacDiem_SlMua
            );
            console.log("allDacDiemSP_SLmua", allDacDiemSP_SLmua);
            const hangDaDatmoi = hangDaDat?.map((item5) =>
                item5?._id !== item3?._id
                    ? item5
                    : {
                          ...item3,
                          ...{ allDacDiemSP: allDacDiemSP_SLmua },
                      }
            );
            sethangDaDat(hangDaDatmoi);
            const newDonHang = {
                donHang: hangDaDatmoi,
            };
            updateDonHang(newDonHang, donHangTruoc?._id, dispatch);
        }
    };
    const handleGiamTru = (giamTru3) => {
        const newDonHang = {
            ttThem: {
                ...donHangTruoc?.ttThem,
                ...{
                    baoCao: {
                        ...donHangTruoc?.ttThem?.baoCao,
                        ...{ giamTru: +giamTru3 },
                    },
                },
            },
        };
        console.log("newDonHang", newDonHang);
        updateDonHang(newDonHang, donHangTruoc?._id, dispatch);
        setgiamTru(giamTru3);
    };
    // Thay doi so luong
    // Tinh Tong Tien và so luong

    const tinhtongtien = () => {
        let tt = 0;
        if (cartDemo?.length !== 0) {
            cartDemo?.map((sp) => {
                sp?.allDacDiemSP?.map((item) => {
                    tt += +item?.slMua * item?.giaKhuyenMai;
                });
            });
        }

        setTongtien(tt);
    };
    const tinhtongtien2 = () => {
        let tt2 = 0;
        if (hangDaDat?.length !== 0) {
            hangDaDat?.map((sp) => {
                sp?.allDacDiemSP?.map((item) => {
                    tt2 += +item?.slMua * item?.giaKhuyenMai;
                });
            });
        }

        settongTien2(tt2);
    };
    const tinhsoluong = () => {
        let tt = 0;
        if (cartDemo?.length !== 0) {
            cartDemo?.map((sp) => {
                sp?.allDacDiemSP?.map((item) => {
                    tt += +item?.slMua;
                });
            });
        }

        setTongsoluong(tt);
    };
    useEffect(() => {
        tinhtongtien();
        tinhsoluong();
    });
    useEffect(() => {
        tinhtongtien2();
    }, [hangDaDat]);
    // Tinh Tong Tien và so luong
    //  Viet QR
    const [viewQR, setviewQR] = useState(-1);
    const [qr, setqr] = useState();
    useEffect(() => {
        if (viewQR === 1) {
            const nganHang = ttShop?.ttShopThem?.nganHang;
            const BANK_ID = nganHang?.maSo;
            const ACCOUNT_NO = nganHang?.taiKhoanNganHang;
            const TEMPLATE = "print";
            const AMOUNT = tongTien2 - giamTru;
            const DESCRIPTION = `Hoá Đơn ${soBan}`;
            const ACCOUNT_NAME = nganHang?.chuTaiKhoanNganhang;
            setqr(
                `https://img.vietqr.io/image/${BANK_ID}-${ACCOUNT_NO}-${TEMPLATE}.png?amount=${AMOUNT}&addInfo=${DESCRIPTION}&accountName=${ACCOUNT_NAME}`
            );
        }
    }, [viewQR]);
    // Viet QR
    useEffect(() => {
        const tinhTongGiaVon = () => {
            let tt = 0;
            if (hangDaDat?.length !== 0) {
                hangDaDat?.map((sp) => {
                    sp?.allDacDiemSP?.map((item) => {
                        tt += +item?.slMua * item?.giaVon;
                    });
                });
            }

            setgiaVon(tt);
        };
        tinhTongGiaVon();
    });

    const handleDaThanhToan = () => {
        const cauHoiPopup = window.confirm("Đã thu tiền khách hàng?");
        if (cauHoiPopup) {
            try {
                if (ttShop?.phiNenTang === "1K/Đơn Hàng") {
                    const newDonHang = {
                        trangThaiDH: "ĐH Đã Thanh Toán",
                        sdtThuTien: user?.username,
                        ttThem: {
                            ...donHangTruoc?.ttThem,
                            ...{
                                baoCao: {
                                    doanhThu:
                                        tongTien2 -
                                        donHangTruoc?.ttThem?.baoCao?.giamTru,
                                    giaVon: giaVon,
                                    giamTru:
                                        donHangTruoc?.ttThem?.baoCao?.giamTru,
                                    phiNenTang: 1000,
                                },
                            },
                        },
                    };
                    console.log("newDonHang", newDonHang);
                    updateDonHang(newDonHang, donHangTruoc?._id, dispatch);
                    const newShop = {
                        cash: +ttShop?.cash - 1000,
                    };
                    console.log("newShop", newShop);
                    updatettShop(newShop, ttShop._id, dispatch);
                    const newTaiKhoan = {
                        GDVao: "",
                        GDRa: 1000,
                        noiDungCK: "Phí Nền Tảng",
                        xacNhanChuyenTien: "Thành Công",
                        thongTinThem: {
                            tenChuTk: ttShop?.TenShop,
                            sdtChuTk: ttShop?.sdtShop,
                        },
                        idChuTaiKhoan: ttShop?._id,
                    };
                    console.log("newTaiKhoan", newTaiKhoan);
                    registerTaiKhoan(newTaiKhoan, dispatch);
                }
                if (ttShop?.phiNenTang === "1% Doanh Thu") {
                    const newDonHang = {
                        trangThaiDH: "ĐH Đã Thanh Toán",
                        sdtThuTien: user?.username,
                        ttThem: {
                            ...donHangTruoc?.ttThem,
                            ...{
                                baoCao: {
                                    doanhThu:
                                        tongTien2 -
                                        donHangTruoc?.ttThem?.baoCao?.giamTru,
                                    giaVon: giaVon,
                                    giamTru:
                                        donHangTruoc?.ttThem?.baoCao?.giamTru,
                                    phiNenTang:
                                        (tongTien2 -
                                            donHangTruoc?.ttThem?.baoCao
                                                ?.giamTru) /
                                        100,
                                },
                            },
                        },
                    };
                    console.log("newDonHang", newDonHang);
                    updateDonHang(newDonHang, donHangTruoc?._id, dispatch);
                    const newShop = {
                        cash:
                            ttShop?.cash -
                            (tongTien2 -
                                donHangTruoc?.ttThem?.baoCao?.giamTru) /
                                100,
                    };
                    console.log("newShop", newShop);
                    updatettShop(newShop, ttShop?._id, dispatch);
                    const newTaiKhoan = {
                        GDVao: "",
                        GDRa:
                            (tongTien2 -
                                donHangTruoc?.ttThem?.baoCao?.giamTru) /
                            100,
                        noiDungCK: "Phí Nền Tảng",
                        xacNhanChuyenTien: "Thành Công",
                        thongTinThem: {
                            tenChuTk: ttShop?.TenShop,
                            sdtChuTk: ttShop?.sdtShop,
                        },
                        idChuTaiKhoan: ttShop?._id,
                    };
                    console.log("newTaiKhoan", newTaiKhoan);
                    registerTaiKhoan(newTaiKhoan, dispatch);
                }
            } catch (err) {
                console.log(err);
            }
        }
    };
    const chieuRongMH = window.innerWidth;

    return (
        <div className="view">
            {chieuRongMH <= "1023" && (
                <div className="mobile">
                    <div className="GioHang-ConTaiNer">
                        <div className="quayLai-tieuDe">
                            <div
                                onClick={() => setloading(0)}
                                className="quayLai"
                            >
                                <i className="fa fa-angle-double-left"></i>
                                Quay Lại
                            </div>
                            <div className="tieuDe">Giỏ Hàng</div>
                        </div>
                        {cartDemo?.length !== 0 ? (
                            <div className="thongTinGioHang-container">
                                {cartDemo?.map((item3, index3) => {
                                    return (
                                        <div key={index3} className="sanPham">
                                            <div className="tenSanPham-xoa">
                                                <div className="tenSanPham">
                                                    {item3?.TenSanPham}
                                                </div>
                                                <div
                                                    onClick={() =>
                                                        handleDaThemGioHang(
                                                            item3
                                                        )
                                                    }
                                                    className="xoa"
                                                >
                                                    <i className="fa fa-close"></i>
                                                </div>
                                            </div>
                                            {item3?.allDacDiemSP &&
                                                item3?.allDacDiemSP?.legth !==
                                                    0 &&
                                                item3?.allDacDiemSP?.map(
                                                    (item, index) => {
                                                        return (
                                                            <div key={index}>
                                                                {item3?.allAnhSp?.map(
                                                                    (
                                                                        item2,
                                                                        index2
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    index2
                                                                                }
                                                                                className="dacDiem-tenDacDiem"
                                                                            >
                                                                                {index ===
                                                                                    index2 && (
                                                                                    <div>
                                                                                        <div className="dacDiem-themGioHang">
                                                                                            {/* <img
                                                                                                onClick={() =>
                                                                                                    handleXemAnhFull(
                                                                                                        item2?.AnhSanPham
                                                                                                    )
                                                                                                }
                                                                                                src={
                                                                                                    item2?.AnhSanPham
                                                                                                }
                                                                                                className="anhSp"
                                                                                                alt="timtim"
                                                                                            /> */}

                                                                                            <XemAnh2
                                                                                                anhBanDau={
                                                                                                    item2?.AnhSanPham
                                                                                                }
                                                                                                cssAnhBanDau={{
                                                                                                    marginLeft:
                                                                                                        "2vw",
                                                                                                    width: "12vw",
                                                                                                    height: "15vw",
                                                                                                    objectFit:
                                                                                                        "cover",
                                                                                                    borderRadius:
                                                                                                        "1vw",
                                                                                                }}
                                                                                            />
                                                                                            <div className="giaSanPham">
                                                                                                <div className="giaNY">
                                                                                                    {VND.format(
                                                                                                        item?.giaNiemYet
                                                                                                    )}
                                                                                                </div>
                                                                                                <div className="giaKM ">
                                                                                                    {VND.format(
                                                                                                        item?.giaKhuyenMai
                                                                                                    )}
                                                                                                    &nbsp;
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="soLuong-SL">
                                                                                                <div className="soLuong">
                                                                                                    Số
                                                                                                    Lượng
                                                                                                </div>
                                                                                                <div className="thayDoiSl">
                                                                                                    <div
                                                                                                        onClick={() =>
                                                                                                            handleSoLuong(
                                                                                                                "tru",
                                                                                                                item,
                                                                                                                item3
                                                                                                            )
                                                                                                        }
                                                                                                        className="giamSl"
                                                                                                    >
                                                                                                        <i className="fa fa-arrow-circle-down"></i>
                                                                                                    </div>
                                                                                                    <input
                                                                                                        type="number"
                                                                                                        className="SL"
                                                                                                        placeholder={
                                                                                                            item?.slMua
                                                                                                        }
                                                                                                        onChange={(
                                                                                                            e
                                                                                                        ) =>
                                                                                                            handleSoLuong(
                                                                                                                e
                                                                                                                    .target
                                                                                                                    .value,

                                                                                                                item,
                                                                                                                item3
                                                                                                            )
                                                                                                        }
                                                                                                    />
                                                                                                    <div
                                                                                                        onClick={() =>
                                                                                                            handleSoLuong(
                                                                                                                "cong",
                                                                                                                item,
                                                                                                                item3
                                                                                                            )
                                                                                                        }
                                                                                                        className="giamSl"
                                                                                                    >
                                                                                                        <i className="fa fa-arrow-circle-up"></i>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="thanhTien-TT">
                                                                                                <div className="thanhTien">
                                                                                                    Thành
                                                                                                    Tiền
                                                                                                </div>
                                                                                                <div className="TT">
                                                                                                    {VND.format(
                                                                                                        item?.slMua *
                                                                                                            item?.giaKhuyenMai
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="tenDacDiem">
                                                                                            {
                                                                                                item?.tenDacDiem
                                                                                            }
                                                                                            &nbsp;-&nbsp;
                                                                                            {
                                                                                                item?.soLuong
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        );
                                                    }
                                                )}
                                        </div>
                                    );
                                })}
                                <div className="tongTien">
                                    <div className="tieude">Tổng Tiền Hàng</div>
                                    <div className="sotien">
                                        {VND.format(Tongtien)}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="datHangThanhCong-conatiner">
                                {donHangTruoc ? (
                                    <div className="datHangTC">
                                        Đặt Hàng Thành Công!
                                    </div>
                                ) : (
                                    <div className="datHangTC">
                                        Giỏ Hàng Trống!
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="chonNoiNhan-container">
                            <div className="chonNoiNhan">Chọn Nơi Nhận</div>

                            <div className="noiNhan-soBan">
                                <select
                                    onChange={(e) => setnoiNhan(e.target.value)}
                                    className="noiNhan"
                                >
                                    <option>Nhận Tại Bàn</option>
                                    <option>Tự Đến Lấy</option>
                                    <option>Ship Tận Nơi</option>
                                </select>
                                {noiNhan === "Nhận Tại Bàn" && (
                                    <>
                                        <select
                                            onChange={(e) =>
                                                setsoBan(e.target.value)
                                            }
                                            className="soBan"
                                        >
                                            <option value="">{soBan}</option>
                                            {arrSoBan &&
                                                arrSoBan.length > 0 &&
                                                arrSoBan.map((item, index) => {
                                                    return (
                                                        <option key={index}>
                                                            {item}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                        {(soBan &&
                                            arrSoBan?.find(
                                                (item) => item === soBan
                                            ) &&
                                            donHangTruoc &&
                                            +donHangTruoc?.ttThem?.khachHang
                                                ?.maBaoMat === +maBaoMat) ||
                                        (soBan &&
                                            arrSoBan?.find(
                                                (item) => item === soBan
                                            ) &&
                                            donHangTruoc &&
                                            +donHangTruoc?.ttThem?.khachHang
                                                ?.sdtNguoiMua ===
                                                +user?.username &&
                                            user) ||
                                        soBan === "Chọn Số Bàn" ||
                                        !soBan ||
                                        !donHangTruoc ? (
                                            <></>
                                        ) : (
                                            <input
                                                onChange={(e) =>
                                                    setmaBaoMat(e.target.value)
                                                }
                                                placeholder="Mã Bảo Mật"
                                                className="maBaoVe"
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        {noiNhan !== "Nhận Tại Bàn" && (
                            <div className="thongTinNguoiNhan-container">
                                <div className="thongTinNguoiNhan">
                                    Thông Tin Người Nhận
                                </div>
                                <div className="thongTinChiTiet">
                                    <div className="tieuDe">Họ Và Tên</div>
                                    <input
                                        className="noiDung"
                                        onChange={(e) =>
                                            sethoTenNguoiMua(e.target.value)
                                        }
                                        placeholder={hoTenNguoiMua}
                                    />
                                </div>
                                <div className="thongTinChiTiet">
                                    <div className="tieuDe">Số Điện Thoại</div>

                                    <input
                                        className="noiDung"
                                        onChange={(e) =>
                                            setsdtNguoiMua(e.target.value)
                                        }
                                        placeholder={sdtNguoiMua}
                                    />
                                </div>
                                <div className="diaChi">
                                    <ChonDiaChi
                                        thonXom={thonXom}
                                        setthonXom={setthonXom}
                                        xa={xa}
                                        setxa={setxa}
                                        huyen={huyen}
                                        sethuyen={sethuyen}
                                        tinh={tinh}
                                        settinh={settinh}
                                    />
                                </div>
                                <div className="thongTinChiTiet">
                                    <div className="tieuDe">Ghi Chú</div>
                                    <input
                                        className="noiDung"
                                        placeholder={ghiChuNguoiMua}
                                        onChange={(e) =>
                                            setghiChuNguoiMua(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        {(noiNhan === "Nhận Tại Bàn" &&
                            donHangTruoc?.ttThem?.khachHang?.nhomKhach ===
                                "Khách Lẻ" &&
                            cart &&
                            cart?.length > 0 &&
                            donHangTruoc &&
                            soBan &&
                            arrSoBan?.find((item) => item === soBan) &&
                            hangDaDat?.length !== 0 &&
                            +donHangTruoc?.ttThem?.khachHang?.maBaoMat ===
                                +maBaoMat) ||
                        (noiNhan === "Nhận Tại Bàn" &&
                            donHangTruoc?.ttThem?.khachHang?.nhomKhach ===
                                "Khách Lẻ" &&
                            cart &&
                            cart?.length > 0 &&
                            donHangTruoc &&
                            arrSoBan?.find((item) => item === soBan) &&
                            hangDaDat?.length !== 0 &&
                            user &&
                            +user?.username ===
                                +donHangTruoc?.ttThem?.khachHang
                                    ?.sdtNguoiMua) ||
                        (noiNhan === "Nhận Tại Bàn" &&
                            cart &&
                            cart?.length > 0 &&
                            soBan &&
                            arrSoBan?.find((item) => item === soBan) &&
                            !donHangTruoc) ||
                        (noiNhan !== "Nhận Tại Bàn" &&
                            cart &&
                            cart?.length > 0 &&
                            hoTenNguoiMua !== "Nhập họ và tên" &&
                            sdtNguoiMua !== "Nhập số điện thoại" &&
                            huyen !== "Quận/Huyện") ? (
                            <div
                                onClick={() => handleDonHangMoi()}
                                className="datHang"
                            >
                                ĐẶT HÀNG
                            </div>
                        ) : (
                            <div
                                onClick={() =>
                                    alert("Thông tin đơn hàng thiếu!")
                                }
                                className="datHang2"
                            >
                                ĐẶT HÀNG
                            </div>
                        )}
                        {noiNhan === "Nhận Tại Bàn" &&
                            donHangTruoc?.ttThem?.khachHang?.nhomKhach ===
                                "Khách Lẻ" &&
                            hangDaDat &&
                            hangDaDat.length !== 0 &&
                            soBan &&
                            arrSoBan?.find((item) => item === soBan) &&
                            (+donHangTruoc?.ttThem?.khachHang?.maBaoMat ===
                                +maBaoMat ||
                                allNhanVien?.find(
                                    (item) => +item === +user?.username
                                ) ||
                                user?._id === ttShop?.user ||
                                user?.admin === true ||
                                +myDetail?.soDienThoai ===
                                    +donHangTruoc?.ttThem?.khachHang
                                        ?.sdtNguoiMua) && (
                                <>
                                    <div className="thongTinDonHang-container">
                                        <div className="thongTinDonHang">
                                            Hoá Đơn Mua Hàng
                                        </div>

                                        <div className="soBan-maBaoMat">
                                            <div className="soBan">
                                                Số Bàn:&ensp;
                                                {donHangTruoc?.soBan}
                                            </div>
                                            <div className="maBaoMat">
                                                Mã Bảo Mật:&ensp;
                                                {
                                                    donHangTruoc?.ttThem
                                                        ?.khachHang?.maBaoMat
                                                }
                                            </div>
                                        </div>

                                        {hangDaDat?.map((item3, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="sanPham"
                                                >
                                                    <div className="tenSanPham">
                                                        {item3?.TenSanPham}
                                                    </div>

                                                    {item3?.allDacDiemSP &&
                                                        item3?.allDacDiemSP
                                                            ?.length > 0 &&
                                                        item3?.allDacDiemSP?.map(
                                                            (item, index) => {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                        className="dacDiem-themGioHang"
                                                                    >
                                                                        <div className="tenDD-gioPhut">
                                                                            <div className="tenDD">
                                                                                {
                                                                                    item?.tenDacDiem
                                                                                }
                                                                            </div>

                                                                            <div className="gioPhut">
                                                                                {
                                                                                    item?.gioPhut
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="giaSanPham">
                                                                            <div className="giaNY">
                                                                                {VND.format(
                                                                                    item?.giaNiemYet
                                                                                )}
                                                                            </div>
                                                                            <div className="giaKM">
                                                                                {VND.format(
                                                                                    item?.giaKhuyenMai
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="soLuong-SL">
                                                                            <div className="soLuong">
                                                                                Số
                                                                                Lượng
                                                                            </div>
                                                                            {item.daXong !==
                                                                                1 &&
                                                                            (allNhanVien?.find(
                                                                                (
                                                                                    item
                                                                                ) =>
                                                                                    +item ===
                                                                                    +user?.username
                                                                            ) ||
                                                                                user?._id ===
                                                                                    ttShop?.user ||
                                                                                user?.admin ===
                                                                                    true) ? (
                                                                                <div className="thayDoiSl">
                                                                                    <div
                                                                                        onClick={() =>
                                                                                            handleSoLuongDonHang(
                                                                                                "tru",
                                                                                                item,
                                                                                                item3
                                                                                            )
                                                                                        }
                                                                                        className="giamSl"
                                                                                    >
                                                                                        <i className="fa fa-arrow-circle-down"></i>
                                                                                    </div>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="SL"
                                                                                        placeholder={
                                                                                            item?.slMua
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleSoLuongDonHang(
                                                                                                e
                                                                                                    .target
                                                                                                    .value,

                                                                                                item,
                                                                                                item3
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <div
                                                                                        onClick={() =>
                                                                                            handleSoLuongDonHang(
                                                                                                "cong",
                                                                                                item,
                                                                                                item3
                                                                                            )
                                                                                        }
                                                                                        className="giamSl"
                                                                                    >
                                                                                        <i className="fa fa-arrow-circle-up"></i>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div className="SLMua">
                                                                                    {
                                                                                        item?.slMua
                                                                                    }
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className="thanhTien-TT">
                                                                            <div className="thanhTien">
                                                                                Thành
                                                                                Tiền
                                                                            </div>
                                                                            <div className="TT">
                                                                                {VND.format(
                                                                                    item?.slMua *
                                                                                        item?.giaKhuyenMai
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        {item.daXong ===
                                                                        1 ? (
                                                                            <div className="daXong">
                                                                                <i className="fa-solid fa-check"></i>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="chuaXong">
                                                                                <i className="fa-solid fa-check"></i>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                </div>
                                            );
                                        })}
                                        <div className="tinhTien">
                                            <div className="tinhTien2">
                                                Tính Tiền
                                            </div>
                                            <div className="tongTien">
                                                <div className="tieude">
                                                    Tổng Tiền Hàng :
                                                </div>
                                                <div className="sotien">
                                                    {VND.format(tongTien2)}
                                                </div>
                                            </div>
                                            <div className="tongTien">
                                                <div className="tieude">
                                                    Giảm Trừ :
                                                </div>
                                                {allNhanVien?.find(
                                                    (item) =>
                                                        +item ===
                                                        +user?.username
                                                ) ||
                                                user?._id === ttShop?.user ||
                                                user?.admin === true ? (
                                                    <input
                                                        type="number"
                                                        className="sotien"
                                                        placeholder={VND.format(
                                                            donHangTruoc?.ttThem
                                                                ?.baoCao
                                                                ?.giamTru
                                                        )}
                                                        onChange={(e) =>
                                                            handleGiamTru(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                ) : (
                                                    <div className="sotien">
                                                        {VND.format(
                                                            donHangTruoc?.ttThem
                                                                ?.baoCao
                                                                ?.giamTru
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="tongTien">
                                                <div className="tieude">
                                                    Cần Thanh Toán :
                                                </div>
                                                <div className="sotien">
                                                    {VND.format(
                                                        tongTien2 -
                                                            donHangTruoc?.ttThem
                                                                ?.baoCao
                                                                ?.giamTru
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="thanhToanQrCode-container">
                                        <div
                                            onClick={() =>
                                                setviewQR(viewQR * -1)
                                            }
                                            className="thanhToanQr"
                                        >
                                            Thanh Toán Qua QR Code
                                        </div>
                                        {viewQR === 1 && (
                                            <img className="qr" src={qr} />
                                        )}
                                    </div>
                                    {donHangTruoc?.trangThaiDH !==
                                        "ĐH Đã Thanh Toán" &&
                                        allNhanVien?.find(
                                            (item) => +item === +user?.username
                                        ) && (
                                            <div
                                                onClick={() =>
                                                    handleDaThanhToan()
                                                }
                                                className="daThanhToan"
                                            >
                                                Đã Thu Tiền
                                            </div>
                                        )}
                                </>
                            )}
                    </div>
                </div>
            )}
            {chieuRongMH > "1023" && (
                <div className="pc">
                    <div className="GioHang-ConTaiNer">GioHang</div>
                </div>
            )}
        </div>
    );
};
export default GioHang;
