import "./TaiKhoanFabysa.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getttShop, getTaiKhoan, registerTaiKhoan } from "../redux/apiRequest";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import BanPhimSo from "../GiaoDienChung/BanPhimSo2";
import DinhDangSo from "../component/DinhDangSo";
import XemAnh2 from "../GiaoDienChung/XemAnh2";
import anhHaiHuoc from "../assets/images/anhHaiHuoc.jpg";
const TaiKhoanFabysa = () => {
    const dispatch = useDispatch();
    const { idShop, idUser } = useParams();

    const user = useSelector((state) => state.auth.login.currentUser);
    const ttShop = useSelector((state) => state?.ttShop?.ttShop?.ttShop?.shop);
    const myDetail = useSelector((state) => state.post.post?.myDetail);
    console.log("myDetail", myDetail);
    console.log("ttShop", ttShop);

    const taiKhoan = useSelector(
        (state) => state?.taiKhoan?.taiKhoan?.taiKhoan?.taiKhoan
    );
    const allTaiKhoan2 = useSelector(
        (state) => state?.taiKhoan?.taiKhoan?.allTaiKhoan?.allTaiKhoan
    );

    console.log("taiKhoan", taiKhoan);

    const [soTien, setsoTien] = useState({
        tenSo: "Số Lượng",
        giaTri: "0",
        loaiSo: "f",
    });
    const [loading, setloading] = useState("Thông Tin Tài Khoản");
    const [dateMax, setdateMax] = useState("");
    const [dateMin, setdateMin] = useState("");
    const [skip, setskip] = useState(0);
    const [limit, setlimit] = useState("30");
    const [sort, setsort] = useState(-1);
    const [giaoDichChon, setgiaoDichChon] = useState("Giao Dịch Vào");
    const [allTaiKhoan, setallTaiKhoan] = useState([]);
    console.log("allTaiKhoan", allTaiKhoan);

    useEffect(() => {
        const handleScroll = (e) => {
            const scrollHeight = e.target.documentElement.scrollHeight;
            const currentHeight =
                e.target.documentElement.scrollTop + window.innerHeight;
            if (currentHeight >= scrollHeight) {
                setskip(+skip + 30);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [allTaiKhoan2]);
    useEffect(() => {
        if (idUser !== "a") {
            getTaiKhoan(idUser, dateMax, dateMin, sort, skip, limit, dispatch);
        }
        if (idShop !== "a") {
            getTaiKhoan(idShop, dateMax, dateMin, sort, skip, limit, dispatch);
        }
    }, [skip, dateMax, dateMin, limit]);

    useEffect(() => {
        if (skip === 0) {
            setallTaiKhoan(allTaiKhoan2);
        } else {
            setallTaiKhoan([...allTaiKhoan2, ...allTaiKhoan]);
        }
    }, [allTaiKhoan2]);
    //  Viet QR
    // const nganHang = tenNganHang;
    const BANK_ID = "970422";
    const ACCOUNT_NO = "0931969456666";
    const TEMPLATE = "print";
    const AMOUNT = +soTien.giaTri;
    const DESCRIPTION =
        idUser === user?._id
            ? `US_${user?.username}`
            : `SH_${ttShop?.sdtShop}`;
    const ACCOUNT_NAME = "TRAN VAN HUNG";
    const qr = `https://img.vietqr.io/image/${BANK_ID}-${ACCOUNT_NO}-${TEMPLATE}.png?amount=${AMOUNT}&addInfo=${DESCRIPTION}&accountName=${ACCOUNT_NAME}`;
    // Viet QR
    // console.log("soTien",soTien?.giaTri < 0)
    const handleLuuTaiKhoan = () => {
        if (soTien?.giaTri !== 0) {
            if (idUser !== "a") {
                const newTaiKhoan = {
                    soTien: +soTien?.giaTri,
                    nDungCK: "Nạp Fabysa Xanh",
                    xacNhan: "Xác nhận",
                    ttThem: {
                        tenCTK: myDetail?.hoTen,
                        sdtCTK: myDetail?.sdt,
                        loaiTK: "User",
                    },
                    idCTK: user?._id,
                };
                console.log("newTaiKhoan", newTaiKhoan);
                registerTaiKhoan(newTaiKhoan, dispatch);
            } else {
                const newTaiKhoan = {
                    soTien: +soTien?.giaTri,
                    nDungCK: "Nạp Fabysa Xanh",
                    xacNhan: "Xác nhận",
                    ttThem: {
                        tenCTK: ttShop?.TenShop,
                        sdtCTK: ttShop?.sdtShop,
                        loaiTK: "Shop",
                    },
                    idCTK: ttShop?._id,
                };
                console.log("newTaiKhoan", newTaiKhoan);
                // registerTaiKhoan(newTaiKhoan, dispatch);
            }
        } else {
            alert("Nhập số lượng cần nạp !");
        }
    };
    const [menuChon, setmenuChon] = useState("Nạp");
    const handleChonMenu = (item) => {
        if (item !== menuChon) {
            if (item === "Nạp") {
                setmenuChon(item);
                setsoTien({ tenSo: "Số Lượng", giaTri: "0", loaiSo: "f" });
            }
            if (item === "Rút") {
                setmenuChon(item);
            }
            if (item === "Lịch Sử") {
                setmenuChon(item);
                // setallTaiKhoan([]);
                setskip(0);
            }
        } else {
            setmenuChon("");
        }
        // setsoTien(0);
        // setdanhSachSo([]);
        // setsoThayThe();
        // setallTaiKhoan([]);
        // setskip(0);
    };
    const handleChonGiaoDich = (item) => {
        setgiaoDichChon(item);
        setallTaiKhoan([]);
        setskip(0);
    };
    return (
        <div className="view">
            <div className="mobile">
                {idUser !== "a" && (
                    <div className="TaiKhoanFabysa-ConTaiNer">
                        <div className="quayLai-tieuDe">
                            <div
                                onClick={() => window.history.back()}
                                className="quayLai"
                            >
                                <i className="fa fa-angle-double-left"></i>
                                Quay Lại
                            </div>
                            <div className="donHang">Fabysa Xanh</div>
                        </div>
                        <div className="thongTinTK">
                            <div className="avatar">
                                <XemAnh2
                                    anhBanDau={myDetail?.avatar || anhHaiHuoc}
                                    cssAnhBanDau={{
                                        width: "20vw",
                                        height: "20vw",
                                        borderRadius: "50%",
                                    }}
                                />
                            </div>

                            <div className="ten-soDu">
                                <div className="ten">{myDetail?.hoTen}</div>
                                <div className="soDu">
                                    Số dư :&#160;
                                    <DinhDangSo
                                        soDinhDang={
                                            myDetail?.fabysaXanh || "9000"
                                        }
                                        donViSo="f"
                                        // tt,sdt,vnd,f
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="menu">
                            <div
                                onClick={() => handleChonMenu("Nạp")}
                                className={
                                    menuChon === "Nạp"
                                        ? "icon-phim2"
                                        : "icon-phim"
                                }
                            >
                                <i
                                    style={{
                                        fontSize: "5vw",
                                    }}
                                    className="fas fa-cloud-download-alt"
                                ></i>
                                &nbsp;Nạp
                            </div>
                            <div
                                onClick={() => handleChonMenu("Lịch Sử")}
                                className={
                                    menuChon === "Lịch Sử"
                                        ? "icon-phim2"
                                        : "icon-phim"
                                }
                            >
                                <i
                                    style={{
                                        fontSize: "5vw",
                                    }}
                                    className="fas fa-poll"
                                ></i>
                                &nbsp;Lịch Sử
                            </div>
                            <div
                                onClick={() => handleChonMenu("Rút")}
                                className={
                                    menuChon === "Rút"
                                        ? "icon-phim2"
                                        : "icon-phim"
                                }
                            >
                                <i
                                    style={{
                                        fontSize: "5vw",
                                    }}
                                    className="fas fa-cloud-upload-alt"
                                ></i>
                                &nbsp;Rút
                            </div>
                        </div>
                        <div className="chiTietMenu">
                            {menuChon === "" && (
                                <div className="gioiThieu">
                                    <div className="tieuDe">Giới Thiệu</div>
                                    <div className="noiDung">
                                        - Quy đổi :&#160;
                                        <DinhDangSo
                                            soDinhDang="1"
                                            donViSo="vnd"
                                            // tt,sdt,vnd,f
                                        />
                                        &#160;=&#160;
                                        <DinhDangSo
                                            soDinhDang="1"
                                            donViSo="f"
                                            // tt,sdt,vnd,f
                                        />
                                        <br />
                                        - Fabysa Xanh giúp các giao dịch trên
                                        Fabysa.com được nhanh chóng hơn.
                                        <br />- Fabysa Xanh được nạp thông qua
                                        chuyển khoản tài khoản ngân hàng.
                                        <br />- Người dùng có thể đổi Fabysa
                                        Xanh về tài khoản ngân hàng bất kỳ lúc
                                        nào.
                                    </div>
                                    <div className="kiHieu-container">
                                        <div className="kiHieu">F&#160;</div>
                                    </div>
                                </div>
                            )}
                            {menuChon === "Nạp" && (
                                <div className="nap">
                                    <div className="tieuDe">
                                        Nạp Fabysa Xanh
                                    </div>
                                    <div className="huongDan">
                                        - Quy đổi :&#160;
                                        <DinhDangSo
                                            soDinhDang="1"
                                            donViSo="vnd"
                                            // tt,sdt,vnd,f
                                        />
                                        &#160;=&#160;
                                        <DinhDangSo
                                            soDinhDang="1"
                                            donViSo="f"
                                            // tt,sdt,vnd,f
                                        />
                                        <br />- Hướng dẫn : Chuyển khoản số tiền
                                        tương ứng vào tài khoản dưới đây để nạp
                                        thêm Fabysa Xanh.
                                    </div>
                                    <div className="taikhoan">
                                        <div className="ttTaiKhoan">
                                            <div className="nganHang">
                                                Ngân hàng
                                            </div>
                                            <div className="tenNganHang">
                                                MBBANK
                                            </div>
                                            <div className="nganHang">
                                                Số tài Khoản
                                            </div>
                                            <div className="tenNganHang">
                                                0931969456666
                                            </div>
                                            <div className="nganHang">
                                                Chủ Tài Khoản
                                            </div>
                                            <div className="tenNganHang">
                                                TRAN VAN HUNG
                                            </div>
                                            <div className="nganHang">
                                                Nội Dung
                                            </div>
                                            <div className="tenNganHang">
                                                {DESCRIPTION}
                                            </div>
                                            <div className="nganHang">
                                                Số Tiền
                                            </div>
                                            <div className="tenNganHang">
                                                <DinhDangSo
                                                    soDinhDang={soTien?.giaTri}
                                                    donViSo="vnd"
                                                    // tt,sdt,vnd,f
                                                />
                                            </div>
                                        </div>
                                        <div className="qr-container">
                                            <XemAnh2
                                                anhBanDau={qr}
                                                cssAnhBanDau={{
                                                    width: "56vw",
                                                    objectFit: "cover",
                                                    borderRadius: "2vw",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="fabysa2-soLuong">
                                        <div className="fabysa2">
                                            Nhập Số lượng
                                        </div>
                                        <div className="soLuong2">
                                            <BanPhimSo
                                                soMoi={soTien}
                                                setsoMoi={setsoTien}
                                            />
                                        </div>
                                    </div>
                                    <div className="ghiChu">
                                        - Lưu ý : Sau khi chuyển khoản hệ thống
                                        cần thời gian để xác nhận giao dịch và
                                        hoàn thành việc nạp Fabysa Xanh. Vui
                                        lòng chờ trong ít phút !
                                        <br />- Hotline :&nbsp;&nbsp;
                                        <DinhDangSo
                                            soDinhDang="0911459929"
                                            donViSo="sdt"
                                            // tt,sdt,vnd,f
                                        />
                                    </div>
                                    {taiKhoan?.xacNhan === "Xác nhận" ? (
                                        <div
                                            onClick={() =>
                                                alert(
                                                    "Đang kiểm tra giao dịch !"
                                                )
                                            }
                                            className="choXN"
                                        >
                                            Chờ Xác Nhận
                                        </div>
                                    ) : (
                                        <div
                                            onClick={() => handleLuuTaiKhoan()}
                                            className="daCK"
                                        >
                                            Đã Chuyển Khoản
                                        </div>
                                    )}
                                </div>
                            )}
                            {menuChon === "Lịch Sử" && (
                                <div className="lichSu">
                                    <div className="tieuDe">
                                        Lịch Sử Giao Dịch
                                    </div>
                                    <div className="allGiaoDich">
                                        {allTaiKhoan &&
                                            allTaiKhoan?.length !== 0 &&
                                            allTaiKhoan?.map(
                                                (item, index) =>
                                                    item && (
                                                        <div
                                                            className="giaoDich"
                                                            key={index}
                                                        >
                                                            <div className="noiDung">
                                                                {item?.nDungCK}
                                                            </div>
                                                            <div className="tgian-soTien-thanhCong">
                                                                <div className="tgian">
                                                                    {new Date(
                                                                        item?.createdAt
                                                                    )?.getDate()}
                                                                    /
                                                                    {new Date(
                                                                        item?.createdAt
                                                                    )?.getMonth() +
                                                                        1}
                                                                    /
                                                                    {new Date(
                                                                        item?.createdAt
                                                                    )?.getFullYear()}
                                                                    &nbsp;
                                                                    {new Date(
                                                                        item?.createdAt
                                                                    )?.getHours()}
                                                                    :
                                                                    {new Date(
                                                                        item?.createdAt
                                                                    )?.getMinutes()}
                                                                </div>
                                                                <div className="thanhCong">
                                                                    {
                                                                        item?.xacNhan
                                                                    }
                                                                </div>
                                                                <div className="soTien">
                                                                    {item?.soTien >
                                                                    0 ? (
                                                                        <i
                                                                            style={{
                                                                                color: "#04aa6d",
                                                                            }}
                                                                            className="fa fa-plus"
                                                                        ></i>
                                                                    ) : (
                                                                        <i
                                                                            style={{
                                                                                color: "red",
                                                                            }}
                                                                            className="fas fa-minus"
                                                                        ></i>
                                                                    )}
                                                                    &nbsp;
                                                                    <DinhDangSo
                                                                        soDinhDang={
                                                                            item?.soTien
                                                                        }
                                                                        donViSo="f"
                                                                        // tt,sdt,vnd,f
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                            )}
                                    </div>
                                </div>
                            )}
                            {menuChon === "Rút" && (
                                <div className="rut">
                                    <div className="tieuDe">
                                        Rút Fabysa Xanh
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* <div className="chonDanhMuc">
                            {menuTaiKhoan?.map((item) => {
                                return (
                                    <div
                                        key={item}
                                        onClick={() => handleChonDanhMuc(item)}
                                        className={
                                            item === loading
                                                ? "daChon"
                                                : "chuaChon"
                                        }
                                    >
                                        {item}
                                    </div>
                                );
                            })}
                        </div>
                        {loading === "Thông Tin Tài Khoản" && (
                            <div className="ttTaiKhoan">
                                <div className="tieuDe">Tài Khoản Cá Nhân</div>
                                <div className="noiDung">
                                    - Loại tài khoản : Fabysa Xanh
                                </div>
                                <div className="noiDung">
                                    - Chủ tài khoản :&#160;{myDetail?.hoTen}
                                </div>
                                <div className="noiDung">
                                    - Số dư :&#160;
                                    {handleDinhDangSo(myDetail?.fabysaXanh)}
                                    &#160;<span>F&#160;</span>
                                </div>

                                <div className="tieuDe">
                                    Giới Thiệu Fabysa Xanh
                                </div>
                                <div className="noiDung">
                                    - Kí hiệu : &#160;<span>F&#160;</span>
                                </div>
                                <div className="noiDung">
                                    - Quy đổi : 1&#160;<span>F&#160;</span>
                                    &#160;=&#160;1&#160;VNĐ
                                </div>
                                <div className="noiDung">
                                    - Sử dụng : Dùng Fabysa Xanh giúp hoạt động
                                    giao dịch trên Fabysa.com được nhanh chóng
                                    hơn.
                                </div>
                                <div className="kiHieu-container">
                                    <div className="kiHieu">F&#160;</div>
                                </div>
                            </div>
                        )}
                        {loading === "Nạp Fabysa Xanh" && (
                            <div className="muaFabysaXanh">
                                <div className="tieuDe">Nạp Fabysa Xanh</div>
                                <div className="donGia-soLuong-thanhTien">
                                    <div className="donGia">
                                        Đơn Giá
                                        <br /> 1.000đ /1.000&#160;
                                        <span>F&#160;</span>
                                    </div>
                                    <div className="soLuong">
                                        Số Lượng
                                        <br />
                                        <div className="input">
                                            <BanPhimSo
                                                soMoi={soTien}
                                                setsoMoi={setsoTien}
                                            />
                                        </div>
                                    </div>
                                    <div className="thanhTien">
                                        Thành Tiền
                                        <DinhDangSo
                                            soDinhDang={
                                                soTien?.giaTri
                                            }
                                            donViSo="vnd"
                                            // tt,sdt,vnd,f
                                        />
                                    </div>
                                </div>

                                <div className="tieuDe">Thanh Toán Hoá Đơn</div>
                                <div className="taikhoan">
                                    <div className="ttTaiKhoan2">
                                        <div className="nganHang">
                                            Ngân hàng
                                        </div>
                                        <div className="tenNganHang">
                                           MBBANK
                                        </div>
                                        <div className="nganHang">
                                            Số tài Khoản
                                        </div>
                                        <div className="tenNganHang">
                                            0931969456666
                                        </div>
                                        <div className="nganHang">
                                            Chủ Tài Khoản
                                        </div>
                                        <div className="tenNganHang">
                                           TRAN VAN HUNG
                                        </div>
                                        <div className="nganHang">
                                            Số Tiền Nhận
                                        </div>
                                        <div className="tenNganHang">
                                        <DinhDangSo
                                            soDinhDang={
                                                soTien?.giaTri
                                            }
                                            donViSo="vnd"
                                            // tt,sdt,vnd,f
                                        />
                                        </div>
                                        <div className="nganHang">Nội Dung</div>
                                        <div className="tenNganHang">
                                            {DESCRIPTION}
                                        </div>
                                    </div>
                                    <div className="qr-container">
                                        <XemAnh2
                                            anhBanDau={qr}
                                            cssAnhBanDau={{
                                                width: "48vw",
                                                objectFit: "cover",
                                                borderRadius: "2vw",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="ghiChu">
                                    - Lưu ý : Sau khi chuyển khoản hệ thống cần
                                    thời gian để xác nhận giao dịch và hoàn
                                    thành việc mua Fabysa Xanh. Vui lòng chờ
                                    trong ít phút. Xin cảm ơn! <br /> - Cần hỗ
                                    trợ liên hệ : &nbsp;
                                    <a
                                        className="dienThoai"
                                        href={`tel:0976993229`}
                                    >
                                        &nbsp;
                                        <i
                                            className="fa fa-phone-square"
                                            style={{ color: "#04aa6d" }}
                                        ></i>
                                        &nbsp; 0976.993.229
                                    </a>
                                    &nbsp; - &nbsp;
                                    <a
                                        className="zalo"
                                        href={` https://zalo.me/0976993229`}
                                        target="_blank"
                                    >
                                        Zalo
                                    </a>
                                </div>
                                {taiKhoan?.thongTinThem?.loaiTK !== "Shop" &&
                                taiKhoan?.thongTinThem?.loaiTK !== "shop" &&
                                taiKhoan?.xacNhanChuyenTien ===
                                    "Chờ Xác Nhận" ? (
                                    <div className="xacNhanGiaoDich">
                                        Giao dịch đang được xác nhận! Vui lòng
                                        chờ!
                                    </div>
                                ) : (
                                    <div
                                        onClick={() => handleLuuTaiKhoan()}
                                        className="daChuyenKhoan"
                                    >
                                        Đã Chuyển Khoản
                                    </div>
                                )}
                            </div>
                        )} */}
                        {/* {loading === "Lịch Sử Giao Dịch" && (
                            <div className="lichSuaGD">
                                <div className="chonGiaoDich">
                                    {lsGiaoDich?.map((item) => {
                                        return (
                                            <div
                                                key={item}
                                                onClick={() =>
                                                    handleChonGiaoDich(item)
                                                }
                                                className={
                                                    item === giaoDichChon
                                                        ? "daChon"
                                                        : "chuaChon"
                                                }
                                            >
                                                {item}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="dsGiaoDich">
                                    Danh sách giao dịch{" "}
                                </div>
                                <div className="allGiaoDich">
                                    {allTaiKhoan &&
                                        allTaiKhoan?.length !== 0 &&
                                        allTaiKhoan?.map(
                                            (item, index) =>
                                                item?.GDVao && (
                                                    <div
                                                        className="chiTiet"
                                                        key={index}
                                                    >
                                                        <div className="thoiGian">
                                                            {new Date(
                                                                item?.createdAt
                                                            )?.getDate()}
                                                            /
                                                            {new Date(
                                                                item?.createdAt
                                                            )?.getMonth() + 1}
                                                            /
                                                            {new Date(
                                                                item?.createdAt
                                                            )?.getFullYear()}
                                                            &nbsp;
                                                            {new Date(
                                                                item?.createdAt
                                                            )?.getHours()}
                                                            h
                                                            {new Date(
                                                                item?.createdAt
                                                            )?.getMinutes()}
                                                        </div>
                                                        <div className="soTien-noiDung">
                                                            <div className="soTien">
                                                                +
                                                                {handleDinhDangSo(
                                                                    item?.GDVao
                                                                )}
                                                                &#160;
                                                                <span>
                                                                    F&#160;
                                                                </span>
                                                            </div>
                                                            <div className="noiDung">
                                                                {
                                                                    item?.noiDungCK
                                                                }
                                                            </div>
                                                            {item?.xacNhanChuyenTien ===
                                                            "Thành Công" ? (
                                                                <div className="thanhCong">
                                                                    {
                                                                        item?.xacNhanChuyenTien
                                                                    }
                                                                </div>
                                                            ) : (
                                                                <div className="xacNhan">
                                                                    {
                                                                        item?.xacNhanChuyenTien
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                        )}
                                    {allTaiKhoan &&
                                        allTaiKhoan?.length !== 0 &&
                                        allTaiKhoan?.map(
                                            (item, index) =>
                                                item?.GDRa && (
                                                    <div
                                                        className="chiTiet"
                                                        key={index}
                                                    >
                                                        <div className="thoiGian">
                                                            {new Date(
                                                                item?.createdAt
                                                            )?.getDate()}
                                                            /
                                                            {new Date(
                                                                item?.createdAt
                                                            )?.getMonth() + 1}
                                                            /
                                                            {new Date(
                                                                item?.createdAt
                                                            )?.getFullYear()}
                                                            &nbsp;
                                                            {new Date(
                                                                item?.createdAt
                                                            )?.getHours()}
                                                            h
                                                            {new Date(
                                                                item?.createdAt
                                                            )?.getMinutes()}
                                                        </div>
                                                        <div className="soTien-noiDung">
                                                            <div className="soTien">
                                                                -
                                                                {handleDinhDangSo(
                                                                    item?.GDRa
                                                                )}
                                                                &#160;
                                                                <span>
                                                                    F&#160;
                                                                </span>
                                                            </div>
                                                            <div className="noiDung">
                                                                {
                                                                    item?.noiDungCK
                                                                }
                                                            </div>
                                                            {item?.xacNhanChuyenTien ===
                                                            "Thành Công" ? (
                                                                <div className="thanhCong">
                                                                    {
                                                                        item?.xacNhanChuyenTien
                                                                    }
                                                                </div>
                                                            ) : (
                                                                <div className="xacNhan">
                                                                    {
                                                                        item?.xacNhanChuyenTien
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                        )}
                                </div>
                            </div>
                        )} */}
                    </div>
                )}
                {idShop !== "a" && (
                    <div className="TaiKhoanFabysa-ConTaiNer">
                        <div className="quayLai-tieuDe">
                            {/* <a
                                href={`/quan-ly-shop/${idUser}`}
                                className="quayLai"
                            >
                                <i className="fa fa-angle-double-left"></i>
                                Quay Lại
                            </a> */}
                            <div
                                onClick={() => window.history.back()}
                                className="quayLai"
                            >
                                <i className="fa fa-angle-double-left"></i>
                                Quay Lại
                            </div>
                            <div className="donHang">Fabysa Xanh</div>
                            <div className="tien-container">
                                {/* {handleDinhDangSo(ttShop?.fabysaXanh)}
                                &#160;<span>F&#160;</span> */}
                            </div>
                        </div>
                        <div className="chonDanhMuc">
                            {/* {menuTaiKhoan?.map((item) => {
                                return (
                                    <div
                                        key={item}
                                        onClick={() => handleChonDanhMuc(item)}
                                        className={
                                            item === loading
                                                ? "daChon"
                                                : "chuaChon"
                                        }
                                    >
                                        {item}
                                    </div>
                                );
                            })} */}
                        </div>
                        {loading === "Thông Tin Tài Khoản" && (
                            <div className="ttTaiKhoan">
                                <div className="tieuDe">Tài Khoản Cá Nhân</div>
                                <div className="noiDung">
                                    - Loại tài khoản : Fabysa Xanh
                                </div>
                                <div className="noiDung">
                                    - Chủ tài khoản :&#160;{ttShop?.TenShop}
                                </div>
                                <div className="noiDung">
                                    - Số dư :&#160;
                                    {/* {handleDinhDangSo(ttShop?.fabysaXanh)} */}
                                    &#160;<span>F&#160;</span>
                                </div>

                                <div className="tieuDe">
                                    Giới Thiệu Fabysa Xanh
                                </div>
                                <div className="noiDung">
                                    - Kí hiệu : &#160;<span>F&#160;</span>
                                </div>
                                <div className="noiDung">
                                    - Quy đổi : 1&#160;<span>F&#160;</span>
                                    &#160;=&#160;1&#160;VNĐ
                                </div>
                                <div className="noiDung">
                                    - Sử dụng : Dùng Fabysa Xanh giúp hoạt động
                                    giao dịch trên Fabysa.com được nhanh chóng
                                    hơn.
                                </div>
                                <div className="noiDung">
                                    - Ví dụ : Shop trả phí nền tảng, người dùng
                                    có dùng để cọc tiền hàng và thanh toán phí
                                    nền tảng khi nhận việc giao hàng qua
                                    Fabysa.com, đổi lấy sản phẩm và quà tặng ưu
                                    đãi, ...
                                </div>
                                <div className="kiHieu-container">
                                    <div className="kiHieu">F&#160;</div>
                                </div>
                            </div>
                        )}
                        {loading === "Nạp Fabysa Xanh" && (
                            <div className="muaFabysaXanh">
                                <div className="tieuDe">Nạp Fabysa Xanh</div>
                                <div className="donGia-soLuong-thanhTien">
                                    <div className="donGia">
                                        Đơn Giá
                                        <br /> 1.000đ /1.000&#160;
                                        <span>F&#160;</span>
                                    </div>
                                    <div className="soLuong">
                                        Số Lượng
                                        <br />
                                        <div className="input">
                                            <BanPhimSo
                                                soMoi={soTien}
                                                setsoMoi={setsoTien}
                                            />
                                        </div>
                                    </div>
                                    <div className="thanhTien">
                                        Thành Tiền
                                        <br />
                                        {/* {VND.format(
                                            danhSachSo?.find(
                                                (item) =>
                                                    item?.tenSo ===
                                                    "Số Tiền Nhận"
                                            )?.giaTri || 0
                                        )} */}
                                    </div>
                                </div>

                                <div className="tieuDe">Thanh Toán Hoá Đơn</div>
                                <div className="taikhoan">
                                    <div className="ttTaiKhoan2">
                                        <div className="nganHang">
                                            Ngân hàng
                                        </div>
                                        <div className="tenNganHang">
                                            MBBank
                                        </div>
                                        <div className="nganHang">
                                            Số tài Khoản
                                        </div>
                                        <div className="tenNganHang">
                                            0931969456666
                                        </div>
                                        <div className="nganHang">
                                            Chủ Tài Khoản
                                        </div>
                                        <div className="tenNganHang">
                                            Trần Văn Hùng
                                        </div>
                                        <div className="nganHang">
                                            Số Tiền Nhận
                                        </div>
                                        <div className="tenNganHang">
                                            {/* {VND.format(
                                                danhSachSo?.find(
                                                    (item) =>
                                                        item?.tenSo ===
                                                        "Số Tiền Nhận"
                                                )?.giaTri || 0
                                            )} */}
                                            <DinhDangSo
                                                soDinhDang={soTien?.giaTri}
                                                donViSo="vnd"
                                                // tt,sdt,vnd,f
                                            />
                                        </div>
                                        <div className="nganHang">Nội Dung</div>
                                        <div className="tenNganHang">
                                            {DESCRIPTION}
                                        </div>
                                    </div>
                                    <div className="qr-container">
                                        <XemAnh2
                                            anhBanDau={qr}
                                            cssAnhBanDau={{
                                                width: "48vw",
                                                objectFit: "cover",
                                                borderRadius: "2vw",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="ghiChu">
                                    - Lưu ý : Sau khi chuyển khoản hệ thống cần
                                    thời gian để xác nhận giao dịch và hoàn
                                    thành việc mua Fabysa Xanh. Vui lòng chờ
                                    trong ít phút. Xin cảm ơn! <br /> - Cần hỗ
                                    trợ liên hệ : &nbsp;
                                    <a
                                        className="dienThoai"
                                        href={`tel:0976993229`}
                                    >
                                        &nbsp;
                                        <i
                                            className="fa fa-phone-square"
                                            style={{ color: "#04aa6d" }}
                                        ></i>
                                        &nbsp; 0976.993.229
                                    </a>
                                    &nbsp; - &nbsp;
                                    <a
                                        className="zalo"
                                        href={` https://zalo.me/0976993229`}
                                        target="_blank"
                                    >
                                        Zalo
                                    </a>
                                </div>
                                {taiKhoan?.thongTinThem?.loaiTK !== "User" &&
                                taiKhoan?.thongTinThem?.loaiTK !== "user" &&
                                taiKhoan?.xacNhanChuyenTien === "ChoXN" ? (
                                    <div className="xacNhanGiaoDich">
                                        Giao dịch đang được xác nhận! Vui lòng
                                        chờ!
                                    </div>
                                ) : (
                                    <div
                                        onClick={() => handleLuuTaiKhoan()}
                                        className="daChuyenKhoan"
                                    >
                                        Đã Chuyển Khoản
                                    </div>
                                )}
                            </div>
                        )}
                        {/* {loading === "Lịch Sử Giao Dịch" && (
                            <div className="lichSuaGD">
                                <div className="chonGiaoDich">
                                    {lsGiaoDich?.map((item) => {
                                        return (
                                            <div
                                                key={item}
                                                onClick={() =>
                                                    handleChonGiaoDich(item)
                                                }
                                                className={
                                                    item === giaoDichChon
                                                        ? "daChon"
                                                        : "chuaChon"
                                                }
                                            >
                                                {item}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="dsGiaoDich">
                                    Danh sách giao dịch
                                </div>
                                <div className="allGiaoDich">
                                    {allTaiKhoan &&
                                        allTaiKhoan?.length !== 0 &&
                                        allTaiKhoan?.map(
                                            (item, index) =>
                                                item?.GDVao && (
                                                    <div
                                                        className="chiTiet"
                                                        key={index}
                                                    >
                                                        <div className="thoiGian">
                                                            {new Date(
                                                                item?.createdAt
                                                            )?.getDate()}
                                                            /
                                                            {new Date(
                                                                item?.createdAt
                                                            )?.getMonth() + 1}
                                                            /
                                                            {new Date(
                                                                item?.createdAt
                                                            )?.getFullYear()}
                                                            &nbsp;
                                                            {new Date(
                                                                item?.createdAt
                                                            )?.getHours()}
                                                            h
                                                            {new Date(
                                                                item?.createdAt
                                                            )?.getMinutes()}
                                                        </div>
                                                        <div className="soTien-noiDung">
                                                            <div className="soTien">
                                                                +
                                                                {handleDinhDangSo(
                                                                    item?.GDVao
                                                                )}
                                                                &#160;
                                                                <span>
                                                                    F&#160;
                                                                </span>
                                                            </div>
                                                            <div className="noiDung">
                                                                {
                                                                    item?.noiDungCK
                                                                }
                                                            </div>
                                                            {item?.xacNhanChuyenTien ===
                                                            "Thành Công" ? (
                                                                <div className="thanhCong">
                                                                    {
                                                                        item?.xacNhanChuyenTien
                                                                    }
                                                                </div>
                                                            ) : (
                                                                <div className="xacNhan">
                                                                    {
                                                                        item?.xacNhanChuyenTien
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                        )}
                                    {allTaiKhoan &&
                                        allTaiKhoan?.length !== 0 &&
                                        allTaiKhoan?.map(
                                            (item, index) =>
                                                item?.GDRa && (
                                                    <div
                                                        className="chiTiet"
                                                        key={index}
                                                    >
                                                        <div className="thoiGian">
                                                            {new Date(
                                                                item?.createdAt
                                                            )?.getDate()}
                                                            /
                                                            {new Date(
                                                                item?.createdAt
                                                            )?.getMonth() + 1}
                                                            /
                                                            {new Date(
                                                                item?.createdAt
                                                            )?.getFullYear()}
                                                            &nbsp;
                                                            {new Date(
                                                                item?.createdAt
                                                            )?.getHours()}
                                                            h
                                                            {new Date(
                                                                item?.createdAt
                                                            )?.getMinutes()}
                                                        </div>
                                                        <div className="soTien-noiDung">
                                                            <div className="soTien">
                                                                -
                                                                {handleDinhDangSo(
                                                                    item?.GDRa
                                                                )}
                                                                &#160;
                                                                <span>
                                                                    F&#160;
                                                                </span>
                                                            </div>
                                                            <div className="noiDung">
                                                                {
                                                                    item?.noiDungCK
                                                                }
                                                            </div>
                                                            {item?.xacNhanChuyenTien ===
                                                            "Thành Công" ? (
                                                                <div className="thanhCong">
                                                                    {
                                                                        item?.xacNhanChuyenTien
                                                                    }
                                                                </div>
                                                            ) : (
                                                                <div className="xacNhan">
                                                                    {
                                                                        item?.xacNhanChuyenTien
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                        )}
                                </div>
                            </div>
                        )} */}
                    </div>
                )}
            </div>
            <div className="pc">
                <div className="TaiKhoanFabysa-ConTaiNer">TaiKhoanFabysa</div>
            </div>
        </div>
    );
};
export default TaiKhoanFabysa;
