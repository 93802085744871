import "./TimGiaoHang.scss";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import {
    getOneDonHang,
    updateDonHang,
    updatePost,
    registerTaiKhoan,
    deleteDonHang,
    guiMail,
} from "../redux/apiRequest";
import DinhDangSo from "../component/DinhDangSo";
const TimGiaoHang = () => {
    const { idDonHang, tenVietTat, idShop, idCtv, tenCtv, sdtCtv } =
        useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.login.currentUser);
    const myDetail = useSelector((state) => state.post.post?.myDetail);
    const donHang = useSelector(
        (state) => state.donHang.donHang?.donHang?.donHang
    );
    useEffect(() => {
        const idShop = "";
        const soBan = "";
        getOneDonHang(idShop, idDonHang, soBan, dispatch);
    }, [idDonHang]);
    const [phiNenTang, setphiNenTang] = useState(1000);

    const handleQuayLai = () => {
        navigate(`/giao-hang/Tìm%20Giao%20Hàng/${donHang?.trangThaiDH}`);
    };
    const handleDangShip = () => {
        const newDonHang = {
            trangThaiDH: "ĐH Trên Đường Giao",
        };
        console.log("newDonHang", newDonHang);
        updateDonHang(newDonHang, donHang?._id, dispatch);
    };
    const handleHuyDonShip = () => {
        const cauHoi = window.confirm("Xoá đơn hàng cần giao này ?");
        if (cauHoi) {
            deleteDonHang(donHang?._id, dispatch);
            navigate(`/giao-hang/Tìm%20Giao%20Hàng/${donHang?.trangThaiDH}`);
        }
    };
    const handleShipDaThanhToan = () => {
        const cauHoi = window.confirm("Đã nhận tiền giao hàng chuyển ?");
        if (cauHoi) {
            if (myDetail?.fabysaXanh > phiNenTang) {
                const newDonHang = {
                    ttThem: {
                        ...donHang?.ttThem,
                        ...{
                            ttGiaoHang: {
                                ...donHang?.ttThem?.ttGiaoHang,
                                ckNguoiGui: "Đã Nhận",
                            },
                        },
                    },
                };
                console.log("newDonHang", newDonHang);
                updateDonHang(newDonHang, donHang?._id, dispatch);
                const newShop = {
                    fabysaXanh: +myDetail?.fabysaXanh - +phiNenTang,
                };
                console.log("newShop", newShop);
                updatePost(newShop, myDetail._id, dispatch);
                const newTaiKhoan = {
                    GDVao: "",
                    GDRa: +phiNenTang,
                    noiDungCK: "Phí Nền Tảng",
                    xacNhanChuyenTien: "Thành Công",
                    thongTinThem: {
                        tenChuTk: myDetail?.hoTen,
                        sdtChuTk: myDetail?.soDienThoai,
                    },
                    idChuTaiKhoan: myDetail?.user,
                };
                console.log("newTaiKhoan", newTaiKhoan);
                registerTaiKhoan(newTaiKhoan, dispatch);

                const linkDonHang = `https://fabysa.com/nhan-ship/${donHang?._id}/a/a/a/a/a/a`;
                const newEmail = {
                    email: donHang?.ttThem?.ttGiaoHang?.gmail,
                    subject: donHang?.ttThem?.ttGiaoHang?.tenNv,
                    html: `<a style='text-decoration: none' href=${linkDonHang}>Đơn hàng đã xác nhận. . . !</a>`,
                };
                guiMail(newEmail);
            } else {
                alert("Thiếu Fabysa Xanh để trả phí nền tảng !");
            }
        }
    };
    const handleShipDaHoanHang = () => {
        const cauHoi = window.confirm("Đã nhận hàng hoàn ?");
        if (cauHoi) {
            const newDonHang = {
                ttThem: {
                    ...donHang?.ttThem,
                    ...{
                        ttGiaoHang: {
                            ...donHang?.ttThem?.ttGiaoHang,
                            hoanHang: "Đã Nhận",
                        },
                    },
                },
            };
            console.log("newDonHang", newDonHang);
            updateDonHang(newDonHang, donHang?._id, dispatch);

            const linkDonHang = `https://fabysa.com/nhan-ship/${donHang?._id}/a/a/a/a/a/a`;
            const newEmail = {
                email: donHang?.ttThem?.ttGiaoHang?.gmail,
                subject: `${myDetail?.hoTen}`,
                html: `<a style='text-decoration: none' href=${linkDonHang}>Đơn hàng đã xác nhận. . . !</a>`,
            };
            guiMail(newEmail);
        }
    };
    const handlePrint = () => {
        window.print();
    };
    //  Viet QR
    const nganHang = donHang?.ttThem?.ttCuaHang?.nganHang;
    const BANK_ID = donHang?.ttThem?.ttCuaHang?.idNganHang;
    const ACCOUNT_NO = donHang?.ttThem?.ttCuaHang?.soTaiKhoan;
    const TEMPLATE = "print";
    const AMOUNT = +donHang?.ttThem?.ttGiaoHang?.thuNguoiNhan;
    const DESCRIPTION = `${donHang?._id}`;
    const ACCOUNT_NAME = donHang?.ttThem?.ttCuaHang?.tenChuTk;
    const qr = `https://img.vietqr.io/image/${BANK_ID}-${ACCOUNT_NO}-${TEMPLATE}.png?amount=${AMOUNT}&addInfo=${DESCRIPTION}&accountName=${ACCOUNT_NAME}`;
    // Viet QR

    return (
        <div className="view">
            <div className="mobile">
                <div className="chiTietDonHang-ConTaiNer">
                    <div className="quayLai-tieuDe">
                        <div
                            onClick={() => handleQuayLai()}
                            className="quayLai"
                        >
                            <i className="fa fa-angle-double-left"></i>Quay Lại
                        </div>
                        <div className="tieuDe">Chi Tiết Đơn Hàng</div>
                        <div className="inHoaDon" onClick={() => handlePrint()}>
                            <i className="fa-solid fa-print"></i>
                        </div>
                    </div>
                    <div className="trangThaiDh-huyDon">
                        <div className="trangThaiDh">
                            <i
                                className="fas fa-shopping-bag"
                                style={{
                                    color: "red",
                                }}
                            ></i>
                            &#160;
                            {donHang?.trangThaiDH}
                        </div>
                        {donHang?.trangThaiDH === "ĐH Tìm Người Giao" && (
                            <div
                                onClick={() => handleHuyDonShip()}
                                className="huyDon"
                            >
                                <i
                                    className="fa fa-remove"
                                    style={{
                                        color: "red",
                                    }}
                                ></i>
                                &#160;Xoá Đơn
                            </div>
                        )}
                    </div>
                    <div className="chiTiet">
                        <div className="noiGui-thongTin">
                            <div className="tieuDe">
                                <i
                                    style={{ color: "red" }}
                                    className="fas fa-arrow-alt-circle-up"
                                ></i>
                                &nbsp;Người Gửi
                            </div>
                            <div className="noiDung">
                                Họ & Tên :&nbsp;{donHang?.tenShop}
                            </div>
                            <a
                                href={`tel:${donHang?.sdtShop}`}
                                className="noiDung"
                            >
                                Số Điện Thoại :&nbsp; &nbsp;
                                <i
                                    className="fa fa-phone-square"
                                    style={{ color: "#04aa6d" }}
                                ></i>
                                &nbsp;
                                <DinhDangSo
                                    soDinhDang={donHang?.sdtShop}
                                    donViSo="sdt"
                                    // tt,vnd,f
                                />
                            </a>
                            <div className="noiDung">
                                Địa Chỉ : &nbsp;{donHang?.thonXomBan}
                                ,&nbsp;
                                {donHang?.xaBan},&nbsp;
                                {donHang?.huyenBan},&nbsp;
                                {donHang?.tinhBan}
                            </div>
                        </div>
                        <div className="noiGui-thongTin">
                            <div className="tieuDe">
                                <i
                                    style={{ color: "red" }}
                                    className="fas fa-arrow-alt-circle-down"
                                ></i>
                                &nbsp;Người Nhận
                            </div>
                            <div className="noiDung">
                                Họ & Tên :&nbsp;
                                {donHang?.ttThem?.ttKhachHang?.hoTenNguoiMua}
                            </div>

                            <a
                                className="noiDung"
                                href={`tel:${donHang?.ttThem?.ttKhachHang?.sdtNguoiMua}`}
                            >
                                Số Điện Thoại :&nbsp; &nbsp;
                                <i
                                    className="fa fa-phone-square"
                                    style={{ color: "#04aa6d" }}
                                ></i>
                                &nbsp;
                                <DinhDangSo
                                    soDinhDang={
                                        donHang?.ttThem?.ttKhachHang
                                            ?.sdtNguoiMua
                                    }
                                    donViSo="sdt"
                                    // tt,sdt,vnd,f
                                />
                            </a>
                            <div className="noiDung">
                                Địa Chỉ : &nbsp;{donHang?.thonXomMua}
                                ,&nbsp;
                                {donHang?.xaMua},&nbsp;
                                {donHang?.huyenMua},&nbsp;
                                {donHang?.tinhMua}
                            </div>
                        </div>
                        <div className="noiGui-thongTin">
                            <div className="tieuDe">
                                <i
                                    style={{ color: "red" }}
                                    className="fas fa-shipping-fast"
                                ></i>
                                &nbsp;Người Giao Hàng
                            </div>
                            {donHang?.trangThaiDH !== "ĐH Tìm Người Giao" && (
                                <div className="noiDung">
                                    Họ & Tên :&nbsp;
                                    {donHang?.ttThem?.ttGiaoHang?.tenNv}
                                </div>
                            )}
                            {donHang?.trangThaiDH !== "ĐH Tìm Người Giao" && (
                                <a
                                    className="noiDung"
                                    href={`tel:${donHang?.ttThem?.ttGiaoHang?.phiShip?.sdtNv}`}
                                >
                                    Số Điện Thoại :&nbsp; &nbsp;
                                    <i
                                        className="fa fa-phone-square"
                                        style={{ color: "#04aa6d" }}
                                    ></i>
                                    &nbsp;
                                    <DinhDangSo
                                        soDinhDang={
                                            donHang?.ttThem?.ttGiaoHang?.sdtNv
                                        }
                                        donViSo="sdt"
                                        // tt,sdt,vnd,f
                                    />
                                </a>
                            )}

                            <div className="noiDung">
                                Giá Trị Hàng (Cọc) :&nbsp;
                                <DinhDangSo
                                    soDinhDang={
                                        donHang?.ttThem?.baoCao?.doanhThu -
                                        donHang?.ttThem?.baoCao?.giamTru
                                    }
                                    donViSo="vnd"
                                    // tt,vnd,f
                                />
                            </div>
                            <div className="noiDung">
                                Phí Giao Hàng :&nbsp;
                                <DinhDangSo
                                    soDinhDang={
                                        donHang?.ttThem?.ttGiaoHang?.phiGiaoHang
                                    }
                                    donViSo="vnd"
                                    // tt,sdt,vnd,f
                                />
                            </div>
                            <div className="noiDung">
                                Thu Người Nhận :&nbsp;
                                <DinhDangSo
                                    soDinhDang={
                                        +donHang?.ttThem?.ttGiaoHang
                                            ?.thuNguoiNhan
                                    }
                                    donViSo="vnd"
                                    // tt,vnd,f
                                />
                            </div>
                            {donHang?.trangThaiDH === "ĐH Tìm Người Giao" && (
                                <div className="noiDung">
                                    . . . Vui lòng chờ người giao hàng nhận đơn
                                    ! . . .
                                </div>
                            )}
                        </div>
                    </div>
                    {donHang?.trangThaiDH === "ĐH Giao Khách Đã Nhận" &&
                        donHang?.ttThem?.ttCuaHang && (
                            <div className="thanhToan-container">
                                <div className="tieuDe">
                                    <i
                                        style={{ color: "red" }}
                                        className="fa fa-qrcode"
                                    ></i>
                                    &nbsp;Nhận Tiền Người Giao Hàng
                                </div>
                                <div className="noiDung">
                                    Ngân Hàng : &nbsp;
                                    {donHang?.ttThem?.ttCuaHang?.nganHang}
                                </div>
                                <div className="noiDung">
                                    Số Tài Khoản : &nbsp;
                                    {donHang?.ttThem?.ttCuaHang?.soTaiKhoan}
                                </div>
                                <div className="noiDung">
                                    Chủ Tài Khoản : &nbsp;
                                    {donHang?.ttThem?.ttCuaHang?.tenChuTk}
                                </div>
                                <div className="noiDung">
                                    Số Tiền = Thu Người Nhận =&nbsp;
                                    <DinhDangSo
                                        soDinhDang={
                                            +donHang?.ttThem?.ttGiaoHang
                                                ?.thuNguoiNhan
                                        }
                                        donViSo="vnd"
                                        // tt,vnd,f
                                    />
                                </div>
                                <img className="qr" src={qr} />
                            </div>
                        )}
                    <div className="phimChucNang-container">
                        {donHang?.trangThaiDH === "ĐH Người Giao Đã Nhận" && (
                            <div className="phimChucNang">
                                <div
                                    className="phim"
                                    onClick={() => handleDangShip()}
                                >
                                    Người Giao Hàng Đã Lấy Hàng
                                </div>
                            </div>
                        )}
                        {donHang?.trangThaiDH === "ĐH Trên Đường Giao" && (
                            <div className="phimChucNang"></div>
                        )}
                        {donHang?.trangThaiDH === "ĐH Giao Khách Đã Nhận" && (
                            <div className="phimChucNang">
                                {donHang?.ttThem?.ttGiaoHang?.ckNguoiGui ===
                                    "" && (
                                    <div
                                        onClick={() =>
                                            alert("Chờ chuyển khoản !")
                                        }
                                        className="phimXam"
                                    >
                                        <i
                                            // style={{ color: "red" }}
                                            className="fa fa-square"
                                        ></i>
                                        &nbsp;Đã Nhận Tiền
                                    </div>
                                )}
                                {donHang?.ttThem?.ttGiaoHang?.ckNguoiGui ===
                                    "Chờ Xác Nhận" && (
                                    <div
                                        onClick={() => handleShipDaThanhToan()}
                                        className="phimXanh"
                                    >
                                        <i
                                            // style={{ color: "red" }}
                                            className="fa fa-square"
                                        ></i>
                                        &nbsp;Đã Nhận Tiền
                                    </div>
                                )}
                                {donHang?.ttThem?.ttGiaoHang?.ckNguoiGui ===
                                    "Đã Nhận" && (
                                    <div
                                        onClick={() =>
                                            alert("Đơn hàng đã hoàn thành !")
                                        }
                                        className="phimDo"
                                    >
                                        <i
                                            // style={{ color: "red" }}
                                            className="fa fa-check-square"
                                        ></i>
                                        &nbsp;Đã Nhận Tiền
                                    </div>
                                )}
                            </div>
                        )}
                        {donHang?.trangThaiDH ===
                            "ĐH Giao Khách Không Nhận" && (
                            <div className="phimChucNang">
                                {donHang?.ttThem?.ttGiaoHang?.hoanHang ===
                                    "" && (
                                    <div
                                        onClick={() => alert("Chờ hoàn hàng !")}
                                        className="phimXam"
                                    >
                                        <i
                                            // style={{ color: "red" }}
                                            className="fa fa-square"
                                        ></i>
                                        &nbsp;Đã Hoàn Hàng
                                    </div>
                                )}
                                {donHang?.ttThem?.ttGiaoHang?.hoanHang ===
                                    "Chờ Xác Nhận" && (
                                    <div
                                        onClick={() => handleShipDaHoanHang()}
                                        className="phimXanh"
                                    >
                                        <i
                                            // style={{ color: "red" }}
                                            className="fa fa-square"
                                        ></i>
                                        &nbsp;Đã Hoàn Hàng
                                    </div>
                                )}
                                {donHang?.ttThem?.ttGiaoHang?.hoanHang ===
                                    "Đã Nhận" && (
                                    <div
                                        onClick={() =>
                                            alert("Đơn hàng đã hoàn thành !")
                                        }
                                        className="phimDo"
                                    >
                                        <i
                                            // style={{ color: "red" }}
                                            className="fa fa-check-square"
                                        ></i>
                                        &nbsp;Đã Hoàn Hàng
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="luuY-ConTainer">
                        {donHang?.trangThaiDH === "ĐH Tìm Người Giao" && (
                            <div className="luuY">
                                <div className="tieuDe">
                                    <i
                                        style={{ color: "red" }}
                                        className="fas fa-exclamation-triangle"
                                    ></i>
                                    &nbsp;Lưu ý
                                </div>
                                <div className="noiDung">
                                    - Fabysa là trung gian giữa người gửi, người
                                    nhận và người giao hàng. <br />- Fabysa giữ
                                    cọc của người giao hàng để đảm bảo hàng được
                                    giao đến nơi an toàn không hư hỏng và mất
                                    mát.
                                    <br />- Số tiền cọc bằng bằng giá trị hàng
                                    gửi đi. <br />- Phí nền tảng là&nbsp;
                                    <DinhDangSo
                                        soDinhDang={phiNenTang}
                                        donViSo="f"
                                        // tt,vnd,f
                                    />
                                    /Đơn hàng hoàn thành.
                                </div>
                            </div>
                        )}
                        {donHang?.trangThaiDH === "ĐH Người Giao Đã Nhận" && (
                            <div className="luuY">
                                <div className="tieuDe">
                                    <i
                                        style={{ color: "red" }}
                                        className="fas fa-exclamation-triangle"
                                    ></i>
                                    &nbsp;Lưu ý
                                </div>
                                <div className="noiDung">
                                    - Người gửi thanh toán phí giao hàng cho
                                    người giao hàng ngay lúc người giao hàng đến
                                    lấy hàng đi giao. <br />- Người giao hàng
                                    chuyển khoản tiền thu người nhận cho người
                                    gửi sau khi giao hàng thành công.
                                </div>
                            </div>
                        )}
                        {donHang?.trangThaiDH === "ĐH Giao Khách Đã Nhận" && (
                            <div className="luuY">
                                <div className="tieuDe">
                                    <i
                                        style={{ color: "red" }}
                                        className="fas fa-exclamation-triangle"
                                    ></i>
                                    &nbsp;Lưu ý
                                </div>
                                <div className="noiDung">
                                    - Sau khi người nhận nhận hàng, người giao
                                    hàng sẽ chuyển tiền thu người nhận cho người
                                    gửi, nếu đã nhận được tiền, hãy bấm Đã Nhận
                                    Tiền để hoàn thành đơn hàng.
                                    <br />- Phí nền tảng là&nbsp;
                                    <DinhDangSo
                                        soDinhDang={phiNenTang}
                                        donViSo="f"
                                        // tt,vnd,f
                                    />
                                    /Đơn hàng hoàn thành.
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="pc">
                <div className="TimGiaoHang-ConTaiNer">TimGiaoHang</div>
            </div>
        </div>
    );
};
export default TimGiaoHang;
