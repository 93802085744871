import "./AddShop.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { registerttShop } from "../redux/apiRequest";
import Loading from "../GiaoDienChung/Loading";
import ChonDiaChi from "../component/ChonDiaChi";
const AddShop = () => {
    const user = useSelector((state) => state?.auth?.login?.currentUser);
    const TTLoading = useSelector((state) => state?.ttShop?.ttShop?.isFetching);
    const message = useSelector(
        (state) => state?.ttShop?.ttShop?.ttShop?.message
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [TenShop, setTenShop] = useState();
    const [SdtShop, setSdtShop] = useState();
    const [UserShop, setUserShop] = useState(user?._id);

    const [thonXom, setthonXom] = useState("Thôn/Xóm/Số nhà...");
    const [xa, setxa] = useState("Xã/Phường");
    const [huyen, sethuyen] = useState("Quận/Huyện");
    const [tinh, settinh] = useState("Tỉnh/TP");
    const [tenVietTat, settenVietTat] = useState("");
    console.log("tenVietTat", tenVietTat);

    const handleLuuThongTinShop = () => {
        if (!message || message !== "Tao moi thanh Cong") {
            const menuShop = [
                "Khuyến Mại Đặc Biệt",
                "Điện Thoại",
                "Quần Áo",
                "Đồng Hồ ",
                "Hoa Quả",
            ];
            const nvBanHang = [{ sdt: SdtShop, ten: "Chủ Shop" }];
            const nvQuanLy = [{ sdt: SdtShop, ten: "Chủ Shop" }];
            const soBan = ["Bàn 1", "Bàn 2", "Bàn 3"];
            if (!TenShop || !SdtShop || !tinh || !huyen) {
                alert("Vui lòng nhập đủ thông tin");
            } else {
                try {
                    const newShop = {
                        TenShop: TenShop,
                        sdtShop: SdtShop,
                        tinh: tinh,
                        huyen: huyen,
                        xa: xa || "Trống",
                        thonXom: thonXom || "Trống",

                        cash: 300000,
                        phiNenTang: "1K/Đơn Hàng",
                        capBac: 1,
                        xuatBan: "Xây Dựng",
                        tenVietTat: tenVietTat,
                        ttShopThem: {
                            menuShop,
                            giaoDien: "1",
                            nvBanHang,
                            nvQuanLy,
                            soBan,
                            tenVietTat: "ten-ngan",
                        },
                        allAnhShop: [],
                        user: UserShop || user._id,
                    };
                    console.log("newShop", newShop);
                    registerttShop(newShop, dispatch);
                } catch (err) {
                    console.log(err);
                }
            }
        }
    };
    const handleTenShop = (str, ten, gd) => {
        if (str && ten) {
            // str = str?.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
            // str = str?.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
            // str = str?.replace(/ì|í|ị|ỉ|ĩ/g, "i");
            // str = str?.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
            // str = str?.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
            // str = str?.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
            // str = str?.replace(/đ/g, "d");
            // str = str?.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
            // str = str?.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
            // str = str?.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
            // str = str?.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
            // str = str?.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
            // str = str?.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
            // str = str?.replace(/Đ/g, "D");
            str = str?.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "");
            str = str?.replace(/\u02C6|\u0306|\u031B/g, "");
            str = str?.replace(/ + /g, " ");
            str = str?.replace(/  /g, "");
            str = str?.trim();
            str = str?.replace(
                /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
                " "
            );
            str = str.split(" ");
            str = str.join("-");
            settenVietTat(`Fa1-${str}`);
            setTenShop(ten);
        }
    };
    return (
        <div className="addShop-container">
            <div className="quayLai-tieuDe">
                <a href={`/`} className="quayLai">
                    <i className="fa fa-angle-double-left"></i>Quay Lại
                </a>
                <div className="tieuDe">Thêm Shop Mới</div>
            </div>
            <div className="addShop">
                <div className="tenShop-TS">
                    <div className="tenShop">Tên Shop</div>
                    <input
                        className="TS"
                        placeholder="Nhập Tên Shop"
                        type="text"
                        onChange={(e) =>
                            handleTenShop(e.target.value, e.target.value, "")
                        }
                    />
                </div>

                <div className="tenShop-TS">
                    <div className="tenShop">Số Điện Thoại</div>
                    <input
                        className="TS"
                        placeholder="Nhập Số Điện Thoại"
                        type="text"
                        onChange={(e) => setSdtShop(e.target.value)}
                    />
                </div>

                <div className="diaChi">
                    <ChonDiaChi
                        thonXom={thonXom}
                        setthonXom={setthonXom}
                        xa={xa}
                        setxa={setxa}
                        huyen={huyen}
                        sethuyen={sethuyen}
                        tinh={tinh}
                        settinh={settinh}
                    />
                </div>
                <div className="luuY">
                    Shop mới được tặng 300.000&#160;<span>F&#160;</span>
                </div>
                {!message || message !== "Tao moi thanh Cong" ? (
                    <div className="luuShop" onClick={handleLuuThongTinShop}>
                        Thêm Shop Mới
                    </div>
                ) : (
                    <div className="daluuShop">Đã Thêm Mới</div>
                )}
            </div>
            {TTLoading === true && <Loading />}
        </div>
    );
};
export default AddShop;
