import "./NhomGiaoHang.scss";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { getDonHang, updatePost } from "../redux/apiRequest";
import DinhDangSo from "../component/DinhDangSo";

const NhomGiaoHang = () => {
    const { nhiemVu, trangThaiDH } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const myDetail = useSelector((state) => state.post.post?.myDetail);
    const user = useSelector((state) => state.auth.login.currentUser);

    const [skip, setskip] = useState(0);
    const [sort, setsort] = useState(-1);
    const [phiNenTang, setphiNenTang] = useState(1000);
    const allDonHang2 = useSelector(
        (state) => state.donHang.donHang.alldonHang?.allDonHang
    );
    const [allDonHang, setallDonHang] = useState([]);
    console.log("allDonHang", allDonHang);
    const [kinhDo, setkinhDo] = useState(0);
    const [viDo, setviDo] = useState(0);

    const handleDangKiGiaoHang = () => {
        const cauHoi = window.confirm("Bạn muốn đăng kí làm người giao hàng ?");
        if (cauHoi) {
            const newPost = {
                doiTacGiaoHang: "Người Giao Hàng",
            };
            updatePost(newPost, myDetail?._id, dispatch);
        }
    };
    const arrTrangThai = [
        "ĐH Tìm Người Giao",
        "ĐH Người Giao Đã Nhận",
        "ĐH Trên Đường Giao",
        "ĐH Giao Khách Đã Nhận",
        "ĐH Giao Khách Không Nhận",
    ];
    useEffect(() => {
        if (skip > 0) {
            setallDonHang([...allDonHang, ...allDonHang2]);
        } else {
            setallDonHang(allDonHang2);
        }
    }, [allDonHang2]);
    useEffect(() => {
        const handleScroll = (e) => {
            const scrollHeight = e.target.documentElement.scrollHeight;
            const currentHeight =
                e.target.documentElement.scrollTop + window.innerHeight;
            if (currentHeight >= scrollHeight) {
                setskip(skip + 5);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [allDonHang]);
    useEffect(() => {
        if (nhiemVu === "Nhận Giao Hàng") {
            if (trangThaiDH === "ĐH Tìm Người Giao") {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        const idShop = "";
                        const sdtCtv = "";
                        const sdtKhachHang = "";
                        const sdtOrder = "";
                        const sdtXuLyDon = "";
                        const sdtGiaoHang = "";
                        const sdtThuTien = "";
                        // const kinhDo = position.coords.latitude;
                        // const viDo = position.coords.longitude;
                        // const skip = "";
                        const limit = 5;
                        // const trangThaiDH = "";
                        const noiNhan = "";
                        getDonHang(
                            idShop,
                            sdtCtv,
                            sdtKhachHang,
                            sdtOrder,
                            sdtXuLyDon,
                            sdtGiaoHang,
                            sdtThuTien,
                            position.coords.latitude,
                            position.coords.longitude,
                            skip,
                            limit,
                            trangThaiDH,
                            noiNhan,
                            sort,
                            dispatch
                        );
                        setkinhDo(position.coords.latitude);
                        setviDo(position.coords.longitude);
                    });
                }
            } else {
                const idShop = "";
                const sdtCtv = "";
                const sdtKhachHang = "";
                const sdtOrder = "";
                const sdtXuLyDon = "";
                const sdtGiaoHang = user?.username || "";
                const sdtThuTien = "";
                const kinhDo = "";
                const viDo = "";
                // const skip = "";
                const limit = 5;
                // const trangThaiDH = "";
                const noiNhan = "";
                getDonHang(
                    idShop,
                    sdtCtv,
                    sdtKhachHang,
                    sdtOrder,
                    sdtXuLyDon,
                    sdtGiaoHang,
                    sdtThuTien,
                    kinhDo,
                    viDo,
                    skip,
                    limit,
                    trangThaiDH,
                    noiNhan,
                    sort,
                    dispatch
                );
            }
        }
        if (nhiemVu === "Tìm Giao Hàng") {
            // const idShop = "";
            const sdtCtv = "";
            const sdtKhachHang = "";
            const sdtOrder = "";
            const sdtXuLyDon = "";
            const sdtGiaoHang = "";
            const sdtThuTien = "";
            const kinhDo = "";
            const viDo = "";
            // const skip = "";
            const limit = 5;
            // const trangThaiDH = "";
            const noiNhan = "";
            getDonHang(
                user?._id || "",
                sdtCtv,
                sdtKhachHang,
                sdtOrder,
                sdtXuLyDon,
                sdtGiaoHang,
                sdtThuTien,
                kinhDo,
                viDo,
                skip,
                limit,
                trangThaiDH,
                noiNhan,
                sort,
                dispatch
            );
        }
    }, [trangThaiDH, skip, nhiemVu]);
    const handleThemDonHangGiao = () => {
        navigate(`/them-ship`);
    };
    const handleChonDonHang = (item) => {
        setallDonHang([]);
        setskip(0);
        if (nhiemVu === "Tìm Giao Hàng") {
            navigate(`/giao-hang/Tìm%20Giao%20Hàng/${item}`);
        } else {
            navigate(`/giao-hang/Nhận%20Giao%20Hàng/${item}`);
        }
    };
    const handleChiTietDonHang = (item) => {
        console.log("item", item);
        if (user) {
            if (nhiemVu === "Tìm Giao Hàng") {
                navigate(`/tim-ship/${item?._id}/a/a/a/a/a/a`);
            } else {
                if (
                    +myDetail?.fabysaXanh >=
                    +item?.ttThem?.baoCao?.doanhThu -
                        +item?.ttThem?.baoCao?.giamTru
                ) {
                    navigate(`/nhan-ship/${item?._id}/a/a/a/a/a/a`);
                    console.log(
                        "gffg",
                        +myDetail?.fabysaXanh >=
                            +item?.ttThem?.baoCao?.doanhThu -
                                +item?.ttThem?.baoCao?.giamTru
                    );
                } else {
                    alert("Tài khoản Fabysa cần lớn hơn giá trị đơn hàng!");
                }
            }
        } else {
            alert("Đăng nhập để nhận đơn!");
        }
    };
    const handleQuayLai = () => {
        navigate(`/`);
    };
    return (
        <div className="view">
            <div className="mobile">
                <div className="NhomGiaoHang-ConTaiNer">
                    <div className="quayLai-tieuDe">
                        {/* <div
                            onClick={() => window.history.back()}
                            className="quayLai"
                        >
                            <i className="fa fa-angle-double-left"></i>
                            Quay Lại
                        </div> */}
                        <div
                            onClick={() => handleQuayLai()}
                            className="quayLai"
                        >
                            <i className="fa fa-angle-double-left"></i>Quay Lại
                        </div>
                        <div className="tieuDe">{nhiemVu}</div>
                        {user ? (
                            <a
                                href={`/fabysa-xanh/a/${user?._id}`}
                                className="taiKhoanShop"
                            >
                                <i
                                    className="fa fa-plus"
                                    style={{ color: "#04aa6d" }}
                                ></i>
                                &#160;
                                <DinhDangSo
                                    soDinhDang={myDetail?.fabysaXanh}
                                    donViSo="f"
                                    // tt,vnd,f
                                />
                            </a>
                        ) : (
                            <div
                                onClick={() => alert("Đăng nhập để sử dụng!")}
                                className="taiKhoanShop"
                            >
                                <i
                                    className="fa fa-plus"
                                    style={{ color: "#04aa6d" }}
                                ></i>
                                &#160;
                                <DinhDangSo
                                    soDinhDang="99000"
                                    donViSo="f"
                                    // tt,vnd,f
                                />
                            </div>
                        )}
                    </div>
                    <div className="nhomDonHang">
                        <div className="trangThai">
                            <select
                                onChange={(e) =>
                                    handleChonDonHang(e.target.value)
                                }
                                className="chonTrangThai"
                                id="provinces"
                            >
                                <option>{trangThaiDH}</option>
                                {arrTrangThai?.map(
                                    (item, index) =>
                                        item && (
                                            <option value={item} key={item}>
                                                {index + 1}.&nbsp;{item}
                                            </option>
                                        )
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="tieuDe-traHang">
                        <div className="tieuDe">Danh Sách Đơn Hàng</div>
                        {nhiemVu === "Nhận Giao Hàng" ? (
                            <div>
                                {myDetail?.doiTacGiaoHang ===
                                "Người Giao Hàng" ? (
                                    <div className="toaDo">
                                        Toạ độ:&nbsp;
                                        {Math.round(kinhDo * 1) / 1}/
                                        {Math.round(viDo * 1) / 1}
                                    </div>
                                ) : (
                                    <div
                                        className="traHang"
                                        onClick={() => handleDangKiGiaoHang()}
                                    >
                                        <i
                                            className="fa fa-check-square"
                                            style={{ color: "red" }}
                                        ></i>
                                        &#160;Nhận Giao
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div
                                onClick={() => handleThemDonHangGiao()}
                                className="traHang"
                            >
                                <i
                                    className="fa fa-plus"
                                    style={{ color: "red" }}
                                ></i>
                                &#160;Đơn hàng
                            </div>
                        )}
                    </div>
                    <div className="allDonHang">
                        {allDonHang && allDonHang?.length > 0 ? (
                            allDonHang?.map((item, index) => {
                                return (
                                    <div
                                        onClick={() =>
                                            handleChiTietDonHang(item)
                                        }
                                        key={index}
                                        className="donHang-container2"
                                    >
                                        <div className="ngayThang-container">
                                            {(item?.ttThem?.ttGiaoHang
                                                ?.tienCoc ===
                                                "Đã Nhận Lại Cọc" &&
                                                nhiemVu === "Nhận Giao Hàng") ||
                                            (item?.ttThem?.ttGiaoHang
                                                ?.ckNguoiGui === "Đã Nhận" &&
                                                nhiemVu === "Tìm Giao Hàng") ||
                                            (item?.ttThem?.ttGiaoHang
                                                ?.hoanHang === "Đã Nhận" &&
                                                nhiemVu === "Tìm Giao Hàng") ? (
                                                <div className="trangThai2">
                                                    {item?.trangThaiDH}
                                                </div>
                                            ) : (
                                                <div className="trangThai">
                                                    {item?.trangThaiDH}
                                                </div>
                                            )}

                                            <div className="ngayThang">
                                                {new Date(
                                                    item?.createdAt
                                                )?.getHours()}
                                                :
                                                {new Date(
                                                    item?.createdAt
                                                )?.getMinutes()}{" "}
                                                &nbsp;
                                                {new Date(
                                                    item?.createdAt
                                                )?.getDate()}
                                                /
                                                {new Date(
                                                    item?.createdAt
                                                )?.getMonth() + 1}
                                                /
                                                {new Date(
                                                    item?.createdAt
                                                )?.getFullYear()}
                                            </div>
                                        </div>
                                        <div className="phi-coc-daNhan">
                                            <div className="coc">
                                                - Giá Trị Hàng (Cọc) :&nbsp;
                                                <DinhDangSo
                                                    soDinhDang={
                                                        item?.ttThem?.baoCao
                                                            ?.doanhThu -
                                                        item?.ttThem?.baoCao
                                                            ?.giamTru
                                                    }
                                                    donViSo="vnd"
                                                    // tt,sdt,vnd,f
                                                />
                                            </div>
                                            <div className="coc">
                                                - Phí Giao Hàng :&nbsp;
                                                <DinhDangSo
                                                    soDinhDang={
                                                        item?.ttThem?.ttGiaoHang
                                                            ?.phiGiaoHang
                                                    }
                                                    donViSo="vnd"
                                                    // tt,sdt,vnd,f
                                                />
                                            </div>
                                            <div className="coc">
                                                - Thu Người Nhận :&nbsp;
                                                <DinhDangSo
                                                    soDinhDang={
                                                        item?.ttThem?.ttGiaoHang
                                                            ?.thuNguoiNhan
                                                    }
                                                    donViSo="vnd"
                                                    // tt,sdt,vnd,f
                                                />
                                            </div>
                                        </div>
                                        <div className="noiGui-diaChi">
                                            <div className="noiGui">
                                                - Nơi Gửi :&nbsp;
                                                {item?.thonXomBan},&nbsp;
                                                {item?.xaBan},&nbsp;
                                                {item?.huyenBan},&nbsp;
                                                {item?.tinhBan}
                                            </div>
                                            <div className="noiGui">
                                                - Nơi Nhận :&nbsp;
                                                {item?.thonXomMua},&nbsp;
                                                {item?.xaMua},&nbsp;
                                                {item?.huyenMua},&nbsp;
                                                {item?.tinhMua}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="trong">Trống!</div>
                        )}
                    </div>
                </div>
            </div>
            <div className="pc">
                <div className="NhomGiaoHang-ConTaiNer">NhomGiaoHang</div>
            </div>
        </div>
    );
};
export default NhomGiaoHang;
