import "./Shop2.scss";
import gioHang2 from "../assets/images/giohang.jpg";
import menu from "../assets/images/menu.png";
import like from "../assets/images/like.jpg";
import like2 from "../assets/images/like2.jpg";
import XemAnh2 from "../GiaoDienChung/XemAnh2";
import HeaderShop from "./HeaderShop";
import SuaMenu from "./SuaMenu";

import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import {
    getttShop,
    getSanPham,
    getSanPhamDanHuyen2,
    getPost,
    registerGioHang,
    updateGioHang,
    getGioHang,
    getYourStatus,
    getArrSanPham,
    updatePost,
} from "../redux/apiRequest";
import { useEffect } from "react";
import GioHang from "./GioHangSi";
import ChiTietSanPham2 from "./ChiTietSanPhamSi";
import UpdateSanPham from "./UpdateSanPham";
import Loading from "../GiaoDienChung/Loading";
const Shop2 = () => {
    const { tenVietTat, idShop, idCtv, tenCtv, sdtCtv } = useParams();
    const user = useSelector((state) => state.auth.login.currentUser);
    const myDetail = useSelector((state) => state.post.post?.myDetail);
    const gioHang = useSelector(
        (state) => state.gioHang.gioHang.gioHang?.gioHang
    );
    const ttShop = useSelector((state) => state.ttShop.ttShop.ttShop?.shop);

    const ttShopThem = ttShop?.ttShopThem;
    const khachSi = ttShopThem?.khachSi;
    const khachCtv = ttShopThem?.khachCtv;
    const nvBanHang = ttShopThem?.nvBanHang;
    const nvQuanLy = ttShop?.ttShopThem?.nvQuanLy;
    const allSanPham2 = useSelector(
        (state) => state.sanPham.sanPham.allsanPham?.allSanpham
    );
    const VND = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND",
    });
    const [Tongsoluong, setTongsoluong] = useState(0);
    const [Tongtien, setTongtien] = useState(0);
    const [loading, setloading] = useState(0);
    const [loadingTruoc, setloadingTruoc] = useState(0);
 
    const [thongTinSp, setthongTinSp] = useState();
    const [sort, setsort] = useState(1);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [nhomSP, setnhomSP] = useState("Xin kính chào quý khách");
    const [skip, setskip] = useState(0);
    const [soBan, setsoBan] = useState("fabysa");
    const [maBaoMat, setmaBaoMat] = useState();
    const [allSanPham, setallSanPham] = useState([]);

    const nhomSanPham2 = ttShop?.ttShopThem?.menuShop;
    const nhomSanPham = nhomSanPham2?.filter((item) => item !== nhomSP);

    useEffect(() => {
        if (user && user.length !== 0) {
            getPost(user?._id, dispatch);
            getGioHang(idShop, user?._id, dispatch);
        }
    }, []);
    useEffect(() => {
        getttShop(idShop, dispatch);
        getYourStatus(idShop, dispatch);
    }, [idShop]);
    // get san pham
    useEffect(() => {
        if (ttShop) {
            setnhomSP(
                ttShop?.ttShopThem?.menuShop[0] || "Xin kính chào quý khách"
            );
        }
    }, [ttShop]);
    useEffect(() => {
        const handleScroll = (e) => {
            const scrollHeight = e.target.documentElement.scrollHeight;
            const currentHeight =
                e.target.documentElement.scrollTop + window.innerHeight;
            if (currentHeight >= scrollHeight) {
                setskip(skip + 6);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [allSanPham]);

    useEffect(() => {
        const limit = 6;
        getSanPham(idShop, nhomSP, sort, skip, limit, dispatch);
    }, [nhomSP, skip, sort]);

    useEffect(() => {
        if (allSanPham2 && allSanPham) {
            setallSanPham([...allSanPham, ...allSanPham2]);
        }
    }, [allSanPham2]);
    // get san pham
    // Gio hang
    const [cart, setcart] = useState([]);
    const [cartDemo, setcartDemo] = useState([]);
    // Gio hang

    // Chi Tiet San Pham
    const handleChiTietSP = (item) => {
        setloading(4);
        setthongTinSp(item);
    };
    //Gio Hang
    const handleDaThemGioHang = (item) => {
        if (cartDemo && cartDemo.length > 0) {
            const CartDemo2 = cartDemo.filter(
                (item2) => item2._id !== item._id
            );
            console.log("xetCartDemo", CartDemo2);
            setcartDemo(CartDemo2);
        }

        if (cart && cart.length > 0) {
            const xetCart = cart.filter((item2) => item2._id !== item._id);
            setcart(xetCart);
        }
    };
    const handleThemGioHang = (item) => {
        const allDacDiemSP = item?.allDacDiemSP;
        const allDacDiemSP2 = allDacDiemSP?.map(
            (item) =>
                item && {
                    ...item,
                    slMua: 0,
                }
        );
        setcartDemo([
            ...cartDemo,
            {
                _id: item?._id,
                tenSanPham: item?.TenSanPham,
                allDacDiemSP: allDacDiemSP2,
                thongTinSanPham: item?.thongTinSanPham,
            },
        ]);
    };
    // Gio Hang
    const handleSapXep = () => {
        setallSanPham([]);
        setsort(-sort);
        setskip(0);
    };
    // Xem Anh Full
    // const handlexemAnh = (item) => {
    //     setloading(5);
    //     setxemAnhFull(item);
    //     setloadingTruoc(loading);
    // };
    // Xem Anh Full
    const handleChonNhomSanPham = (item) => {
        setallSanPham([]);
        setnhomSP(item);
        setskip(0);
    };
    // Them Sp
    const themSanPhamMoi = () => {
        const themSp = window.confirm("Thêm Sản Phẩm Mới?");
        if (themSp) {
            navigate(`/addsp/${idShop}`);
        }
    };
    // Them Sp
    // Sua Menu
    const suaMenuMoi = () => {
        const themSp = window.confirm("Sửa Menu Shop?");
        if (themSp) {
            navigate(`/sua-menu/${idShop}`);
        }
    };
    // Sua Menu

    return (
        <div className="Shop2-Container">
            
        </div>
    );
};
export default Shop2;
