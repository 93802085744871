import "./DangNhap.scss";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { loginUser } from "../redux/apiRequest";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import logoFabysa from "../assets/images/logoFabysa.png";
import { useEffect } from "react";
const DangNhap = () => {
    const register = useSelector((state) => state.auth.register);
    const login = useSelector((state) => state.auth.login);
    const auth = useSelector((state) => state.auth);
    console.log("auth", auth);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleLogin = (e) => {
        if (!username || !password) {
            alert("Thiếu thông tin tài khoản!");
        } else {
            const user = {
                username: username,
                password: password,
            };
            loginUser(user, dispatch);
        }
    };
    useEffect(() => {
        if (
            login?.currentUser &&
            login?.currentUser === "Tài khoản hoặc mật khẩu không đúng !"
        ) {
            alert("Tài khoản hoặc mật khẩu không đúng !");
        }
        if (
            login?.currentUser &&
            login?.currentUser !== "Tài khoản hoặc mật khẩu không đúng !"
        ) {
            navigate(`/`);
        }
    }, [login]);
    const handleQuenMk = () => {
        const doiMk = window.confirm("Cần hỗ trợ đổi mật khẩu?");
        if (doiMk) {
            window.location = `https://www.facebook.com/profile.php?id=61563450972545`;
        }
    };

    return (
        <div className="login-containerTo">
            <div className="quayLai-tieuDe">
                <a href={`/`} className="quayLai">
                    <i className="fa fa-angle-double-left"></i>Quay Lại
                </a>
                <div className="tieuDe">Đăng Nhập</div>
            </div>

            <div className="login-container">
                <div className="logoDangNhap">
                    <img src={logoFabysa} alt="he" />
                </div>
                <div className="dangNhap">
                    <div className="labelDangNhap">Số Điện Thoại</div>
                    <input
                        className="inputDangNhap"
                        type="text"
                        placeholder="Nhập số điện thoại"
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <div className="labelDangNhap">Mật Khẩu</div>
                    <input
                        className="inputDangNhap"
                        type="password"
                        placeholder="Nhập mật khẩu"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <div
                        onClick={() => handleLogin()}
                        className="buttonDangNhap"
                    >
                        Đăng Nhập
                    </div>
                </div>
                <div onClick={() => handleQuenMk()} className="quenMk">
                    Quên mật khẩu?
                </div>
                <div className="login-register"> Nếu chưa có tài khoản? </div>
                <NavLink className="login-register-link" to={`/dang-ki`}>
                    Đăng Kí
                </NavLink>
            </div>
        </div>
    );
};
export default DangNhap;
