import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MiniGame from "./MiniGame/MiniGame";
import TrangCaNhan from "./ThongTinCaNhan/TrangCaNhan";
import UpdateMyDetail from "./ThongTinCaNhan/UpdateMyDetail";

import XacNhanTaiKhoan from "./QuanLyFabysa/XacNhanTaiKhoan";

import NhanGiaoHang from "./QuanLyGiaoHang/NhanGiaoHang";
import TimGiaoHang from "./QuanLyGiaoHang/TimGiaoHang";
import NhomGiaoHang from "./QuanLyGiaoHang/NhomGiaoHang";
import ThemDonHangGiao from "./QuanLyGiaoHang/ThemDonHangGiao";

import DangNhap from "./DangNhap/DangNhap";
import DangKi from "./DangKi/DangKi";
import QuanLyUser from "./QuanLyUser/QuanLyUser";
import BaoCao from "./QuanLyShop/BaoCao";

import ShopYeuThich from "./ThongTinCaNhan/ShopYeuThich";

import TrangChuShop from "./QuanLyShop/TrangChuShop";
import AddShop from "./QuanLyShop/AddShop";
import UpdateSanPham from "./QuanLyShop/UpdateSanPham";
import NhanVienShop from "./QuanLyShop/NhanVienShop";

import DonViHanhChinhVN from "./QuanLyFabysa/DonViHanhChinhVN";
import QuanLyShop from "./QuanLyShop/QuanLyShop";
import KhoCtv from "./QuanLyShop/KhoCtv";
import KhoSi from "./QuanLyShop/KhoSi";
import ThuNghiem from "./ComponentViDu/ThuNghiem";
import UpdateShop from "./QuanLyShop/UpdateShop";
import TaiKhoanFabysa from "./QuanLyFabysa/TaiKhoanFabysa";
import AddSp from "./QuanLyShop/AddSp";
import SuaMenu from "./QuanLyShop/SuaMenu";
import DonHangMoi from "./QuanLyShop/DonHangMoi";
import DonHangShopChiTiet from "./QuanLyShop/DonHangShopChiTiet";
import DonHangShop from "./QuanLyShop/DonHangShop";
import DoiTac from "./QuanLyShop/DoiTac";
import DonHangMua from "./ThongTinCaNhan/DonHangMua";
import ThayPassword from "./ThayPassword/ThayPassword";

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/thu-nghiem" element={<ThuNghiem />} />

                    <Route
                        path="/sua-trang-ca-nhan/:thongTinSua"
                        element={<UpdateMyDetail />}
                    />
                    <Route path="/dang-ki" element={<DangKi />} />
                    <Route path="/dang-nhap" element={<DangNhap />} />
                    <Route path="/change-password" element={<ThayPassword />} />
                    <Route path="/quan-ly-user" element={<QuanLyUser />} />

                    <Route path="/cua-hang-follow" element={<ShopYeuThich />} />

                    <Route path="/them-cua-hang" element={<AddShop />} />
                    <Route
                        path="/:quan-ly-shop/:idShop"
                        element={<QuanLyShop />}
                    />

                    <Route
                        path="/:idShop/:tenVietTat"
                        element={<TrangChuShop />}
                    />

                    <Route
                        path="/update-san-pham/:idSanPham/:idShop/:trangTruoc"
                        element={<UpdateSanPham />}
                    />

                    <Route
                        path="/kho-ctv/:tenVietTat/:idShop/a/:idCtv/:tenCtv/:sdtCtv"
                        element={<KhoCtv />}
                    />
                    <Route
                        path="/kho-si/:tenVietTat/:idShop/a/:idCtv/:tenCtv/:sdtCtv"
                        element={<KhoSi />}
                    />
                    <Route
                        path="/don-mua/:tenVietTat/:idShop/a/:idCtv/:tenCtv/:sdtCtv"
                        element={<DonHangMua />}
                    />
                    <Route
                        path="/mini-game/:tenVietTat/:idShop/a/:idCtv/:tenCtv/:sdtCtv"
                        element={<MiniGame />}
                    />
                    <Route
                        path="/fabysa-xanh/:idShop/:idUser"
                        element={<TaiKhoanFabysa />}
                    />

                    <Route path="/xac-nhan-tk" element={<XacNhanTaiKhoan />} />
                    <Route
                        path="/bao-cao-shop/:tenVietTat/:idShop/a/:idCtv/:tenCtv/:sdtCtv"
                        element={<BaoCao />}
                    />
                    <Route
                        path="/nhan-ship/:idDonHang/:tenVietTat/:idShop/a/:idCtv/:tenCtv/:sdtCtv"
                        element={<NhanGiaoHang />}
                    />

                    <Route
                        path="/tim-ship/:idDonHang/:tenVietTat/:idShop/a/:idCtv/:tenCtv/:sdtCtv"
                        element={<TimGiaoHang />}
                    />
                    <Route path="/them-ship" element={<ThemDonHangGiao />} />
                    <Route
                        path="/giao-hang/:nhiemVu/:trangThaiDH"
                        element={<NhomGiaoHang />}
                    />
                    <Route
                        path="/update-shop/:idShop"
                        element={<UpdateShop />}
                    />
                    <Route path="/addsp/:idShop" element={<AddSp />} />
                    <Route path="/sua-menu/:idShop/" element={<SuaMenu />} />
                    {/* <Route path="/don-hang/:idShop" element={<DonHangMoi />} /> */}
                    <Route path="/don-hang/:idShop" element={<DonHangShop />} />
                    <Route
                        path="/chi-tiet-don-hang/:idShop/:idDonHang"
                        element={<DonHangShopChiTiet />}
                    />
                    <Route path="/doi-tac/:idShop" element={<DoiTac />} />
                    <Route
                        path="/nhan-vien/:idShop"
                        element={<NhanVienShop />}
                    />
                    <Route
                        path="/don-vi-hanh-chinh-vn"
                        element={<DonViHanhChinhVN />}
                    />

                    <Route path="/" element={<TrangCaNhan />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
