import "./XacNhanTaiKhoan.scss";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import {
    getTaiKhoanXacNhan,
    updateTaiKhoan,
    getttShop,
    getYourPost,
    updatettShop,
    updateYourPost,
    registerTaiKhoan,
} from "../redux/apiRequest";
import DinhDangSo from "../component/DinhDangSo";
const XacNhanTaiKhoan = () => {
    const taiKhoan = useSelector(
        (state) => state?.taiKhoan?.taiKhoan?.taiKhoan?.taiKhoan
    );
    const allTaiKhoan2 = useSelector(
        (state) => state?.taiKhoan?.taiKhoan?.allTaiKhoan?.allTaiKhoan
    );
    const ttShop = useSelector((state) => state.ttShop.ttShop.ttShop?.shop);
    const yourDetail = useSelector((state) => state.post.post?.yourDetail);
    console.log("yourDetail", yourDetail);
    const [allTaiKhoan, setallTaiKhoan] = useState([]);

    console.log("allTaiKhoan", allTaiKhoan);

    const [sort, setsort] = useState(1);
    const [skip, setskip] = useState(0);
    const [limit, setlimit] = useState(20);
    const [ttGiaoDich, setttGiaoDich] = useState();

    const dispatch = useDispatch();

    useEffect(() => {
        const handleScroll = (e) => {
            const scrollHeight = e.target.documentElement.scrollHeight;
            const currentHeight =
                e.target.documentElement.scrollTop + window.innerHeight;
            if (currentHeight >= scrollHeight) {
                setskip(+skip + 20);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [allTaiKhoan2]);
    useEffect(() => {
        getTaiKhoanXacNhan(sort, skip, limit, dispatch);
    }, [skip]);
    useEffect(() => {
        if (skip === 0) {
            setallTaiKhoan(allTaiKhoan2);
        } else {
            setallTaiKhoan([...allTaiKhoan, ...allTaiKhoan2]);
        }
    }, [allTaiKhoan2]);

    const handleChiTietTk = (item) => {
        setttGiaoDich(item);
        if (item?.ttThem?.loaiTK === "User") {
            getYourPost(item?.idCTK, dispatch);
        }
        if (item?.ttThem?.loaiTK === "Shop") {
            getttShop(item?.idCTK, dispatch);
        }
    };
    const handleGiaoDichThanhCong = () => {
        if (ttGiaoDich?.ttThem?.loaiTK === "User") {
            const newTaiKhoan = {
                xacNhan: "Thành Công",
            };
            console.log("newTaiKhoan", newTaiKhoan);
            // updateTaiKhoan(newTaiKhoan, ttGiaoDich?._id, dispatch);

            const newPost = {
                cash: +yourDetail?.fabysaXanh + +ttGiaoDich?.soTien,
            };
            console.log("newPost", newPost);
            // updateYourPost(newPost, yourDetail?._id, dispatch);
        } else {
            const newTaiKhoan = {
                xacNhan: "Thành Công",
            };
            console.log("newTaiKhoan", newTaiKhoan);
            // updateTaiKhoan(newTaiKhoan, ttGiaoDich?._id, dispatch);

            const newShop = {
                cash: +ttShop?.cash + +taiKhoan?.soTien,
            };
            console.log("newShop", newShop);
            // updatettShop(newShop, ttShop?._id, dispatch);
        }
    };

    return (
        <div className="view">
            <div className="mobile">
                <div className="XacNhanTaiKhoan-ConTaiNer">
                    <div className="quayLai-tieuDe">
                        <a href={`/`} className="quayLai">
                            <i className="fa fa-angle-double-left"></i>Quay Lại
                        </a>
                        <div className="tieuDe">Xác Nhận</div>
                    </div>
                    <div className="allGiaoDich">
                        {allTaiKhoan &&
                            allTaiKhoan?.length !== 0 &&
                            allTaiKhoan?.map(
                                (item, index) =>
                                    item && (
                                        <div
                                            onClick={() =>
                                                handleChiTietTk(item)
                                            }
                                            className="giaoDich"
                                            key={index}
                                        >
                                            <div className="noiDung-sdt-ten">
                                                <div className="noiDung">
                                                    {item?.nDungCK}
                                                </div>
                                                <div className="sdt">
                                                    <DinhDangSo
                                                        soDinhDang={
                                                            item?.ttThem?.sdtCTK
                                                        }
                                                        donViSo="sdt"
                                                        // tt,sdt,vnd,f
                                                    />
                                                </div>

                                                <div className="soTien">
                                                    <i
                                                        style={{
                                                            color: "#04aa6d",
                                                        }}
                                                        className="fa fa-plus"
                                                    ></i>
                                                    &nbsp;
                                                    <DinhDangSo
                                                        soDinhDang={
                                                            item?.soTien
                                                        }
                                                        donViSo="f"
                                                        // tt,sdt,vnd,f
                                                    />
                                                </div>
                                            </div>

                                            <div className="tgian-soTien-thanhCong">
                                                <div className="tgian">
                                                    {new Date(
                                                        item?.createdAt
                                                    )?.getDate()}
                                                    /
                                                    {new Date(
                                                        item?.createdAt
                                                    )?.getMonth() + 1}
                                                    /
                                                    {new Date(
                                                        item?.createdAt
                                                    )?.getFullYear()}
                                                    &nbsp;
                                                    {new Date(
                                                        item?.createdAt
                                                    )?.getHours()}
                                                    :
                                                    {new Date(
                                                        item?.createdAt
                                                    )?.getMinutes()}
                                                </div>

                                                <div className="ten">
                                                    {item?.ttThem?.tenCTK}
                                                </div>
                                                <div className="thanhCong">
                                                    {item?.xacNhan}
                                                </div>
                                            </div>
                                        </div>
                                    )
                            )}
                    </div>
                    {ttGiaoDich && (
                        <div className="xacNhan">
                            <div className="xn">
                                <div className="ten-sdt-soTien">
                                    <div className="ten">
                                        {ttGiaoDich?.ttThem?.tenCTK}
                                    </div>

                                    <div className="soDu">
                                        <DinhDangSo
                                            soDinhDang={yourDetail?.fabysaXanh}
                                            donViSo="f"
                                            // tt,sdt,vnd,f
                                        />
                                    </div>

                                    <div
                                        className="dong"
                                        onClick={() => setttGiaoDich("")}
                                    >
                                        <i className="fa fa-remove"></i>
                                    </div>
                                </div>
                                <div className="soDu-dong-tc">
                                    <div className="sdt">
                                        <DinhDangSo
                                            soDinhDang={
                                                ttGiaoDich?.ttThem?.sdtCTK
                                            }
                                            donViSo="sdt"
                                            // tt,sdt,vnd,f
                                        />
                                    </div>
                                    <div className="soTien">
                                        <i
                                            style={{
                                                color: "#04aa6d",
                                            }}
                                            className="fa fa-plus"
                                        ></i>
                                        &nbsp;
                                        <DinhDangSo
                                            soDinhDang={ttGiaoDich?.soTien}
                                            donViSo="f"
                                            // tt,sdt,vnd,f
                                        />
                                    </div>
                                    <div
                                        onClick={() =>
                                            handleGiaoDichThanhCong()
                                        }
                                        className="tc"
                                    >
                                        Thành Công
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="pc">
                <div className="XacNhanTaiKhoan-ConTaiNer">XacNhanTaiKhoan</div>
            </div>
        </div>
    );
};
export default XacNhanTaiKhoan;
