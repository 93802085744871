import "./DangKi.scss";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { registerUser } from "../redux/apiRequest";
import logoFabysa from "../assets/images/logoFabysa.png";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
const DangKi = () => {
    const register = useSelector((state) => state.auth.register);
    console.log("register", register);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleRegister = (e) => {
        if (password !== password2) {
            alert("Mật khẩu chưa khớp !");
            return;
        } else {
            if (!password || !username) {
                alert("Vui lòng nhập đầy dủ thông tin !");
                return;
            } else {
                try {
                    const newUser = {
                        email: "",
                        password: password,
                        username: username,
                    };
                    registerUser(newUser, dispatch);
                } catch (err) {
                    console.log(err);
                }
            }
        }
    };
    useEffect(() => {
        if (register?.success === "Đăng kí Tài Khoản Thành Công !") {
            const cauHoi = window.confirm("Đăng kí Tài Khoản Thành Công !");
            if (cauHoi) {
                navigate(`/dang-nhap`);
            }
        }
        if (register?.success === "Tài khoản đã tồn tại !") {
            alert("Tài khoản đã tồn tại !");
        }
    }, [register]);

    return (
        <div className="register-containerTo">
            <div className="quayLai-tieuDe">
                <a href={`/dang-nhap`} className="quayLai">
                    <i className="fa fa-angle-double-left"></i>Quay Lại
                </a>
                <div className="tieuDe">Đăng Kí</div>
            </div>
            <div className="register-container">
                <div className="logoDangNhap">
                    <img src={logoFabysa} alt="he" />
                </div>
                <div className="dangKi">
                    <div className="labelDangNhap">Số Điện Thoại</div>

                    <input
                        className="inputDangNhap"
                        type="text"
                        placeholder="Nhập số điện thoại"
                        onChange={(e) => setUsername(e.target.value)}
                    />

                    <div className="labelDangNhap">Mật Khẩu</div>

                    <input
                        className="inputDangNhap"
                        type="password"
                        placeholder="Nhập mật khẩu"
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <div className="labelDangNhap">Xác Nhận Mật Khẩu</div>

                    <input
                        className="inputDangNhap"
                        type="password"
                        placeholder="Nhập lại mật khẩu"
                        onChange={(e) => setPassword2(e.target.value)}
                    />

                    <div
                        className="buttonDangNhap"
                        onClick={() => handleRegister()}
                    >
                        Tạo Tài Khoản
                    </div>
                </div>
                <div className="login-register"> Nếu có tài khoản? </div>
                <NavLink className="login-register-link" to={`/dang-nhap`}>
                    Đăng Nhập
                </NavLink>
            </div>
        </div>
    );
};
export default DangKi;
