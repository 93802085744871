import "./DonHangShop.scss";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { getDonHang, getttShop, updatettShop } from "../redux/apiRequest";
import Loading from "../GiaoDienChung/Loading";
import DonHang from "./DonHang";
import InHoaDon from "./InHoaDon";
import DinhDangSo from "../component/DinhDangSo";
const DonHangShop = () => {
    const chieuRongMH = window.innerWidth;
    const user = useSelector((state) => state.auth.login.currentUser);
    const allDonHang = useSelector(
        (state) => state.donHang.donHang.alldonHang?.allDonHang
    );
    // const [allDonHang, setallDonHang] = useState([]);
    // useEffect(() => {
    //     if (allDonHang2 && allDonHang2?.length !== 0) {
    //         setallDonHang([...allDonHang, ...allDonHang2]);
    //     }
    // }, [allDonHang2]);
    console.log("allDonHang", allDonHang);
    const ttShop = useSelector((state) => state.ttShop.ttShop.ttShop?.shop);
    const { idShop } = useParams();
    const [loading, setloading] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [skip, setskip] = useState(0);
    const [sort, setsort] = useState(-1);
    const [thongTinDh, setthongTinDh] = useState();
    const [trangThaiDH, settrangThaiDH] = useState("");
    const [noiNhan, setnoiNhan] = useState("");
    const arrNoiNhan = ["Nhận Tại Bàn", "Tự Đến Lấy", "Ship Tận Nơi"];
    const dsMenuDonHang = [
        "ĐH Mới",
        "ĐH Chưa Thanh Toán",
        "ĐH Đã Thanh Toán",
        "ĐH Trả Lại",
        "ĐH Huỷ",
        "ĐH Tìm Ship Mới",
        "ĐH Ship Đã Nhận",
        "ĐH Đang Ship",
        "ĐH Ship Chưa Thanh Toán Về Shop",
        "ĐH Ship Đã Thanh Toán Về Shop",
        "ĐH Ship Huỷ Chưa Hoàn Hàng",
        "ĐH Ship Huỷ Đã Hoàn Hàng",
    ];
    const dsphiNenTang = ["1K/Đơn Hàng", "1% Doanh Thu"];
    useEffect(() => {
        getttShop(idShop, dispatch);
    }, []);
    useEffect(() => {
        const limit = 20;
        const sdtCtv = "";
        const sdtKhachHang = "";
        const sdtOrder = "";
        const sdtXuLyDon = "";
        const sdtGiaoHang = "";
        const sdtThuTien = "";
        const kinhDo = "";
        const viDo = "";
        getDonHang(
            idShop,
            sdtCtv,
            sdtKhachHang,
            sdtOrder,
            sdtXuLyDon,
            sdtGiaoHang,
            sdtThuTien,
            kinhDo,
            viDo,
            skip,
            limit,
            trangThaiDH,
            noiNhan,
            sort,
            dispatch
        );
    }, [trangThaiDH, noiNhan, skip]);

    const VND = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND",
    });
    const handleChiTietDonHang = (item) => {
        navigate(`/chi-tiet-don-hang/${item?.idShop}/${item?._id}`);
    };
    const handleChonDonHang = (item) => {
        settrangThaiDH(item);
        setskip(0);
    };
    const handleTraHang = () => {
        alert("dgggdgdgd");
    };
    const handleDinhDangSo = (data) => {
        const n = +data;
        return n.toFixed(0).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "," && (a.length - i) % 3 === 0 ? "." + c : c;
        });
    };
    const handleChonPhiNenTang = (phiNenTang) => {
        const newShop = {
            phiNenTang: phiNenTang,
        };
        updatettShop(newShop, ttShop?._id, dispatch, setloading);
        setloading(0);
    };
    const handleChonNoiNhan = (item) => {
        if (item !== noiNhan) {
            setnoiNhan(item);
        }
        if (item === noiNhan) {
            setnoiNhan('');
        }
    };

    return (
        <div className="view">
            {chieuRongMH <= "1023" && (
                <div className="mobile">
                    <div className="DonHangShop-ConTaiNer">
                        <div className="quayLai-tieuDe">
                            <a
                                href={`/quan-ly-shop/${idShop}`}
                                className="quayLai"
                            >
                                <i className="fa fa-angle-double-left"></i>Quay
                                Lại
                            </a>

                            <div className="donHang">Đơn Hàng</div>
                            <a
                                href={`/tai-khoan/${ttShop?.ttShopThem?.tenVietTat}/${ttShop?._id}/a/a/a/a/${ttShop?._id}`}
                                className="taiKhoanShop"
                            >
                                <i
                                    className="fa fa-plus"
                                    style={{ color: "#04aa6d" }}
                                ></i>
                                &#160;
                                <DinhDangSo
                                    soDinhDang={ttShop?.cash}
                                    donViSo="f"
                                    // tt,vnd,f
                                />
                            </a>
                        </div>
                        <div className="nhomDonHang">
                            <div className="noiNhan">
                                {arrNoiNhan?.map(
                                    (item) =>
                                        item && (
                                            <div
                                                onClick={() =>
                                                    handleChonNoiNhan(item)
                                                }
                                                className={
                                                    item === noiNhan
                                                        ? "nhanTaiBan"
                                                        : "nhanTaiBan2"
                                                }
                                                key={item}
                                            >
                                                {item}
                                            </div>
                                        )
                                )}
                            </div>

                            <div className="trangThai">
                                <select
                                    onChange={(e) =>
                                        handleChonDonHang(e.target.value)
                                    }
                                    className="chonTrangThai"
                                    id="provinces"
                                >
                                    <option value="">
                                        Tất Cả Đơn Hàng . . . !
                                    </option>
                                    {dsMenuDonHang?.map(
                                        (item) =>
                                            item && (
                                                <option key={item}>
                                                    {item}
                                                </option>
                                            )
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="tieuDe-traHang">
                            <div className="tieuDe">Danh Sách Đơn Hàng</div>
                            <div
                                onClick={() => handleTraHang()}
                                className="traHang"
                            >
                                Khách Trả Hàng
                            </div>
                        </div>

                        <div className="allDonHang">
                            {allDonHang &&
                                allDonHang?.length !== 0 &&
                                allDonHang?.map((item, index) => {
                                    return (
                                        <div
                                            onClick={() =>
                                                handleChiTietDonHang(item)
                                            }
                                            key={index}
                                            className="donHang"
                                        >
                                            <div className="thoiGian-idDonHang">
                                                <div className="thoiGian">
                                                    {index + 1}.&nbsp;
                                                    {new Date(
                                                        item?.createdAt
                                                    )?.getDate()}
                                                    /
                                                    {new Date(
                                                        item?.createdAt
                                                    )?.getMonth() + 1}
                                                    /
                                                    {new Date(
                                                        item?.createdAt
                                                    )?.getFullYear()}
                                                </div>
                                                <div className="idDonHang">
                                                    {item?._id}
                                                </div>
                                            </div>
                                            <div className="noiNhan-tinhTrang">
                                                <div className="noiNhan">
                                                    {item?.noiNhan !==
                                                    "Nhận Tại Bàn" ? (
                                                        <div>
                                                            {item?.noiNhan}
                                                        </div>
                                                    ) : (
                                                        <div>{item.soBan} </div>
                                                    )}
                                                </div>
                                                <div className="tinhTrang">
                                                    {item?.trangThaiDH}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            )}
            {chieuRongMH > "1023" && (
                <div className="pc">
                    <div className="DonHangShop-ConTaiNer">DonHangShop</div>
                </div>
            )}
        </div>
    );
};
export default DonHangShop;
